import axios from 'axios'

export const saveUserBasicInfo = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/profile`, payload)
}

export const deleteSelf = () => {
  return axios.delete(`${process.env.API_URL}/v1/profile`)
}

export const completeDeleteSelf = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/profile/${payload}`)
}

export const tryPublish = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/professional/me/publish`, payload)
}

export const unPublish = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/professional/me/unpublish`, payload)
}

export const getServices = () => {
  return axios.get(`${process.env.API_URL}/v1/professionals/services`)
}

export const getSpecialities = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/professionals/specialities`)
}

export const getUserServices = () => {
  return axios.get(`${process.env.API_URL}/v1/professional/me/services`)
}

export const saveUserServices = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/professional/me/services`, payload)
}

export const getUserOnlineServices = () => {
  return axios.get(`${process.env.API_URL}/v1/professional/me/online-services`)
}

export const saveUserOnlineServices = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/professional/me/online-services`, payload)
}

export const getUserSpecialities = () => {
  return axios.get(`${process.env.API_URL}/v1/professional/me/specialities`)
}

export const saveUserSpecialities = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/professional/me/specialities`, payload)
}

export const getUserLocations = () => {
  return axios.get(`${process.env.API_URL}/v1/professional/me/locations`)
}

export const saveUserLocations = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/professional/me/locations`, payload)
}

export const getUserCertificates = () => {
  return axios.get(`${process.env.API_URL}/v1/professional/me/certificates`)
}

export const saveUserCertificates = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/professional/me/certificates`, payload)
}

export const getUserBookings = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/professional/me/bookings`, payload)
}

export const changeBookingStatus = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/professional/me/bookings/status`, payload)
}

export const deleteBooking = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/me/bookings/${payload.id}`)
}

export const changeBookingPrice = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/professional/me/bookings/price`, payload)
}

export const addCustomBooking = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/me/bookings/custom`, payload)
}

export const getCustomerBookings = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/me/bookings`, payload)
}

export const getUserBooking = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/me/bookings/${payload.id}`)
}

export const connectUserInstagramPhotos = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/me/photos/instagram`, payload)
}

export const refreshUserInstagramPhotos = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/me/photos/instagram`, payload)
}

export const getUserPhotos = () => {
  return axios.get(`${process.env.API_URL}/v1/professional/me/photos`)
}

export const deletePhotos = () => {
  return axios.delete(`${process.env.API_URL}/v1/professional/me/photos`)
}

export const analyzeUserPhotos = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/professional/me/analyze/photos`, payload)
}

export const connectUserFbPosts = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/me/posts/facebook`, payload)
}

export const connectPageFbPosts = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/me/posts/facebook-pages`, payload)
}

export const getUserPosts = () => {
  return axios.get(`${process.env.API_URL}/v1/professional/me/posts`)
}

export const deletePosts = () => {
  return axios.delete(`${process.env.API_URL}/v1/professional/me/posts`)
}

export const analyzeUserPosts = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/professional/me/analyze/posts`, payload)
}

export const saveUserLang = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/locales`, payload)
}

export const getFollowings = () => {
  return axios.get(`${process.env.API_URL}/v1/professionals/followings`)
}

export const applyPremium = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/professional/me/apply-premium`, payload)
}

export const changeRole = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/profile/role`, payload)
}

export const getUserMembership = () => {
  return axios.get(`${process.env.API_URL}/v1/me/membership`)
}

export const deleteUserCard = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/me/membership/delete-card/${payload.id}`)
}

export const detachUserDefaultCard = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/me/membership/detach-default/${payload.id}`)
}

export const setUserDefaultCard = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/me/membership/default-card/${payload.id}`)
}

export const cancelUserSubscription = (payload) => {
  return axios.delete(`${process.env.API_URL}/v1/me/membership/cancel-subscription/${payload.id}`)
}

export const getBoostCampaignPlans = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/campaigns/plans/boost/${payload.training}`)
}

export const getAdsCampaignPlans = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/campaigns/plans/ads/${payload.training}`)
}

export const getUserBoostCampaigns = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/campaigns/user/boost/${payload.training}`)
}

export const getUserAdsCampaigns = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/campaigns/user/ads/${payload.training}`)
}

export const getUserAffiliates = () => {
  return axios.get(`${process.env.API_URL}/v1/campaigns/user/affiliates`)
}

export const stopCampaign = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/campaigns/user/stop`, payload)
}

export const resumeCampaign = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/campaigns/user/resume`, payload)
}

export const addCampaign = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/campaigns/user`, payload)
}

export const updateCampaign = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/campaigns/user`, payload)
}

export const getAffiliates = () => {
  return axios.get(`${process.env.API_URL}/v1/campaigns/affiliates`)
}

export const paymentStart = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/payment/start`, payload)
}

export const paymentFinish = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/payment/finish`, payload)
}

export const startMembership = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/payment/membership`, payload)
}

export const checkMembership = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/payment/membership/check`, payload)
}

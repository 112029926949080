import store from '@/store'
import * as api from "../app/auth/api";

export const setMessage = (state, message) => {
  state.message = message
}

export const setApiKey = key => store.dispatch('auth/setKey', key)

// export const searchElastic ({commit}, {context, payload}) {
//
//   let auth = {
//     username: 'user',
//     password: 'sbCiFA6jLoQm'
//   }
//
//   // payload = Object.assign(auth, payload)
//
//   axios.defaults.headers.common['Content-Type'] = 'application/json'
//   axios.defaults.headers.common['Authorization'] = 'Basic dXNlcjpzYkNpRkE2akxvUW0='
//
//   axios.post(`${process.env.ELASTICSEARCH_URL}/professionals/_search`, payload, {
//     withCredentials: true,
//     auth: {
//       'username': 'user',
//       'password': 'sbCiFA6jLoQm'
//     }
//   }).then(function (response) {
//     commit('SET_SEARCH_RESULTS', response)
//   }).catch((error) => {
//     // console.log(error)
//   })
// }

export const searchElastic = (state, message) => {
  // Search in Elasticsearch
}

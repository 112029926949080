import * as storage from '@/util/storage'
import { DateTime } from 'luxon'
import { i18n } from '@/app/boot'

export default {
  SET_AUTHENTICATED: (state, trueOrFalse) => {
    state.user.authenticated = trueOrFalse
  },
  SET_TOKEN: (state, token) => {
    if (_.isEmpty(token)) {
      storage.remove('authToken')
      return
    }
    storage.set('authToken', token)
  },
  SET_USER: (state, user) => {
    if (!_.isEmpty(user.data)) {
      if (!_.isUndefined(user.data.gender)) {
        user.data.gender = user.data.gender ? { name: user.data.gender, label: i18n.t(`professional.gender.${user.data.gender}`) } : '';
      }

      if (!_.isUndefined(user.data.currency)) {
        user.data.currency = user.data.currency ? { name: user.data.currency, label: i18n.t(`professional.currency.${user.data.currency}`) } : '';
      }
    }

    state.user = user
  },
  SET_USER_DATA: (state, data) => {
    state.user.data = data
  },
  SET_BASIC_USER_DATA: (state, data) => {
    state.user.data.first_name = data.first_name
    state.user.data.last_name = data.last_name
    state.user.data.email_notifications = data.email_notifications
    state.user.data.image = data.image
    state.user.data.date_of_birth = data.date_of_birth
    state.user.data.gender = data.gender ? { name: data.gender, label: i18n.t(`professional.gender.${data.gender}`) } : '';
    state.user.data.currency = data.currency ? { name: data.currency, label: i18n.t(`professional.currency.${data.currency}`) } : '';
    state.user.data.long_description = data.long_description
    state.user.data.published = data.published
    state.user.data.slug = data.slug
    state.user.data.role_id = data.role_id
    state.user.data.social_only = data.social_only
    state.user.data.membership_id = data.membership_id
  },
  SET_USER_PUBLISHED: (state, data) => {
    state.user.data.published = data
  },
  SET_SERVICES_DATA: (state, data) => {
    state.services = data
  },
  SET_USER_SERVICES_DATA: (state, data) => {
    state.userServices = data
  },
  SET_USER_ONLINE_SERVICES_DATA: (state, data) => {
    state.userOnlineServices = data
  },
  SET_SPECIALITIES_DATA: (state, data) => {
    let specialities = []
    for (let i = 0; i < data.length; i++) {
      specialities.push({ name: data[i], label: i18n.t(`specialities.${data[i]}`) })
    }
    state.specialities = specialities
  },
  SET_USER_SPECIALITIES_DATA: (state, data) => {
    let specialities = []
    for (let i = 0; i < data.length; i++) {
      specialities.push({ name: data[i], label: i18n.t(`specialities.${data[i]}`) })
    }
    state.userSpecialities = specialities
  },
  SET_USER_SPECIALITIES_COMP_DATA: (state, data) => {
    state.userSpecialities = data
  },
  SET_USER_LOCATIONS_DATA: (state, data) => {
    state.userLocations = data
  },
  SET_USER_CERTIFICATES_DATA: (state, data) => {
    state.userCertificates = _.values(data)
  },
  SET_USER_BOOKINGS_DATA (state, data) {
    state.userBookings = data

    // let bookings = data
    //
    // if (!_.isEmpty(bookings)) {
    //   _.forEach(bookings, function (value, key) {
    //     let startTime = new Date(bookings[key].start_time)
    //     let endTime = new Date(bookings[key].end_time)
    //
    //     let date = DateTime.fromISO(startTime.toISOString()).toFormat('yyyy/MM/dd')
    //     let start = DateTime.fromISO(startTime.toISOString()).toFormat('HH:mm')
    //     let finish = DateTime.fromISO(endTime.toISOString()).toFormat('HH:mm')
    //
    //     state.userBookings = [
    //       ...state.userBookings,
    //       {
    //         id: key,
    //         date: date,
    //         start: start,
    //         finish: finish,
    //         title: bookings[key].service,
    //         customerId: bookings[key].userId,
    //         customerName: bookings[key].userName,
    //         location: bookings[key].location,
    //         professionalName: bookings[key].professionalName,
    //         desc: bookings[key].message,
    //         status: bookings[key].status
    //       }
    //     ]
    //   })
    // }
  },
  SET_CUSTOMER_BOOKINGS_DATA (state, data) {
    state.customerBookings = []

    let bookings = data

    if (!_.isEmpty(bookings)) {
      _.forEach(bookings, function (value, key) {
        let startTime = new Date(bookings[key].start_time)
        let endTime = new Date(bookings[key].end_time)

        let date = DateTime.fromISO(startTime.toISOString()).toFormat('yyyy/MM/dd')
        let start = DateTime.fromISO(startTime.toISOString()).toFormat('HH:mm')
        let finish = DateTime.fromISO(endTime.toISOString()).toFormat('HH:mm')

        state.customerBookings = [
          ...state.customerBookings,
          {
            id: key,
            date: date,
            start: start,
            finish: finish,
            title: bookings[key].service,
            customerId: bookings[key].userId,
            customerName: bookings[key].userName,
            location: bookings[key].location,
            professionalName: bookings[key].professionalName,
            desc: bookings[key].message,
            status: bookings[key].status
          }
        ]
      })
    }
  },
  SET_USER_PHOTOS_DATA: (state, data) => {
    state.userPhotos = data
  },
  SET_USER_ANALYZE_PHOTOS_DATA: (state, data) => {
    state.userAnalyzePhotos = data
  },
  SET_USER_POSTS_DATA: (state, data) => {
    state.userPosts = data
  },
  SET_USER_ANALYZE_POSTS_DATA: (state, data) => {
    state.userAnalyzePosts = data
  },
  SET_USER_FOLLOWINGS_DATA: (state, data) => {
    state.userFollowings = data
  },
  SET_USER_CART_DATA: (state, data) => {
    state.userCart = data
  },
  SET_USER_MEMBERSHIP: (state, data) => {
    state.userMembership = data
  },
  SET_BOOST_CAMPAIGN_PLANS: (state, data) => {
    state.boostCampaignPlans = data
  },
  SET_ADS_CAMPAIGN_PLANS: (state, data) => {
    state.adsCampaignPlans = data
  },
  SET_USER_BOOST_CAMPAIGNS: (state, data) => {
    state.userBoostCampaigns = data
  },
  SET_USER_ADS_CAMPAIGNS: (state, data) => {
    state.userAdsCampaigns = data
  },
  SET_USER_AFFILIATES: (state, data) => {
    state.userAffiliates = data
  },
  SET_AFFILIATES: (state, data) => {
    state.affiliates = data
  },
  SET_PAYMENT: (state, data) => {
    state.payment = data
  }
}

<template>
  <div class="chars-counter text-right">{{ count }} / <strong>{{ max }}</strong></div>
</template>

<script>
export default {
  name: 'chars-counter',
  props: {
    count: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

export default {
  all: {},
  allIds: [],
  allMsgIds: [],
  allUsers: {},
  userConversations: [],
  userMessages: [],
  sharedKeys: [],
  selectedProfessional: {},
  selectedConversation: {},
  typingMessage: {},
  editMessage: null,
  isLoading: false,
  isMsgLoading: false,
  msgPending: '',
  inputDisabled: false
}

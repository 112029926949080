export const en = {
  siteTitle: 'FINE.FIT - Gotta feel good! {{var}}',
  homeTitle: 'Find professionals to help you feel fine and fit.',
  searchDesc: 'Find {{search}} professionals to help you feel fine and fit.',
  professionalsTitle: 'Professionals',
  specialitiesTitle: 'Specialities',
  searchTitle: 'Search',
  findOfflineServices: 'Find face-to-face training',
  findOnlineServices: 'Find online training',
  notFound: 'Not found :(',
  welcomeNotice: {
    title: 'Welcome to Fine.fit!',
    intro: 'Our mission is to help you in finding yourself the most suitable coach or trainer, or to help you in finding yourself a new hobby. <strong>Click on the search bar and start exploring!</strong>',
    cta: 'Got it!'
  },
  account: {
    title: 'My account',
    accessDenied: 'You have to sign in to access this page.',
    emailUnconfirmed: 'Your email is not confirmed yet, please confirm!',
    emailConfirmed: 'Your email was confirmed!',
    followingsTitle: 'Following professionals',
    followingsDescription: 'You are following these professionals',
    followingsEmpty: '<strong>None yet.</strong> Start following professionals to see their social media activities here!',
    generalTitle: 'General',
    basicInfoTitle: 'Basic info',
    offlineTitle: 'Face-to-face',
    onlineTitle: 'Online training',
    servicesTitle: 'Services',
    servicesOnlineTitle: 'Online services',
    locationsTitle: 'Locations',
    socialTitle: 'Social activities',
    socialDescription: 'Boost your visibility with social activities',
    settingsTitle: 'Settings',
    settingsDescription: 'Your account settings',
    servicesDescription: 'Services you offer to your clients.',
    professionalCalendarTitle: 'Bookings',
    professionalCalendarDescription: 'Your bookings',
    customerCalendarTitle: 'Bookings history',
    customerCalendarDescription: 'Your bookings history',
    onlineMaterialsTitle: 'Online materials',
    onlineMaterialsDescription: 'Do you have online training materials for preview?',
    comingSoon: 'Coming soon!',
    certificatesTitle: 'Certificates',
    certificatesDescription: 'Do you have certificate/-s for your professional work?',
    professionalMarketingTitle: 'Marketing',
    professionalMarketingDescription: 'Get more customers with marketing!',
    searchTitle: 'Search',
    signedInTitle: 'You are signed in as<br />{{name}}',
    changeRoleTitle: 'Are you a professional?',
    yourProfileTitle: 'Your profile',
    accountTitle: 'Account',
    alerts: {
      uploadPhoto: 'It is recommendable to upload your photo, which could make your profile more trusty.',
      maximumServices: 'You have added maximum amount of services.',
      unsavedChanges: 'You have unsaved changes under {{var}}. Please, don\'t forget to save them!'
    },
    deleteAccount: 'Delete my account',
    unpublishAccount: 'Unpublish account',
    locations: {
      title: 'Your locations',
      subTitle: 'Add your primary locations',
      limitTitle: 'Maximum 5',
      limitReached: 'You have added maximum count of locations.',
      flexibleLabel: 'Is your location flexible?',
      agreeClient: 'Yes, I can agree with client',
      minTitle: 'You have to add at least one location!'
    },
    facebook: {
      posts: {
        loadFrom: 'From where to load your posts?',
        fbPagesBtn: 'My Facebook pages',
        fbPrivateBtn: 'My private account',
        connectBtn: 'Connect with FB activities',
        disconnectBtn: 'Disconnect FB activities',
        disconnectInfo: 'Any other profile information will not be deleted if you have signed up via Facebook',
        analyzer: {
          scoreLabel: 'FB score',
          scoreTooltip: 'How relevant are your Facebook posts comparing to your professional profile on FineFit. Better the score, better visibility on search results. Try writing more about your professional services, it can help to raise the score.',
          analyzerTitle: 'Analyzing your Facebook posts',
          completeTitle: 'Analyzation complete!',
          completeDesc: 'We have analyzed your latest Facebook posts by checking how relevant they are comparing to your professional profile on FineFit. Here is <strong>your Facebook score:</strong>',
          completeResult: 'Your services and categories were mentioned in <strong>{{count}} posts</strong> lately.',
          low: {
            intro: '<strong>Try writing more about your professional services on Facebook, it can help to raise the score for better search results.</strong>',
            info: 'Even with lower score, your Facebook activities will still be visible on your profile page for your customers.'
          },
          medium: {
            intro: '<strong>Good job, you are active about your professional work on Facebook!</strong> Being active on Facebook professionally, can help to raise the score for better search results.',
            info: 'Your Facebook activities are also visible on your profile page for your customers.'
          },
          high: {
            intro: '<strong>Awesome!</strong> It seems that you are very active about your professional work on Facebook! It helps for better search results.',
            info: 'Your Facebook activities are also visible on your profile page for your customers.'
          }
        }
      }
    },
    instagram: {
      photos: {
        connectBtn: 'Add more photos',
        disconnectBtn: 'Disconnect Instagram photos',
        disconnectInfo: 'Your connected Instagram photos will only be removed from Fine.fit, not from your actual Instagram account.',
        refreshBtn: 'Refresh photos',
        source: 'Source',
        analyzer: {
          scoreLabel: 'Instagram<br />score',
          scoreTooltip: 'How relevant are your Instagram photos captions comparing to your professional profile on FineFit. Better the score, better visibility on search results. Try writing more about your professional services, it can help to raise the score.',
          analyzerTitle: 'Analyzing your Instagram photos',
          completeTitle: 'Analyzation complete!',
          completeDesc: 'We have analyzed your latest Instagram photos by checking how relevant they are comparing to your professional profile on FineFit. Here is <strong>your Instagram score:</strong>',
          completeResult: 'Your services and categories were mentioned in <strong>{{count}} photos</strong> lately.',
          low: {
            intro: '<strong>Try writing more about your professional services on Instagram, it can help to raise the score for better search results.</strong>',
            info: 'Even with lower score, your Instagram activities will still be visible on your profile page for your customers.'
          },
          medium: {
            intro: '<strong>Good job, you are active about your professional work on Instagram!</strong> Being active on Instagram professionally, can help to raise the score for better search results.',
            info: 'Your Instagram activities are also visible on your profile page for your customers.'
          },
          high: {
            intro: '<strong>Awesome!</strong> It seems that you are very active about your professional work on Instagram! It helps for better search results.',
            info: 'Your Instagram activities are also visible on your profile page for your customers.'
          }
        }
      }
    },
    specialities: {
      minTitle: 'You have to add at least one speciality!'
    },
    services: {
      fastRegDesc: 'Choose your main services<br />with at least one sub-service',
      addPricing: 'Add pricing',
      addDescription: 'Add description',
      addOnline: 'Online training',
      editPricing: 'Edit pricing',
      editDescription: 'Edit description',
      editOnline: 'Edit online',
      limitReached: 'You have reached the limit of allowed services - {{limit}}.<br />Remove some services to continue.',
      minTitle: 'You have to add at least one service!',
      addedAll: 'You have already added all available services.',
      saveSuccess: 'Services saved successfully!',
      saveBtn: 'Save all services',
      pricing: {
        title: 'Pricing',
        price: 'Price',
        duration: 'Duration',
        discount: 'Discount',
        sessions: 'Sessions',
        firstSession: 'Discount for the first session?',
        priceDuration: 'Price by duration',
        priceSessions: 'Price per sessions',
        customNotice: 'For custom pricing (daily, monthly, yearly etc.) type in packages fields below.',
        packages: 'Packages',
        packageDesc: 'Package description'
      },
      description: {
        title: 'Description',
        placeholder: 'Service description'
      }
    },
    onlineServices: {
      skipMsg: 'Don\'t offer online services? No worries',
      skipScrollMsg: 'Don\'t offer online services? <strong>Scroll down to skip.</strong>',
      trainingMethodTitle: 'Your offered training method?',
      languagesTitle: 'In what languages are your online services?',
      copyServicesInfo: 'Copy content from face-to-face services?',
      copyServicesBtn: 'Yes, copy',
      addWebsite: 'Add external website',
      editWebsite: 'Edit external website',
      langLimitReached: 'You have reached the limit of allowed languages - {{limit}}.<br />Remove if you want to change.',
      websitesLimitReached: 'You have reached the limit of allowed external websites - {{limit}}.<br />Remove if you want to change.',
      website: {
        title: 'External websites for your online trainings',
        url: 'Website description',
        urlPlaceholder: 'https://...',
        description: 'Website description',
        descPlaceholder: 'Short description to your customers about this website URL.'
      },
      error: 'Some error occurred'
    },
    certificates: {
      limitReached: 'You have reached the limit of allowed certificates - {{limit}}.',
      saveBtn: 'Save certificates',
      unsaved: 'You have unsaved changes under <i>Certificates</i>. Please, don\'t forget to save them!',
      uploadImg: 'Upload scanned copy',
      saveEdit: 'Ok, I\'m done',
      saveSuccess: 'Certificates saved successfully!',
    },
    bookings: {
      bookingTitle: 'Booking',
      bookingFor: 'Booking for <strong>{{name}}</strong>',
      bookingBy: 'Booking by <strong>{{name}}</strong>',
      serviceLabel: 'Service',
      locationLabel: 'Location',
      noteLabel: 'Note',
      acceptBtn: 'Accept',
      cancelBtn: 'Cancel',
      reAcceptBtn: 're-accept',
      declineBtn: 'Decline',
      confirmed: 'Confirmed',
      canceled: 'Canceled',
      pending: 'Pending',
      requestDate: 'Request date',
      suggestDate: 'You suggest booking date to customer.',
      status: {
        confirmed: 'Booking confirmed',
        pending: 'Booking pending',
        canceled: 'Booking canceled',
        declined: 'Booking declined'
      },
      addBooking: '+ Add booking',
      addBookingTitle: 'Add booking',
      moreBookings: 'x{{count}} bookings',
      viewAll: 'View all',
      view: 'View',
      passed: 'Passed',
      expired: 'Expired',
      single: {
        title: 'Bookings at {{time}}',
        bookingTitle: 'Title',
        yourNotes: 'Your notes',
        who: 'Who',
        chosenService: 'Chosen service',
        customerExpectations: 'Customer expectations',
        professional: 'Professional',
        yourChosenService: 'Your chosen service',
        yourExpectations: 'Your expectations',
        duration: 'Duration',
        location: 'Location',
        price: 'Price?',
        priceNote: 'Visible only to you',
        customerLocation: 'Customer\'s',
        yourLocation: 'Your'
      },
      customModal: {
        success: '<strong>Booking added!</strong><br />You can edit it at any time.',
        title: 'Title / Customer\'s name',
        date: 'Date/time and duration',
        otherLocation: 'Other location',
        notes: 'Notes',
        notesPlaceholder: 'Notes about this session for yourself'
      },
      revenue: {
        label: 'Est. monthly revenue',
        lastMonth: 'since last month'
      }
    }
  },
  onlineServices: {
    methods: {
      remote: {
        title: 'Remote coaching',
        icon: 'contactless'
      },
      live: {
        title: 'LIVE training',
        icon: 'live_tv'
      },
      programs: {
        title: 'Training programs',
        icon: 'description'
      }
    },
    languagePlaceholder: 'Language...',
    languageTooltip: 'Type a language, it will suggest',
    scrollBtn: 'Scroll to online services',
    offerServices: 'I offer online services.',
    servicesOnlineTitle: 'Online services',
    onlineLanguagesTitle: 'Online languages',
    onlineLanguagesDesc: 'Online services are available in these languages:',
    onlineMethodsTitle: 'Online training methods',
    websitesTitle: 'External websites for this service',
    openWebsiteBtn: 'Open website',
    websiteLinkTitle: 'Open {{url}} in a new tab',
    searchBtn: 'Search professionals'
  },
  professional: {
    ownProfile: 'You are looking at your own profile.',
    metaDesc: '{{name}} - Professional who helps customers to feel fine and fit. Check out what {{name}} offers on Fine.fit',
    verified: 'Verified professional',
    premium: 'Premium professional',
    freeMember: 'Free member',
    gender: {
      male: 'Male',
      female: 'Female'
    },
    currency: {
      eur: 'EUR',
      usd: 'USD',
      gbp: 'GBP',
      aud: 'AUD'
    },
    followBtn: 'Follow',
    unfollowBtn: 'Unfollow',
    following: 'Following',
    sendMsgBtn: 'Send message',
    ads: {
      title: '{{name}} recommends'
    },
    tabs: {
      main: 'Main info',
      social: 'Social media activities'
    },
    pricing: {
      pricingOptions: 'Pricing options',
      firstSessionFree: 'First session for free',
      firstSessionDiscount: '{{percent}}% discount for first session',
      packages: 'Pricing packages'
    },
    aboutTitle: 'About',
    photosTitle: 'Photos',
    reviews: {
      title: 'Ratings & Reviews',
      userReviewTitle: 'Your rating & review',
      placeholder: 'Tell everybody about your experience with this professional',
      ownReviews: 'You are looking at your own reviews.',
      accessDenied: 'You have to sign in or sign up to leave a review.',
      addBtn: 'Add review',
      cancelBtn: 'Cancel',
      publishBtn: 'Publish review',
      publishSuccess: 'Thank you, your review has been published!'
    },
    social: {
      instagram: {
        title: 'Instagram activities',
        modalTitle: 'Instagram photos',
        viewBtn: 'View on Instagram',
        source: 'Source',
        selectPhoto: 'Please, select a photo to display.'
      },
      facebook: {
        title: 'Facebook activities',
        originalPost: 'Original post',
        viewBtn: 'View on Facebook',
        viewBtnShort: 'View'
      }
    },
    locations: {
      title: 'Location',
      regularPlaces: 'Regular places',
      flexible: 'Professional\'s location is flexible!'
    }
  },
  searchPage: {
    viewBtn: 'Check professional',
    showMapBtn: 'Show map with professionals',
    hideMapBtn: 'Hide map',
    servicesBtn: 'See services',
    emptySearch: 'Search for professionals'
  },
  booking: {
    successMsg: '<strong>Booking request has been sent!</strong><br />Please, wait for further information from professional.',
    placeholders: {
      when: 'When?',
      where: 'Where?',
      sessionDuration: 'Session duration?',
      expectations: 'What do you expect from this service?'
    },
    labels: {
      date: 'Who chooses date and time?',
      location: 'Location?',
      expectations: 'Your expectations'
    },
    dateProfessional: 'Professional can suggest date',
    dateUser: 'My preferable date',
    userLocation: 'My preferable location',
    durations: {
      '15min': '15 minutes',
      '30min': '30 minutes',
      '45min': '45 minutes',
      '1h': '1 hour',
      '1h15min': '1 hour 15',
      '1h30min': '1 hour 30',
      '1h45min': '1 hour 45',
      '2h': '2 hours',
      '2h15min': '2 hours 15',
      '2h30min': '2 hours 30',
      '2h45min': '2 hours 45',
      '3h': '3 hours',
      '4h': '4 hours',
      '5h': '5 hours',
      '6h': '6 hours',
      '7h': '7 hours',
      '8h': '8 hours'
    }
  },
  footer: {
    phone: 'Phone',
    email: 'Email',
    follow: {
      facebook: 'Follow us on Facebook',
      twitter: 'Follow us on Twitter',
      linkedin: 'Follow us on LinkedIn',
      instagram: 'Follow us on Instagram'
    }
  },
  labels: {
    suggestedCategories: 'Suggested categories',
    suggestedServices: 'Suggested services',
    suggestedProfessionals: 'Suggested professionals',
    chooseService: 'Choose a service',
    chooseSubService: 'Choose a sub-service:',
    selectedMainService: 'Selected main service',
  },
  placeholders: {
    search: 'Service, speciality, professional name',
    url: 'Your URL',
    slug: 'your-name',
    defaultLocation: '',
    language: 'Language',
    firstName: 'First name',
    lastName: 'Last name',
    name: 'Name',
    phone: 'Phone',
    password: 'Password',
    newPassword: 'New password',
    repeatPassword: 'Repeat password',
    email: 'Email',
    specialities: 'Specialities, your best (max 5)',
    professionalDescription: 'Tell your clients a little bit about your professional work...',
    professionalLongDescription: 'Longer description about what you do.',
    birth: 'Date of birth',
    gender: 'Gender',
    currency: 'You currency',
    languages: 'Languages you speek',
    chooseService: 'Choose your service',
    serviceDescription: 'Tell your clients what to expect from your service...',
    headerVideo: 'Your Youtube video',
    searchInput: 'Type anything...',
    location: 'Location'
  },
  preloader: {
    default: 'Loading...',
    sendingEmail: 'Sending verification...',
    sendingForgotEmail: 'Sending password reset verification...',
    searchingResults: 'Loading results...',
    searchingPros: 'Searching professionals...',
    loadingPro: 'Loading professional...',
    loadingPros: 'Loading professionals...',
    instagram: 'Connecting to Instagram...',
    facebookPosts: 'Connecting to Facebook...',
    analyzeFbPosts: 'Analyzing your Facebook posts...',
    analyzeInstaPosts: 'Analyzing your Instagram photos...',
    facebookSignUp: 'Connecting to Facebook...',
    googleSignUp: 'Connecting to Google...'
  },
  signIn: {
    title: 'Sign in',
    noAccount: 'Don\'t have an account?',
    signedIn: 'You are logged in as {{name}}',
    forgotLabel: 'Forgot your password?',
    signUpLabel: 'Don\'t have an account? You can create one',
    goBack: 'or go back to'
  },
  signUp: {
    title: 'Sign up',
    checkMail: 'Check your email',
    checkMailDescription: 'We just sent you an email to complete registration.',
    disclaimer: 'To sign up, you must read and accept <a href="#terms">{{terms}}</a> and <a href="#privacy">{{privacy}}</a>',
    form: {
      buttons: {
        loginNow: 'Log in now',
        send: 'Send',
        go: 'Let\'s go!'
      },
      label: {
        aboutYourself: 'Tell about yourself'
      }
    },
    customerDescription: 'Do you want to find a service?',
    professionalDescription: 'Do you offer a service?',
    haveAccount: 'Already have an account?',
    userAccount: 'Want to register as standard user, without company?',
    fitnessUnionPro: 'I\'m a licensed coach by Suomen Fitnessurheilu ry',
    termsAcceptText: 'I have read and accept <a href="/page/terms" target="_blank" v-b-tooltip.hover title="Terms & Conditions">{{terms}}</a> and <a href="/page/privacy" target="_blank" v-b-tooltip.hover title="Privacy Policy">{{privacy}}</a>',
    notificationsAcceptText: 'I agree to receive <strong>{{notifications}}</strong>',
    thirdTitle: 'Finish signing up',
    thirdDescription: 'Fill the missing fields below.',
    customerAccount: 'I\'m a <strong>customer</strong><br />looking for services',
    professionalAccount: 'I\'m a <strong>professional</strong><br />offering services',
    customerAccountShort: 'I\'m a <strong>customer</strong>',
    professionalAccountShort: 'I\'m a <strong>professional</strong>',
    or: 'or',
    accountType: 'Who are you?',
    socialConfirmation: {
      title: 'There wasn\'t any account found that is associated with your <strong style="text-transform: capitalize;">{{provider}}</strong> account.',
      description: '<strong>We can create one!</strong> Just finish the steps below:',
      otherSignupWays: 'Other ways of signing up here',
      signUpBtn: 'Yes, sign me up!'
    }
  },
  reset: {
    checkMail: 'Check your email',
    checkMailDescription: 'We just sent you an email to confirm password reset.',
    addNewPassword: 'Add new password',
    haveAccount: 'Remember the password?',
    form: {
      buttons: {
        signIn: 'Sign in',
        send: 'Send'
      },
      errors: {
        noMatch: 'Passwords don\'t match'
      },
      placeholder: {
        email: 'Email',
        newPassword: 'New password',
        repeatPassword: 'Repeat password'
      }
    },
    title: 'Reset password'
  },
  deleted: {
    title: 'Your account was permanently deleted.'
  },
  legal: {
    privacy: {
      body: 'Privacy Policy body text',
      intro: 'Spēkā no 2018. gada 1. marta.',
      title: 'Privacy Policy'
    },
    terms: {
      body: 'Terms & Conditions body text',
      intro: 'Spēkā no 2018. gada 1. marta.',
      title: 'Terms & Conditions'
    },
    notifications: {
      title: 'email notifications'
    }
  },
  titles: {
    headerVideo: 'Paste your Youtube video URL or ID here'
  },
  buttons: {
    back: 'Back',
    book: 'Book',
    browseProfessionals: 'Browse professionals',
    browseServices: 'Browse services',
    showSuggestions: 'Show suggestions',
    clearSearch: 'Clear search input',
    clearLocation: 'Clear location input',
    openServices: 'Open list of services',
    changePassword: 'Change password?',
    setPassword: 'Set password?',
    close: 'Close',
    forgotPassword: 'Forgot your password?',
    previous: 'Previous',
    showLess: 'Show less',
    signIn: 'Sign in',
    signInFacebook: 'Sign in with Facebook',
    signInGoogle: 'Sign in with Google',
    signInEmail: 'Sign in with Email',
    signUp: 'Sign up',
    signOut: 'Sign out',
    signUpFacebook: 'Sign up with Facebook',
    signUpGoogle: 'Sign up with Google',
    signUpEmail: 'Sign up with email',
    send: 'Send',
    submit: 'Submit',
    go: 'Go',
    add: 'Add',
    addMore: 'Add more',
    addVideo: 'Add video',
    removeLast: 'Remove last',
    edit: 'Edit',
    delete: 'Delete',
    deleteChange: 'Delete / Change',
    here: 'here',
    no: 'No',
    ok: 'Ok!',
    readMore: 'Read more',
    resetPassword: 'Reset password',
    resendConfirmation: 'Resend confirmation',
    save: 'Save',
    saveProfilePhoto: 'Save profile photo',
    savePhotos: 'Save photos',
    saveServices: 'Save services',
    saveBasicInfo: 'Save basic info',
    saveLocations: 'Save locations',
    yes: 'Yes',
    confirm: 'Confirm',
    cancel: 'Cancel',
    upgrade: 'Upgrade',
    manage: 'Manage',
    upgradeToProfessional: 'Upgrade to professional account',
    viewProfile: 'View profile',
    settings: 'Settings',
    editAccount: 'Edit account',
    certificates: 'Certificates',
    feed: 'Feed',
    bookings: 'Bookings',
    marketing: 'Marketing',
    messages: 'Messages',
    viewDetails: 'View details'
  },
  servicesSection: {
    title: 'Main services'
  },
  professionalSection: {
    title: 'Are you professional?',
    subTitle: 'We can help you.',
    description: 'Manage your business, accept payments without any hassle.',
    cta: 'Learn more'
  },
  pages: {
    home: 'Home',
    contacts: 'Contacts',
    account: 'Account',
    professionals: 'Professionals',
    pricing: 'Pricing',
    termsOfServices: 'Terms of services',
    privacyPolicy: 'Privacy policy',
    management: 'Management'
  },
  copyright: '© 2021, FineFit.',
  specialities: {
    acrobatics: 'Acrobatics',
    bikiniFitness: 'Bikini fitness',
    bodybuilding: 'Body building',
    callisthenics: 'Callisthenics',
    competitionCoaching: 'Competition coaching',
    crossfit: 'Crossfit',
    enduranceSports: 'Endurance sports',
    fitness: 'Fitness',
    fodmap: 'Fodmap',
    functionalTraining: 'Functional training',
    fustra: 'Fustra',
    glutenFree: 'Gluten free',
    groupTraining: 'Group training',
    gymnastics: 'Gymnastics',
    gymTraining: 'Gym training',
    handstanding: 'Handstanding',
    highIntensityIntervalTraining: 'HIIT - High intensity interval training',
    hydrobics: 'Hydrobics',
    hydroRunning: 'Hydro running',
    kettlebell: 'Kettlebell',
    largeGroup: 'Large group',
    martialArts: 'Martial arts',
    meditation: 'Meditation',
    mentalCoaching: 'Mental coaching',
    methodMakia: 'Method Makia',
    methodPutkisto: 'Method Putkisto',
    milkFree: 'Milk free',
    mindfulness: 'Mindfulness',
    movement: 'Movement',
    movementImpaired: 'Movement impaired',
    nutritionPrograms: 'Nutrition programs',
    others: 'Others',
    otherSports: 'Other sports',
    paleo: 'Paleo',
    performanceImprovement: 'Performance improvement',
    personalTraining: 'Personal training',
    physiotherapy: 'Physiotherapy',
    pilates: 'Pilates',
    postPregnancy: 'Post pregnancy',
    powerLifting: 'Power lifting',
    pregnancyTimeTraining: 'Pregnancy time training',
    remoteTraining: 'Remote training',
    running: 'Running',
    seniorCitizens: 'Senior citizens',
    smallGroup: 'Small group',
    strengthAndMuscle: 'Strength and muscle mass gain',
    stressControl: 'Stress control',
    swimming: 'Swimming',
    therapeutic: 'Therapeutic',
    vegetarian: 'Vegetarian',
    weightLifting: 'Weight lifting',
    weightLoss: 'Weight loss',
    wellness: 'Wellness - health and well being',
    yoga: 'Yoga',
    exercises: 'Exercises',
    recuperation: 'Recuperation',
    generalMentalWellbeing: 'General mental wellbeing',
    generalPerformance: 'General performance',
    sexualWellbeing: 'Sexual wellbeing',
    sportSpecific: 'Sport specific',
    circuitTraining: 'Circuit training',
    core: 'Core',
    stretching: 'Stretching',
    recuperative: 'Recuperative',
    danceAerobics: 'Dance aerobics (choreographic)',
    dance: 'Dance',
    absLegsBooty: 'Abs - Legs - Booty',
    bodyInterval: 'Body interval',
    posteriorConditioning: 'Posterior conditioning (back)',
    waterAerobics: 'Water aerobics',
    stepAerobics: 'Step aerobics',
    seniorExercise: 'Senior exercise',
    spinning: 'Spinning',
    bodyPump: 'Body pump'
  },
  messenger: {
    messenger: 'Messenger',
    messagePlaceholder: 'Type your message here...',
    messageSending: 'Sending...',
    startConversation: 'Type your message and press ENTER to start conversation with <strong>{{name}}</strong>',
    conversationTitle: 'Conversation with <strong>{{name}}</strong>',
    sendMessageInfo: '- Press ENTER to send your message -',
    previousConversations: 'Conversations',
    empty: '- Empty -',
    booking: {
      confirmed: '{{name}} has confirmed the booking!',
      canceled: '{{name}} has canceled the booking.',
      declined: '{{name}} has declined the booking.'
    }
  },
  image: {
    dropLabel: 'Drop / browse your image here',
    confirmLabel: 'Confirm',
    confirmTitle: 'Confirm',
    cancelLabel: 'Cancel',
    cancelTitle: 'Cancel',
    editTitle: 'Edit',
    removeTitle: 'Delete',
    downloadTitle: 'Download',
    rotateTitle: 'Rotate',
    uploadTitle: 'Upload',
    tooSmall: 'Image is too small'
  },
  fastReg: {
    title: 'Publish your account',
    description: 'You are almost there to publish your account.',
    step0: {
      title: 'Membership plan'
    },
    step1: {
      title: 'Basic info',
      finishBtn: 'Done! Next, please.'
    },
    step2: {
      title: 'Location',
      finishBtn: 'Done! Next, please.'
    },
    step3: {
      title: 'Services',
      finishBtn: 'Done! One more, please.'
    },
    step4: {
      title: 'Online services',
      skipBtn: 'Skip & finish',
      finishBtn: 'Save & publish'
    }
  },
  landings: {
    customers: {
      title: 'We can help you to find professional.<br />We got this!',
      why: {
        title: 'Why Finefit?',
        description: '<p>Looking for a personal trainer, coach or another wellness professional?</p><p>Here you´ll find the exactly right person and services for your needs, where and when you want them. Once you find the perfect professional, you can book your session and pay for it, order a training package or discuss your specific needs with our trainer. You can also leave an inquery for a quote on a training, nutrition or wellness package you need, and we´ll reply with quotes from the five fastest professionals fitting your needs.</p>'
      },
      how: {
        title: 'How does it work and what you get?',
        options: {
          title: 'When looking for a professional to suit your needs, you have four options',
          option1: 'You can search our trainers by training method or sport',
          option2: 'You can search through keywords or location',
          option3: 'You can browse through our list of professionals',
          option4: 'You can leave an inquiry for a quote'
        }
      }
    },
    professionals: {
      title: 'We can help you with customers.<br />We got this!',
      why: {
        title: 'Why Finefit?',
        description: '<p>Fine.fit is a virtual employment agent for training, coaching and wellness professionals.</p><p>Our mission is to produce and solicit work, and make life easy for you. We will find you new customers, make booking meetings a breeze, provide you with a communication channel with the client. You set the pricing for the different services you provide and decide on your working hours as well as how much you work.</p>'
      },
      how: {
        title: 'How does it work and what you get?',
        options: {
          title: 'Fill our your profile on our page (sign up)',
          option1: 'Fill in your basic information and the photo you want to use on your profile',
          option2: 'Choose and add the services you offer and set their pricing and session lengths',
          option3: 'If you can´t find a service you offer on our list, let us know and we´ll add it',
          option4: 'Read through our Terms of Service',
          option5: 'Decide if you want to take part in Fine.fit Satisfaction Guarantee – program(Pa3)'
        }
      },
      communication: {
        title: 'Communication',
        description: 'Receive messages, orders and bookings from clients and reply to them. When a prospective client sends you a message or a booking inquery, our system will notify you by e-mail.'
      }
    }
  },
  modals: {
    deleteConfirmation: {
      confirmation: {
        intro: 'Are you sure you want to perform this action?',
        title: 'Are you sure?'
      },
      deleted: {
        intro: 'This entry was deleted successfully.',
        title: 'Deleted successfully'
      }
    },
    deleteProfile: {
      confirmation: {
        intro: 'Are you sure you want to delete your account?',
        title: 'Are you sure?'
      },
      deleted: {
        intro: 'Confirmation, to complete your account deletion, was sent to your email.',
        title: 'Confirmation email sent'
      }
    },
    unpublishProfile: {
      confirmation: {
        intro: 'Are you sure you want to unpublish your account? It will be taken out of search results and your profile URL.',
        title: 'Are you sure?'
      },
      unpublished: {
        intro: 'Your account was unpublished!',
        title: 'Success'
      }
    },
    deleteInstagram: {
      confirmation: {
        intro: 'Are you sure you want to <strong>disconnect</strong> your Instagram account?',
        title: 'Are you sure?'
      },
      deleted: {
        intro: 'Your Instagram account was <strong>disconnected</strong> from your FineFit account.',
        title: 'Success'
      }
    },
    deletePosts: {
      confirmation: {
        intro: 'Are you sure you want to <strong>disconnect</strong> from your activity on Facebook?',
        title: 'Are you sure?'
      },
      deleted: {
        intro: 'Your Facebook activity was <strong>disconnected</strong> from your FineFit account.',
        title: 'Success'
      }
    },
    changeRole: {
      confirmation: {
        intro: 'Are you sure you want to upgrade to professional account?',
        title: 'Are you sure?'
      },
      changed: {
        intro: 'Your account was upgraded to professional!',
        title: 'Account upgraded'
      }
    },
    cancelBooking: {
      confirmation: {
        intro: 'Are you sure you want to <strong>cancel</strong> this booking?',
        title: 'Are you sure?',
        messageTitle: 'Explain the reason for cancellation.',
        messagePlaceholder: 'Cancellation reason'
      },
      canceled: {
        intro: 'Your booking was <strong>canceled</strong> successfully!',
        title: 'Success'
      }
    },
    deleteBooking: {
      confirmation: {
        intro: 'Are you sure you want to <strong>delete</strong> this booking?',
        title: 'Are you sure?'
      },
      canceled: {
        intro: 'Your booking was <strong>deleted</strong> successfully!',
        title: 'Success'
      }
    },
    justSignedUp: {
      welcomeTitle: 'Welcome to FineFit!',
      title: 'Congratulations, you just went public!',
      intro: 'As a new Finefit-coach, we have just sent you a <strong>promo code</strong> for coaching portal <strong>Step2.fit</strong>.',
      intro2: 'You have filled out the minimum necessary info to be published.',
      improveTitle: 'Some of the additional cool things you can add on your <strong>Account</strong> page to get <strong>better search results</strong>:',
      connectInstagram: 'Connect your <strong>Instagram</strong> account to add more photos that will be updated automatically',
      connectFacebook: 'Connect your <strong>Facebook</strong> posts with your Fine.fit account for better search results',
      addDesc: 'Add your short <strong>description, longer description, gender etc.</strong> to reach more customers'
    },
    justSignedUpCustomer: {
      welcomeTitle: 'Welcome to FineFit!',
      title: 'Congratulations! What to do next:',
      findOfflineServices: 'Find face-to-face<br />training',
      findOnlineServices: 'Find online<br />training',
    },
    addCertificate: {
      title: 'Add certificate',
      certificateId: '# ID number (optional)',
      certificateTitle: 'Title on the certificate'
    },
    editCertificate: {
      title: 'Edit certificate'
    },
    accountBlocked: {
      title: 'Your account has been blocked.',
      reason: 'Reason: <strong>"{{reason}}"</strong>',
      body: 'You may not publish your information and send private messages.',
      footer: '<strong>Questions?</strong> Contact us: <a href="mailto:info@fine.fit">info@fine.fit</a>'
    },
    deleteCard: {
      confirmation: {
        intro: '<p>Are you sure you want to delete your payment card?</p>',
        title: 'Are you sure?'
      },
      success: {
        intro: 'Your payment card was deleted successfully.',
        title: 'Payment card deleted'
      }
    },
    detachDefault: {
      confirmation: {
        intro: '<p>Are you sure you want to detach your default payment card?</p><p><strong>Your membership subscription will no longer be automatically renewed until you add a new payment card.</strong></p>',
        title: 'Are you sure?'
      },
      success: {
        intro: 'Your default payment card was detached successfully. <strong>Set a new payment card as default for auto-renewal to not lose your membership.</strong>',
        title: 'Payment card deleted'
      }
    },
    cancelSubscription: {
      confirmation: {
        intro: '<p>Are you sure you want to cancel your membership subscription?</p><p><strong>Your membership will not be auto-renewed anymore until you create a new subscription again.</strong></p><p>Verified membership benefits will be lost after expiration.</p>',
        title: 'Are you sure?'
      },
      success: {
        intro: 'Your membership subscription was canceled successfully. <strong>Create a new membership subscription to prolong your verified membership benefits.</strong>',
        title: 'Membership subscription canceled'
      }
    },
    freeMembership: {
      title: '<strong>Become verified</strong><br />for 3 months <strong>for FREE</strong>',
      modalTitle: 'Become verified professional!',
      offer: 'Special offer - try verified professional features right away for free.',
      labels: {
        afterTrial: 'WHAT ABOUT AFTER FREE TRIAL? (choose one)',
        chargeNotice: 'Your payment card will NOT be charged for 3 months during the free trial period, you can cancel anytime!',
        addCard: 'ADD YOUR CREDIT CARD (to prevent fake users)'
      },
      autoCharge: 'Enable <strong>automatic renewal</strong> after trial period',
      autoChargeDesc: 'When 3 months pass, your payment card will be <strong>charged monthly</strong> (€19.89/month), but if you are not satisfied with Fine.fit features, you <strong>can cancel</strong> your membership at any time on your account page.',
      noCharge: '<strong>Downgrade</strong> to free account after trial period',
      noChargeDesc: 'When 3 months pass, your membership will be downgraded to free account and your credit card <strong>will not</strong> be charged.',
      notice: 'When 3 months pass, your payment card will be charged monthly, but if you are not satisfied with Fine.fit features, you can cancel your membership at any time on your account page.',
      success: {
        cta: 'Go to next step',
        title: '<strong>Congratulations!</strong> You are <strong>verified professional</strong> now!'
      }
    },
    basicMembership: {
      title: 'Add your payment card first<br />and <strong>become verified</strong>',
      modalTitle: 'Become verified professional!',
      labels: {
        important: 'IMPORTANT!',
        chargeNotice: 'Your payment card will <strong>NOT</strong> be charged for <strong>3 months</strong> during the free trial period, <strong>you can cancel</strong> anytime!',
        addCard: 'ADD YOUR CREDIT CARD (to prevent fake users)'
      },
      notice: 'When 3 months pass, your payment card will be charged monthly, but if you are not satisfied with Fine.fit features, you can cancel your membership at any time on your account page.',
      success: {
        cta: 'Go to next step',
        title: '<strong>Congratulations!</strong> You are <strong>verified professional</strong> now!'
      }
    },
    applyPremium: {
      title: 'Apply for premium',
      explLabel: 'How can you help?',
      explPlaceholder: 'Explain in a free form how can you help Fine.fit',
      success: 'Your application for premium was <strong>submitted successfully</strong> and will be reviewed soon!'
    }
  },
  testers: {
    ctaBtn: 'Test user?',
    intro: 'WE WELCOME YOU<br />as an Exclusive test user!',
    invitationTitle: 'Help us with testing',
    invitation: '<p>We are inviting you to become a test user of our, soon to be launched, wellness professionals search platform.</p>',
    rewardTitle: 'You will be rewarded',
    reward: '<p>As a reward, we offer you full membership for a lifetime, <strong>when you submit your feedback.</strong></p>',
    signUpTitle: 'Just sign up & try',
    signUp: '<p>Go to <a href="https://fine.fit">www.fine.fit</a> and create a professional profile for yourself.</p>',
    feedbackTitle: 'We want to hear your ideas & opinion',
    feedback: '<p>You can give us your feedback using our feedback form and we will also be in direct contact with you.</p>',
    welcome: '<p><span style="color: #f1a444;">Welcome</span> aboard!</p>',
    contactsTitle: 'Questions / Feedback?'
  },
  testersPage: {
    ctaBtn: 'Test user?',
    intro: 'WE WELCOME YOU<br />as an Exclusive test user!',
    invitationTitle: 'Help us with testing',
    invitation: '<p>We are inviting you to become a test user of our, soon to be launched, wellness professionals search platform.</p>',
    rewardTitle: 'You will be rewarded',
    reward: '<p>As a reward, we offer you full membership for a lifetime, <strong>when you submit your feedback.</strong></p>',
    signUpTitle: 'Just sign up & try',
    signUp: '<p>Go to <a href="https://fine.fit">www.fine.fit</a> and create a professional profile for yourself.</p>',
    feedbackTitle: 'We want to hear your ideas & opinion',
    feedback: '<p>You can give us your feedback using our feedback form and we will also be in direct contact with you.</p>',
    welcome: '<p><span style="color: #f1a444;">Welcome</span> aboard!</p>',
    contactsTitle: 'Questions / Feedback?'
  },
  feedback: {
    submit: 'Submit your feedback',
    title: 'Your experience on Fine.fit',
    description: 'We want to hear your ideas & opinion.',
    question1: 'Overall feeling of Fine.fit?',
    question2: 'How easy it was to create a profile?',
    question3: 'Fine.fit features in overall?',
    question4: 'Are there any features that you miss?',
    question5: 'Are there useless features?',
    question6: 'Your own suggestions?',
    yourAnswer: 'Your answer here',
    submitted: '<strong>Thank you for your help and feedback, you are a lifetime Premium-member in our service!</strong><br />This badge is now visible on your profile page, and other premium member features/benefits will soon be ready for you to use.'
  },
  currencies: {
    eur: '€',
    usd: '$',
    gbp: '£',
    sek: 'kr',
    yen: '¥'
  },
  marketing: {
    locked: {
      title: 'Locked',
      premium: 'Only <strong>premium members</strong> have option to create affiliate partner ads.',
      basic: 'You have to be <strong>verified professional</strong> to place ad on your <strong>profile page</strong>.'
    },
    offlineTitle: 'Face-to-face training<br />marketing campaigns',
    onlineTitle: 'Online training<br />marketing campaigns',
    menuNotice: 'Select from the menu above.',
    boost: 'Boost your profile',
    create: 'Marketing options',
    campaigns: {
      offline: {
        title: 'My face-to-face campaigns'
      },
      online: {
        title: 'My online campaigns',
        locked: {
          title: 'Locked',
          description: 'You have to add <strong>online services</strong> first.',
          cta: 'Add online services'
        }
      }
    },
    campaignsDesc: 'Your currently active and past marketing campaigns',
    placement: {
      search: 'Search results',
      profile: 'Profile page'
    },
    paidSearch: {
      offline: {
        title: 'First on face-to-face search results'
      },
      online: {
        title: 'First on online services search results'
      },
      title: 'First on search results',
      notPublished: 'Your profile is <strong>not published</strong> yet.<br />Only published professionals can be boosted on search results.<br /><strong>Finish your profile</strong> and try again!',
      adLabel: 'Ad',
      impressions: 'impressions',
      save: 'Save',
      steps: {
        step0: 'Choose campaign',
        step1: 'Budget',
        step2: 'Targeted keywords',
        step3: 'Payment'
      },
      plans: {
        label: 'Choose your plan',
        empty: 'No plans available',
        pricesNotice: 'Prices can vary depending on demand.',
        chosen: 'Chosen plan'
      },
      keywords: {
        notice: 'Max 4 keywords per each campaign. Max 3 words per each keyword. Max 30 characters per keyword. No duplicates.',
        currentlyActive: 'Currently active keywords',
        paymentBtn: 'Save & Continue to payment'
      },
      addedSuccess: 'Your campaign was <strong>created</strong> successfully!',
      updatedSuccess: 'Your campaign was <strong>updated</strong> successfully!',
      payment: {
        payBtn: 'Pay',
        payStartBtn: 'Pay & Start Campaign',
        success: '<strong>Success!</strong> Your campaign now is up and running!<br />You can check the campaign statistics under subpage <strong>"My campaigns"</strong>.'
      },
      details: {
        campaign: 'Campaign',
        created: 'Created',
        keywords: 'Keywords',
        price: 'Price',
        impressions: 'impressions',
        clicks: 'Clicks on ad',
        status: 'Status',
        active: 'Active',
        ended: 'Ended',
        paused: 'Paused',
        unpaid: 'Not paid',
        resumeBtn: 'Resume',
        pauseBtn: 'Pause',
        empty: 'You haven\'t created any campaign yet',
        createBtn: 'Create campaign'
      }
    },
    ads: {
      offline: {
        title: 'Face-to-face services ads',
        customTitle: 'Custom face-to-face services ad',
        affiliatesTitle: 'Affiliate partner ads for face-to-face services'
      },
      online: {
        title: 'Online services ads',
        customTitle: 'Custom online services ad',
        affiliatesTitle: 'Affiliate partner ads for online services'
      },
      title: 'Ads',
      customTitle: 'Custom ad',
      affiliatesTitle: 'Affiliate partner ads',
      adLabel: 'Ad',
      impressions: 'impressions',
      save: 'Save',
      savedLabel: 'Saved',
      unsavedLabel: 'Not saved',
      unpaidLabel: 'Not paid',
      publishProfileBtn: 'Save & publish ad on profile',
      steps: {
        step0: 'Choose campaign',
        step1: 'Budget',
        step2: 'Ad content',
        step3: 'Targeted keywords',
        step4: 'Payment',
        step5: 'Finish'
      },
      affiliates: {
        label: 'Select affiliate partner',
        discountCodeLabel: 'Your discount code',
        chosen: 'Chosen affiliate partner',
        bodyclub: {
          adTitle: 'Get up to 20% off',
          adDescription: 'Use my code [code] to get up to 20% discount on Bodyclub.fi!',
          adUrl: 'http://www.bodyclub.fi',
          adImage: '',
          ctaLabel: 'Check products'
        }
      },
      placement: {
        label: 'Ad placement',
        profile: 'Show on <strong>profile page</strong>',
        search: 'Show on <strong>search results</strong>',
        chosen: 'Chosen placement',
        multiple: 'can select multiple'
      },
      plans: {
        label: 'Set your budget',
        empty: 'No plans available',
        pricesNotice: 'Prices can vary depending on demand.',
        chosen: 'Chosen plan',
        profilePrice: 'FOR FREE',
        searchPrice: 'SET BUDGET',
        profileExistingNotice: '<strong>You have an active campaign on your profile page.</strong><br />There can be only 1 active campaign on profile page. Would you like to pause it and create a new one?',
        stopActiveBtn: 'Yes, pause the active ad',
        contentBtn: 'Continue to Ad content'
      },
      content: {
        label: 'Add your content',
        adTitle: 'Ad title',
        adDescription: 'Ad description',
        adCta: 'CTA button label',
        adUrl: 'External URL',
        adPreview: 'Ad preview',
        notice: 'Max 4 keywords per each campaign. Max 3 words per each keyword. Max 30 characters per keyword. No duplicates.',
        currentlyActive: 'Currently active keywords',
        keywordsBtn: 'Continue to targeted keywords',
        editBtn: 'Edit Ad content',
        profileFinishBtn: 'Finish & publish ad on profile',
        finishBtn: 'Finish & publish'
      },
      keywords: {
        notice: 'Max 4 keywords per each campaign. Max 3 words per each keyword. Max 30 characters per keyword. No duplicates.',
        currentlyActive: 'Currently active keywords',
        paymentBtn: 'Continue to payment'
      },
      addedSuccess: 'Your campaign was <strong>created</strong> successfully!',
      updatedSuccess: 'Your campaign was <strong>updated</strong> successfully!',
      payment: {
        payBtn: 'Pay & Start Campaign',
        success: '<strong>Success!</strong> Your campaign now is up and running!<br />You can check the campaign statistics under subpage <strong>"My campaigns"</strong>.'
      },
      details: {
        campaign: 'Campaign',
        created: 'Created',
        keywords: 'Keywords',
        price: 'Price',
        impressions: 'impressions',
        clicks: 'Clicks on ad',
        status: 'Status',
        active: 'Active',
        ended: 'Ended',
        paused: 'Paused',
        unpaid: 'Not paid',
        resumeBtn: 'Resume',
        pauseBtn: 'Pause',
        empty: 'You haven\'t created any campaign yet',
        createBtn: 'Create campaign',
        profileExistingNotice: '<strong>You have an active campaign on your profile page.</strong><br />There can be only 1 active campaign on profile page. Would you like to pause it to activate this campaign?',
      }
    }
  },
  checkout: {
    payByExisting: 'Pay by existing card',
    addCard: 'Add payment card',
    notice: 'Fine.fit does not store any payment card information. Your payment card information is processed by Stripe payment system.'
  },
  membership: {
    title: 'Membership',
    label: 'Membership level',
    upgradeServicesPricing: 'For a <strong>free member</strong>, your services pricing will not be visible on your profile for your customers. <strong>Upgrade your membership</strong> to unlock this feature and more!',
    upgradeServicesDescription: 'For a <strong>free member</strong>, your services description will not be visible on your profile for your customers, but still can be searched. <strong>Upgrade your membership</strong> to unlock this feature and more!',
    upgradeAds: 'For a <strong>free member</strong>, your advertisements will not be visible on your profile for your customers. <strong>Upgrade your membership</strong> to unlock this feature and more!',
    upgradePricing: 'For a <strong>free member</strong>, your services pricing will not be visible on your profile for your customers. <strong>Upgrade your membership</strong> to unlock this feature and more!',
    upgradeSocialActivities: 'For a <strong>free member</strong>, your social activities will not be visible on your profile for your customers, but still can be searched. <strong>Upgrade your membership</strong> to unlock this feature and more!',
    free: 'Free member',
    basic: 'Verified professional',
    premium: 'Premium member',
    1: 'Free member',
    2: 'Verified professional',
    3: 'Premium member',
    paymentType: {
      free: 'Free trial',
      charge_automatically: 'Automatic renewal'
    },
    currentMembership: 'Current membership',
    pricePaid: 'Price paid',
    membershipExpires: 'Membership expires in',
    paymentInfo: 'Payment info',
    daysLeft: '{{days}} days',
    renewalCanceled: 'Your membership auto-renewal was canceled.',
    renewalNotice: '<strong>Add new or make an existing payment card as primary</strong> to pay monthly membership fee and still use the benefits of verified membership.',
    paidPeriod: 'Paid period',
    paymentTypeLabel: 'Payment type',
    cardPrimaryNotice: 'You haven\'t set any existing payment card as primary (default) for membership auto-renewal. Select one from the list or add a new one.',
    cardPrimaryMissing: 'You haven\'t attached any payment card for membership auto-renewal.',
    addedCards: 'Added payment cards',
    cardExp: 'Exp.',
    primaryCard: 'PRIMARY CARD',
    setPrimary: 'Set as primary',
    upgrade: {
      title: 'Upgrade your account',
      intro: 'to become more visible on search results and unlock verified professional features!'
    }
  },
  pricingPage: {
    title: 'Choose a plan',
    month: 'month',
    upgraded: 'Already upgraded',
    existing: 'You have this membership!',
    free: {
      title: 'Free',
      cta: 'Use free plan'
    },
    basic: {
      title: 'Professional',
      cta: 'Try 3 months for free'
    },
    premium: {
      title: 'Premium',
      cta: 'Apply for premium',
      description: '<p><strong>Premium members are limited number of professionals who help Fine.fit to grow (by testing new features under development or promoting Fine.fit).</strong></p>' +
        '<p>You can apply for premium membership by submitting appliance form explaining how you can help Fine.fit. Every appliance is manually reviewed.</p>',
      locked: {
        title: 'Locked',
        description: 'You have to be at least <strong>free</strong> member first to apply for premium membership.'
      }
    },
    features: {
      feature1: '<span>Public</span> profile',
      feature2: '<span>Add</span> locations',
      feature3: '<span>Add</span> face-to-face services',
      feature4: '<span>Add</span> online services',
      feature5: '<span>Add</span> basic descriptions',
      feature6: '<span>Use</span> messaging',
      feature7: '<span>Show</span> verified member badge',
      feature8: '<span>Show</span> pricings',
      feature9: '<span>Show</span> detailed descriptions',
      feature10: '<span>Show</span> your social activities',
      feature11: '<span>Use</span> bookings & revenue calendar',
      feature12: '<span>Add</span> custom ads on profile',
      feature13: '<span>Boost</span> your profile on search results*',
      feature14: '<span>Boost</span> your ads on search results*',
      feature15: '<span>Get</span> ratings & reviews',
      feature16: '<span>Show</span> premium member badge',
      feature17: '<span>Featured</span> professional',
      feature18: '<span>Higher</span> on search results',
      feature19: '<span>Add</span> affiliate ads on profile',
      feature20: '<span>Boost</span> affiliate ads on search results *'
    },
    footnote: '* Boosting your profile or ads on search results is for extra charge.'
  },
  services: {
    acrobatics: 'Acrobatics',
    bikiniFitness: 'Bikini fitness',
    bodybuilding: 'Body building',
    callisthenics: 'Callisthenics',
    competitionCoaching: 'Competition coaching',
    crossfit: 'Crossfit',
    enduranceSports: 'Endurance sports',
    fitness: 'Fitness',
    fodmap: 'Fodmap',
    functionalTraining: 'Functional training',
    fustra: 'Fustra',
    glutenFree: 'Gluten free',
    groupTraining: 'Group training',
    gymnastics: 'Gymnastics',
    gymTraining: 'Gym training',
    handstanding: 'Handstanding',
    highIntensityIntervalTraining: 'High intensity interval training',
    hydrobics: 'Hydrobics',
    hydroRunning: 'Hydro running',
    kettlebell: 'Kettlebell',
    largeGroup: 'Large group',
    martialArts: 'Martial arts',
    meditation: 'Meditation',
    mentalCoaching: 'Mental coaching',
    methodMakia: 'Method Makia',
    methodPutkisto: 'Method Putkisto',
    milkFree: 'Milk free',
    mindfulness: 'Mindfulness',
    movement: 'Movement',
    movementImpaired: 'Movement impaired',
    nutritionPrograms: 'Nutrition programs',
    others: 'Others',
    otherSports: 'Other sports',
    paleo: 'Paleo',
    performanceImprovement: 'Performance improvement',
    personalTraining: 'Personal training',
    physiotherapy: 'Physiotherapy',
    pilates: 'Pilates',
    postPregnancy: 'Post pregnancy',
    powerLifting: 'Power lifting',
    pregnancyTimeTraining: 'Pregnancy time training',
    remoteTraining: 'Remote training',
    running: 'Running',
    seniorCitizens: 'Senior citizens',
    smallGroup: 'Small group',
    strengthAndMuscle: 'Strength and muscle mass gain',
    stressControl: 'Stress control',
    swimming: 'Swimming',
    therapeutic: 'Therapeutic',
    vegetarian: 'Vegetarian',
    weightLifting: 'Weight lifting',
    weightLoss: 'Weight loss',
    wellness: 'Wellness - health and well being',
    yoga: 'Yoga',
    exercises: 'Exercises',
    recuperation: 'Recuperation',
    generalMentalWellbeing: 'General mental wellbeing',
    generalPerformance: 'General performance',
    sexualWellbeing: 'Sexual wellbeing',
    sportSpecific: 'Sport specific'
  },
  notifications: {
    new_review: {
      title: '{name} has reviewed you!',
      name: 'Received a new review',
      body: '{name} has left a review on your profile: \"{review}\"'
    },
    new_message: {
      title: '{name} sent you a message',
      name: 'Received a new message',
      body: '{name}: "{message}"'
    },
    new_booking: {
      title: '{name} wants to book a session!',
      name: 'Received a new booking request',
      body: '{name} wants to book your session on "{date}", special requests: "{message}"'
    },
    new_follower: {
      title: '{name} started following you!',
      name: 'New follower',
      body: '{name} started following you'
    }
  },
  errors: {
    400: 'Bad Request',
    404: 'Not Found',
    500: 'Internal Server Error',
    1001: 'Employee already registered.',
    1002: 'Unauthorized',
    1003: 'Current offer option is sold out!',
    1004: 'Deleted must be boolean!',
    1005: 'Enable must be boolean!',
    1006: 'Cannot self add to employee.',
    1007: 'Daily limit, maximum 10 emails per day!',
    1008: 'Data can be set once per day',
    1009: 'The Date from is not a valid date format.',
    1010: 'The Date to is not a valid date format.',
    1011: 'Category Not Exists!',
    1012: 'Not found into database.',
    1013: 'Offer option does not exists!',
    1014: 'Password reset link is broken!',
    1015: 'Is not current Company offer!',
    1016: 'Db error.',
    1017: 'Type Not Exists!',
    1018: 'The Email format is invalid.',
    1019: 'The Offer id must be an integer.',
    1020: 'The Offer option id must be an integer.',
    1021: 'Failed to refresh token',
    1022: 'Failed to revoke token',
    1023: 'Invalid image extension, allowed (jpg, jpeg, gif, png)',
    1024: 'Maximum image size 1 Mb.',
    1025: 'Invalid email or password!',
    1026: 'Failed to get id from iWOWNFit',
    1027: 'Offer option limit reached by user.',
    1028: 'Failed to send email',
    1029: 'The Comment may not be greater than :max characters.',
    1030: 'The Name may not be greater than :max characters.',
    1031: 'No access level.',
    1032: 'The Target must be a number.',
    1033: 'Current Password must match.',
    1034: 'Balance Points not enough.',
    1035: 'Invalid Target type id.',
    1036: 'Available must be positive integer!',
    1037: 'Invalid Company id.',
    1038: 'Invalid Count id.',
    1039: 'Invalid id.',
    1040: 'Password reset link is broken!',
    1041: 'Invalid Period id.',
    1042: 'Invalid Phone nr. ex:(+37136363634, 0037121237979)',
    1043: 'Points must be positive integer!',
    1044: 'Invalid Price id.',
    1045: 'Invalid id',
    1046: 'Invalid Target type id.',
    1047: 'Failed to request outside api',
    1048: 'The Active field is required.',
    1049: 'The Available field is required.',
    1050: 'Available Points is required.',
    1051: 'The Comment field is required.',
    1052: 'The Company id field is required.',
    1053: 'The Count field is required.',
    1054: 'The Date from field is required.',
    1055: 'The Date to field is required.',
    1056: 'The Deleted field is required.',
    1057: 'The Description field is required.',
    1058: 'The Devices field is required.',
    1059: 'The Email field is required.',
    1060: 'The First name field is required.',
    1061: 'Type id is required.',
    1062: 'Name is required.',
    1063: 'The Offer id field is required.',
    1064: 'The Offer option id field is required.',
    1065: 'The Password field is required.',
    1066: 'The New Password field is required.',
    1067: 'The Password repeat field is required.',
    1068: 'Link required.',
    1069: 'The Period type field is required.',
    1070: 'The Phone field is required.',
    1071: 'The Phone Code field is required.',
    1072: 'The Phone Number field is required.',
    1073: 'Points is required.',
    1074: 'The Position field is required.',
    1075: 'The Price field is required.',
    1076: 'The Role id field is required.',
    1077: 'The address field is required.',
    1078: 'The Target field is required.',
    1079: 'The Target type field is required.',
    1080: 'Title is required.',
    1081: 'Url is required.',
    1082: 'The Values field is required.',
    1083: 'Password must match.',
    1084: 'The Description field is required.',
    1085: 'Token expired.',
    1086: 'Unknown error.',
    1087: 'Email already in use.',
    1088: 'Broken registration link.',
    1089: 'Cannot Create Token.',
    1090: 'Failed to set token.',
    1091: 'Token invalid.',
    1092: 'The Phone nr must be an integer.',
    1093: 'The Phone code must be an string.',
    1094: 'Company Not Exists!',
    1095: 'Company reg. Nr. is required.',
    1096: 'Street is required.',
    1097: 'City is required.',
    1098: 'Street Nr. is required.',
    1099: 'Postal code is required.',
    1100: 'Country is required.',
    1101: 'Region is required.',
    1102: 'Bank name is required.',
    1103: 'Account Nr. is required.',
    1104: 'Swift is required.',
    1105: 'This address is already taken.',
    1106: 'User already has Company.',
    1107: 'Provider id is required.',
    1108: 'Provider not exists.',
    1109: 'Not Company Employee.',
    1110: 'Employee already registered.',
    1111: 'Integration not found.',
    1112: 'Options title must be an string.',
    1113: 'The option points must be an integer.',
    1114: 'The option available count must be an integer.',
    1115: 'The option limit by user must be an integer.',
    1116: 'API client is not authorized by the resource owner to access the resource requested.',
    1117: 'Missing params.',
    1118: 'API client is not authorized by the resource owner to access the resource requested.',
    1119: 'Challenge is not active.',
    1120: 'User has already applied to this challenge.',
    1121: 'User has already completed this day.',
    1122: 'User has not applied to this challenge.',
    1123: 'Image is required to complete this challenge.',
    1124: 'Challenge has not started yet.',
    1125: 'Day index cannot be zero.',
    1126: 'Challenge apply deadline has passed.',
    1127: 'Challenge day has not started yet.',
    1128: 'Day index cannot be bigger than overall days count.',
    1129: 'The achievement ID is required.',
    1150: 'The achievement ID must be an integer.',
    1151: 'Please, do not spam.',
    1152: 'Login failed.',
    1153: 'Unable to add image.',
    1154: 'Short description field is required.',
    1161: 'Email notifications field is required.',
    1162: 'The email notifications must be an boolean.',
    1163: 'The challenge ID is required.',
    1164: 'The challenge ID must be an integer.',
    1165: 'The terms agree is required.',
    1166: 'The terms agree must be an boolean.',
    1167: 'Registered via Facebook or Google, and password not set.',
    1170: 'Email not provided. Please register by using your email.',
    1171: 'Image does not exist.',
    1172: 'Day is required.',
    1173: 'The day must be an integer.',
    1174: 'Your image must be shared on Facebook.',
    1175: 'Social shared must be an integer.',
    1176: 'Image can be changed only within 1 hour.',
    1177: 'Challenge does not exists!',
    1178: 'Some of partner(s) not exists.',
    1179: 'Partner id is required',
    1180: 'Partner id must be an integer',
    1181: 'This is not your Company challenge!',
    1182: 'Partner does not exist',
    1183: 'This partner does not belong to current company',
    1184: 'Prize id is required',
    1185: 'Prize id must be an integer',
    1186: 'Prize does not exist',
    1187: 'This prize does not belong to current company',
    1188: 'Partners field is required',
    1189: 'Partners must be an array[]',
    1190: 'Quantity is required',
    1191: 'Quantity must be an integer',
    1192: 'Order nr is required',
    1193: 'Order nr must be an integer',
    1194: 'Prizes order nr is required',
    1195: 'Prizes order must be an array',
    1196: 'Invalid password',
    1197: 'Winners count is required',
    1198: 'Winners count must be an integer',
    1199: 'Winners count must be greater than 0',
    1200: 'Min points is required',
    1201: 'Min points must be an integer',
    1202: 'Win quantity is required',
    1203: 'Win quantity must be an integer',
    1204: 'Win quantity must be greater than 0',
    1205: '"Can win again?" is required',
    1206: '"Can win again?" must be boolean',
    1207: 'Winners quantity must be the same as winners count',
    1208: 'Prizes available quantity is less than winners',
    1209: 'You have unfinished lottery',
    1210: 'Lottery id is required',
    1211: 'Lottery id must be an integer',
    1212: 'Accepted winners is required',
    1213: 'Accepted winners must be an array',
    1214: 'Declined winners is required',
    1215: 'Declined winners must be an array',
    1216: 'Invalid lottery',
    1217: 'Invalid qr code(s)',
    1218: 'Declined winners must be less than actual winners',
    1219: 'Employees csv is required',
    1220: 'Maximum {{}} New Employees Allowed.',
    1221: 'Employee email: \'{{}}\' already registered',
    1222: 'Employee first name is empty, line: {{}}',
    1223: 'Employee last name is empty, line: {{}}',
    1224: 'Employee email is empty, line: {{}}',
    1225: 'You have added maximum count of employees.',
    1226: 'Plan not found for current company.',
    1227: 'Current plan does not allow to add more challenges.',
    1228: 'Plan allow maximum {{}} challenge day(s)',
    1229: 'Parent is required',
    1230: 'Parent must be an integer',
    1231: 'Parent must be an positive integer',
    1232: 'Team parent not found',
    1233: 'Company team not found',
    1234: 'Teams order required',
    1235: 'Teams order must be an array',
    1236: 'Teams id is required',
    1237: 'Teams id must be an integer',
    1238: 'Employees is required',
    1239: 'Employees must be an array',
    1240: 'Employees can be add only on last level',
    1241: 'Duplicate email in CSV file',
    1242: 'Team cannot be changed after 24 hours',
    1243: 'Only company employee can choose a team',
    1244: 'Not allowed to delete team(s) with added emplooyees',
    1245: 'Enabled field is required',
    1246: 'Invalid company password',
    1248: 'Your slug contains invalid characters. Only latin letters (a-z), numbers (0-9), and hyphen (-) are allowed',
    1249: 'This slug is taken.',
    1250: 'Your slug is too long. It exceeds the maximum length of 20 characters',
    1251: 'The message must be at least 10 characters',
    1252: 'The message field is required.',
    1253: 'Invalid rating.',
    1254: 'Rating is required',
    1255: 'Location is required',
    1256: 'Service is required',
    1257: 'Date and time is required',
    1260: 'Duplicate keyword detected. Keyword <strong><i>{{var}}</i></strong> exists in your other currently active campaign.',
    1261: 'Keyword must contain not more than <strong>{{var}} words</strong>.',
    1262: 'Keyword must not contain <strong>duplicate words</strong>.',
    1263: '<strong>Your email is not confirmed yet.</strong><br />Go to your inbox and find email from Fine.fit to confirm your email address.',
    2001: 'Data not available yet.',
    2004: 'You have to read and agree to Terms & Conditions and Privacy Policy.',
    2005: 'You have to read and agree to Privacy Policy.',
    2006: 'Authorization failed. Try again later.',
    2007: 'Could not authorize - token was empty.'
  }
}

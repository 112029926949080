import * as api from '../api'
import store from '@/store'
import router from '@/router'

/* Professional profile */
export const getProfessionalInfo = ({ commit }, { context, payload }) => {
  context.isLoading = true
  context.contentLoaded = false
  api.getProfessionalInfo(payload).then((response) => {
    context.isLoading = false
    commit('SET_PROFESSIONAL_INFO_DATA', response.data.data)
    context.contentLoaded = true
    context.setMetaTags(response.data.data)
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const addProfessionalReview = ({ commit, dispatch }, { context, payload }) => {
  context.errors = []
  context.isReviewLoading = true
  context.reviewSuccess = false
  api.addProfessionalReview(payload).then((response) => {
    context.reviewSuccess = true
    context.isReviewLoading = false
    setTimeout(function () { dispatch('getProfessionalInfo', { context: context, payload: payload.slug }) }, 2000)
  }).catch((error) => {
    context.isReviewLoading = false
    context.errors = error.response.data.errors
  })
}

export const addProfessionalBooking = ({ commit, dispatch }, { context, payload }) => {
  context.errors = []
  context.success = false
  context.isLoading = true
  api.addProfessionalBooking(payload).then((response) => {
    context.success = true
    context.isLoading = false

    setTimeout(function () {
      // Prepare booking message and send it to professional
      if (!_.isUndefined(response.data.data) && !_.isEmpty(response.data.data) && !_.isUndefined(response.data.data['_id'])) {
        console.log('Booking response data', response.data)
        let bookingId = response.data.data['_id']
        context.prepareAndSendMessage(bookingId)
      }
    }, 2000)
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const getProfessionalOfflineAds = ({ commit }, { context, payload }) => {
  api.getProfessionalOfflineAds(payload).then((response) => {
    commit('SET_PROFESSIONAL_OFFLINE_ADS_DATA', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getProfessionalOnlineAds = ({ commit }, { context, payload }) => {
  api.getProfessionalOnlineAds(payload).then((response) => {
    commit('SET_PROFESSIONAL_ONLINE_ADS_DATA', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

/* Beginning of face-to-face search */
export const searchProfessionals = ({ commit, dispatch }, { context, payload }) => {
  commit('SET_SEARCH_LOADING', true)
  context.isResultsLoading = false
  api.searchProfessionals(payload).then((response) => {
    commit('SET_SEARCH_LOADING', false)
    context.isResultsLoading = false
    commit('SET_SEARCH_RESULTS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
    commit('SET_SEARCH_LOADING', false)
    context.isResultsLoading = false
  })
}

export const searchProfessionalsNames = ({ commit, dispatch }, { context, payload }) => {
  commit('SET_SEARCH_LOADING', true)
  context.isNamesLoading = false
  api.searchProfessionalsNames(payload).then((response) => {
    commit('SET_SEARCH_LOADING', false)
    context.isNamesLoading = false
    commit('SET_SEARCH_NAMES_RESULTS', response.data.data)
  }).catch((error) => {
    commit('SET_SEARCH_LOADING', false)
    context.errors = error.response.data.errors
    context.isResultsLoading = false
  })
}

export const searchProfessionalsService = ({ commit, dispatch }, { context, payload }) => {
  commit('SET_SEARCH_LOADING', true)
  context.isResultsLoading = false
  api.searchProfessionalsService(payload).then((response) => {
    commit('SET_SEARCH_LOADING', false)
    context.isResultsLoading = false
    commit('SET_SEARCH_RESULTS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
    commit('SET_SEARCH_LOADING', false)
    context.isResultsLoading = false
  })
}

export const searchBoostedProfessionals = ({ commit, dispatch }, { context, payload }) => {
  // commit('SET_SEARCH_LOADING', true)
  // context.isResultsLoading = false
  api.searchBoostedProfessionals(payload).then((response) => {
    // commit('SET_SEARCH_LOADING', false)
    // context.isResultsLoading = false
    commit('SET_SEARCH_RESULTS_BOOSTED', response.data.data)
  }).catch((error) => {
    // context.errors = error.response.data.errors
    // commit('SET_SEARCH_LOADING', false)
    // context.isResultsLoading = false
  })
}

export const searchAds = ({ commit, dispatch }, { context, payload }) => {
  // commit('SET_SEARCH_LOADING', true)
  // context.isResultsLoading = false
  api.searchAds(payload).then((response) => {
    // commit('SET_SEARCH_LOADING', false)
    // context.isResultsLoading = false
    commit('SET_SEARCH_RESULTS_ADS', response.data.data)
  }).catch((error) => {
    // context.errors = error.response.data.errors
    // commit('SET_SEARCH_LOADING', false)
    // context.isResultsLoading = false
  })
}

export const incCampaignClicks = ({ commit }, { payload }) => {
  api.incCampaignClicks(payload).then((response) => {
    // Nothing
  }).catch((error) => {
    // Nothing
  })
}
/* End of face-to-face search */

/* Beginning of online search */
export const searchOnlineProfessionals = ({ commit, dispatch }, { context, payload }) => {
  commit('SET_SEARCH_LOADING', true)
  context.isResultsLoading = false
  api.searchOnlineProfessionals(payload).then((response) => {
    commit('SET_SEARCH_LOADING', false)
    context.isResultsLoading = false
    commit('SET_ONLINE_SEARCH_RESULTS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
    commit('SET_SEARCH_LOADING', false)
    context.isResultsLoading = false
  })
}

export const searchOnlineProfessionalsNames = ({ commit, dispatch }, { context, payload }) => {
  commit('SET_SEARCH_LOADING', true)
  context.isNamesLoading = false
  api.searchOnlineProfessionalsNames(payload).then((response) => {
    commit('SET_SEARCH_LOADING', false)
    context.isNamesLoading = false
    commit('SET_ONLINE_SEARCH_NAMES_RESULTS', response.data.data)
  }).catch((error) => {
    commit('SET_SEARCH_LOADING', false)
    context.errors = error.response.data.errors
    context.isResultsLoading = false
  })
}

export const searchOnlineProfessionalsService = ({ commit, dispatch }, { context, payload }) => {
  commit('SET_SEARCH_LOADING', true)
  context.isResultsLoading = false
  api.searchOnlineProfessionalsService(payload).then((response) => {
    commit('SET_SEARCH_LOADING', false)
    context.isResultsLoading = false
    commit('SET_ONLINE_SEARCH_RESULTS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
    commit('SET_SEARCH_LOADING', false)
    context.isResultsLoading = false
  })
}

export const searchOnlineBoostedProfessionals = ({ commit, dispatch }, { context, payload }) => {
  // commit('SET_SEARCH_LOADING', true)
  // context.isResultsLoading = false
  api.searchOnlineBoostedProfessionals(payload).then((response) => {
    // commit('SET_SEARCH_LOADING', false)
    // context.isResultsLoading = false
    commit('SET_ONLINE_SEARCH_RESULTS_BOOSTED', response.data.data)
  }).catch((error) => {
    // context.errors = error.response.data.errors
    // commit('SET_SEARCH_LOADING', false)
    // context.isResultsLoading = false
  })
}

export const searchOnlineAds = ({ commit, dispatch }, { context, payload }) => {
  // commit('SET_SEARCH_LOADING', true)
  // context.isResultsLoading = false
  api.searchOnlineAds(payload).then((response) => {
    // commit('SET_SEARCH_LOADING', false)
    // context.isResultsLoading = false
    commit('SET_ONLINE_SEARCH_RESULTS_ADS', response.data.data)
  }).catch((error) => {
    // context.errors = error.response.data.errors
    // commit('SET_SEARCH_LOADING', false)
    // context.isResultsLoading = false
  })
}

export const incOnlineCampaignClicks = ({ commit }, { payload }) => {
  api.incOnlineCampaignClicks(payload).then((response) => {
    // Nothing
  }).catch((error) => {
    // Nothing
  })
}
/* End of online search */

export const followProfessional = ({ commit, dispatch }, { context, payload }) => {
  api.followProfessional(payload).then((response) => {
    store.dispatch('auth/fetchUser')
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const unfollowProfessional = ({ commit, dispatch }, { context, payload }) => {
  api.unfollowProfessional(payload).then((response) => {
    store.dispatch('auth/fetchUser')
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

<template>
  <div class="response">
    <b-alert v-if="response.length > 0"
             :variant="variant"
             v-for="item in response"
             :key="item.code"
             show
             :dismissible="variant === 'success'">
      <div v-if="typeof item.code !== 'undefined'">
        <span v-if="!_.isUndefined(item.var)" v-html="$t(`errors.${item.code}`, { var: item.var })"></span>
        <span v-else v-html="$t(`errors.${item.code}`)"></span>
      </div>
      <div v-else>
        <span v-html="item"></span>
      </div>
    </b-alert>
    <b-alert v-if="text"
             :variant="variant"
             show>
      <div v-html="text"></div>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'response',
  props: {
    items: {
      type: Array,
      default: () => [],
      required: false
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
    variant: {
      type: String,
      default: 'danger',
      required: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    response () {
      return this.items.slice(0, 5)
    }
  }
}
</script>

<style lang="scss" scoped>
  .response {
    width: 100%;

    .alert:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    .alert:last-child {
      margin-bottom: 0;
    }
  }
</style>

import * as storage from '@/util/storage'
import Debug from '@/util/debug'
import store from '../store'
import { i18n } from '@/app/boot'
import { AFTER_LOGIN_PAGE } from '@/util/constants'
import Vue from 'vue'

const debug = Debug('router:beforeEach')

const beforeEach = (to, from, next) => {

  // Making an exception when navigating to fine.fit/fi
  if (to.name === 'ProfessionalsSingle.en' && to.params.slug === 'fi') {
    i18n.i18next.changeLanguage('fi')
    next({ name: 'Home.fi' })
  }

  let lang = 'en'
  if ('lang' in to.query || 'lang' in to.params) {
    lang = to.query.lang || to.params.lang
    // TODO: Automatically check in available languages
    if (lang === 'en' || lang === 'fi') {
      i18n.i18next.changeLanguage(lang)
    }
  }

  if (!_.isEmpty(to.meta)) {
    if ('lang' in to.meta) {
      let lang = to.meta.lang
      i18n.i18next.changeLanguage(lang)
    }
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  if (nearestWithMeta) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    }).forEach(tag => document.head.appendChild(tag))
  }

  // Search box
  let elem = document.getElementById('darkside')
  if (elem) {
    elem.parentNode.removeChild(elem)
  }

  // Search box on mobile device
  document.body.className = document.body.className.replace('search-open', '')

  store.dispatch('auth/checkTokenExists').then(() => {
    debug.info('is logged in')
    if (to.meta.refreshToken) {
      store.dispatch('auth/fetchUser').then(() => {
        next({ name: AFTER_LOGIN_PAGE })
      })
      return
    } else if (to.meta.guest) {
      next()
    } else if (to.meta.adminAuth) {
      debug.info('Needs admin auth')
      next({ name: AFTER_LOGIN_PAGE })
      return
    }

    // Check if there are any unsaved changes
    let unsavedChanges = store.getters['unsavedChanges']
    // alert(unsavedChanges)
    if (unsavedChanges) {
      // alert('unsavedChanges')
      // const answer = window.confirm = function (e) {
      //   return 'Do you really want to leave? you have unsaved changes!';
      // };
      const answer = window.confirm('You have unsaved changes, do you really want to leave?')
      if (answer) {
        store.commit('SET_UNSAVED_CHANGES', false)
        return next()
      } else {
        return next(false)
      }
      // next()
    } else {
      next()
    }
  }).catch(() => {
    debug.warn('is not logged in')
    if (to.meta.needsAuth) {
      storage.set('intended', to.name)
      next({ name: `Home.${lang}` })
      return
    }
    next()
  })
}

export default beforeEach

import axios from 'axios'

export const socialSignIn = (payload) => {
  let provider = payload.provider
  return axios.post(`${process.env.API_URL}/v1/login/${provider}`, payload)
}

export const socialSignUp = (payload) => {
  let provider = payload.provider
  return axios.post(`${process.env.API_URL}/v1/login/${provider}`, payload)
}

export const signUpEmail = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/register/email`, payload)
}

export const resendEmailConfirmation = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/register/email-resend`, payload)
}

export const signUpAccount = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/register/account`, payload)
}

export const getUserByCode = (code) => {
  return axios.get(`${process.env.API_URL}/v1/register/${code}`)
}

export const confirmEmail = (code) => {
  return axios.put(`${process.env.API_URL}/v1/register/${code}`)
}

export const oAuthLogin = (payload) => {
  const passport = {
    client_id: 2,
    client_secret: 'qXbNllM2QbeNIjQSPuIvKubXv7BqfcimV1iM5QLi',
    grant_type: 'password',
  }

  payload = Object.assign(passport, payload)

  return axios.post(`${process.env.API_URL}/v1/login`, payload)
}

export const signOut = () => {
  return axios.post(`${process.env.API_URL}/v1/logout`)
}

export const sendForgotEmail = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/forgot`, payload)
}

export const getForgotDataByCode = (code) => {
  return axios.get(`${process.env.API_URL}/v1/forgot/${code}`)
}

export const changePassword = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/forgot/change-password`, payload)
}

export const fetchUser = () => {
  return axios.get(`${process.env.API_URL}/v1/me`)
}

export const saveUserBasicInfo = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/profile`, payload)
}

export const deleteSelf = () => {
  return axios.delete(`${process.env.API_URL}/v1/profile`)
}

export const completeDeleteSelf = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/profile/${payload}`)
}

export const sendNotificationsToken = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/notifications/token`, payload)
}

export const getNotifications = () => {
  return axios.get(`${process.env.API_URL}/v1/notifications`)
}

export const getNotificationsSettings = () => {
  return axios.get(`${process.env.API_URL}/v1/profile/notifications`)
}

export const changeNotificationSettings = (payload) => {
  return axios.put(`${process.env.API_URL}/v1/profile/notifications`, payload)
}

import axios from 'axios'

export const getFeedback = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/feedbacks/${payload}`)
}


export const sendFeedback = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/feedbacks`, payload)
}

import * as storage from '@/util/storage'

export default {
  SET_TEMP_USER: (state, user) => {
    state.signUpUser = user
  },
  SET_AUTH_REDIRECT: (state, data) => {
    state.authRedirect = data
  },
  SET_USER_NOTIFICATIONS_DATA: (state, data) => {
    state.userNotifications = data
  },
  SET_USER_NOTIFICATIONS_AS_READ: (state) => {
    if (!_.isUndefined(state.user.data.notifications_unread)) {
      state.user.data.notifications_unread = 0
    }
  },
  SET_NOTIFICATIONS_SETTINGS_DATA: (state, data) => {
    state.notificationsSettings = data
  }
}

import Vue from 'vue'
import Router from 'vue-router'

const Home = () => import(/* webpackChunkName: "Home" */ '@/app/home/components/Home')
const Contacts = () => import(/* webpackChunkName: "Home" */ '@/app/contacts/components/Contacts')
const Account = () => import(/* webpackChunkName: "Home" */ '@/app/account/components/Home')
const AccountFeed = () => import(/* webpackChunkName: "Home" */ '@/app/account/components/FeedPage')
const AccountBookings = () => import(/* webpackChunkName: "Home" */ '@/app/account/components/BookingsPage')
const AccountMarketing = () => import(/* webpackChunkName: "Home" */ '@/app/account/components/MarketingPage')
const SignInPage = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/SignInPage')
const SignUpPage = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/SignUpPage')
const ForgotPage = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/ForgotPage')
const ResetPassword = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/ResetPassword')
const Terms = () => import(/* webpackChunkName: "Home" */ '@/app/legal/components/Terms')
const Privacy = () => import(/* webpackChunkName: "Home" */ '@/app/legal/components/Privacy')
const CustomersLanding = () => import(/* webpackChunkName: "Home" */ '@/app/landing/components/Customers')
const ProfessionalsLanding = () => import(/* webpackChunkName: "Home" */ '@/app/landing/components/Professionals')
const TestersLanding = () => import(/* webpackChunkName: "Home" */ '@/app/landing/components/Testers')
const PricingLanding = () => import(/* webpackChunkName: "Home" */ '@/app/landing/components/Pricing')
const Professionals = () => import(/* webpackChunkName: "Home" */ '@/app/professionals/components/ListPage')
const OnlineProfessionals = () => import(/* webpackChunkName: "Home" */ '@/app/professionals/components/OnlineListPage')
const ProfessionalsSingle = () => import(/* webpackChunkName: "Home" */ '@/app/professionals/components/SinglePage')
const Management = () => import(/* webpackChunkName: "Home" */ '@/app/management/components/ManagementPage')

const SignIn = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/SignIn')
const SignUp = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/SignUp')
const SignUpRedirect = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/SignUpRedirect')
const ForgotRedirect = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/ForgotRedirect')
const SocialRedirect = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/SocialRedirect')
const Deleted = () => import(/* webpackChunkName: "Home" */ '@/app/auth/components/Deleted')

import { SignUpStepsEn, SignUpStepsFi } from '@/app/auth-steps/routes/signup-steps'
import { ForgotStepsEn, ForgotStepsFi } from '@/app/auth-steps/routes/forgot-steps'

import beforeEach from './beforeEach'
import afterEach from './afterEach'

Vue.use(Router)

let enRoutes = [
  {
    path: '/',
    name: 'Home.en',
    component: Home,
    meta: {
      title: 'Fine.Fit | Find professionals to help you feel fine and fit.',
      metaTags: [
        {
          name: 'description',
          content: 'Find professionals to help you feel fine and fit.'
        },
        {
          property: 'og:description',
          content: 'Find professionals to help you feel fine and fit.'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/:slug',
    name: 'ProfessionalsSingle.en',
    component: ProfessionalsSingle,
    meta: {}
  },
  {
    path: '/:slug/online',
    name: 'OnlineProfessionalsSingle.en',
    component: ProfessionalsSingle,
    meta: {}
  },
  {
    path: '/page/contacts',
    name: 'Contacts.en',
    component: Contacts,
    meta: {
      title: 'Contacts | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Contact Fine.fit by email or phone. Email: info@fine.fit. Phone: +358 50 341 8002'
        },
        {
          property: 'og:description',
          content: 'Contact Fine.fit by email or phone. Email: info@fine.fit. Phone: +358 50 341 8002'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/page/terms',
    name: 'Terms.en',
    component: Terms,
    meta: {
      title: 'Terms Of Services | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Terms of services on Fine.fit'
        },
        {
          property: 'og:description',
          content: 'Terms of services on Fine.fit'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/page/privacy',
    name: 'Privacy.en',
    component: Privacy,
    meta: {
      title: 'Privacy Policy | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Privacy policy on Fine.fit'
        },
        {
          property: 'og:description',
          content: 'Privacy policy on Fine.fit'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/page/customers-signup',
    name: 'CustomersLanding.en',
    component: CustomersLanding,
    meta: {
      title: 'Trainers, Coaches and Wellness Professionals | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'We can help you to find professional. We got this!'
        },
        {
          property: 'og:description',
          content: 'We can help you to find professional. We got this!'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/page/professionals-signup',
    name: 'ProfessionalsLanding.en',
    component: ProfessionalsLanding,
    meta: {
      title: 'Trainers, Coaches and Wellness Professionals | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/page/testers',
    name: 'TestersLanding.en',
    component: TestersLanding,
    meta: {
      title: 'Are you a test user? | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/page/pricing',
    name: 'Pricing.en',
    component: PricingLanding,
    meta: {
      title: 'Pricing | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/page/professionals/:search?/:location?/:service?',
    name: 'ProfessionalsSearch.en',
    component: Professionals,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/page/professionals',
    name: 'Professionals.en',
    component: Professionals,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/page/online-professionals/:search?/:language?/:service?/:method?',
    name: 'OnlineProfessionalsSearch.en',
    component: OnlineProfessionals,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/page/online-professionals',
    name: 'OnlineProfessionals.en',
    component: OnlineProfessionals,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/page/management',
    name: 'Management.en',
    component: Management
  },
  {
    path: '/page/account',
    name: 'Account.en',
    component: Account,
    meta: {
      noScroll: true
    },
    children: [
      {
        path: '/page/account/locations',
        name: 'AccountLocations.en',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/page/account/services',
        name: 'AccountServices.en',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/page/account/services-online',
        name: 'AccountServicesOnline.en',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/page/account/online-materials',
        name: 'AccountOnlineMaterials.en',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/page/account/certificates',
        name: 'AccountCertificates.en',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/page/account/social',
        name: 'AccountSocial.en',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/page/account/settings',
        name: 'AccountSettings.en',
        component: Account,
        meta: {
          noScroll: true
        }
      }
    ]
  },
  {
    path: '/page/account',
    name: 'SignInSuccess.en',
    component: Account
  },
  {
    path: '/page/feed',
    name: 'AccountFeed.en',
    component: AccountFeed
  },
  {
    path: '/page/bookings',
    name: 'AccountBookings.en',
    component: AccountBookings
  },
  {
    path: '/page/marketing',
    name: 'AccountMarketing.en',
    component: AccountMarketing,
    children: [
      {
        path: '/page/marketing/create',
        name: 'MarketingCreate.en',
        component: AccountMarketing,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/page/marketing/campaigns',
        name: 'MarketingCampaigns.en',
        component: AccountMarketing,
        meta: {
          noScroll: true
        }
      }
    ]
  },
  {
    path: '/page/sign-in',
    name: 'SignIn.en',
    component: SignIn,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/page/sign-in/success',
    name: 'SignIn.Success.en',
    component: SignIn,
    meta: { refreshToken: true }
  },
  {
    path: '/page/sign-up/:code',
    name: 'SignUp.Redirect.en',
    component: SignUpRedirect,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/page/sign-up',
    name: 'SignUp.en',
    component: SignUpPage,
    props: true,
    children: SignUpStepsEn,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/page/reset-password',
    name: 'Forgot.en',
    component: ForgotPage,
    props: true,
    children: ForgotStepsEn,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/page/reset-password/:code',
    name: 'Forgot.Redirect.en',
    component: ForgotRedirect,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/page/account/:code',
    name: 'Deleted.en',
    component: Deleted,
    props: true
  },
  {
    path: '/page/social',
    name: 'Social.en',
    component: SocialRedirect,
    props: true,
    meta: { guest: true, needsAuth: false }
  }
]

let fiRoutes = [
  {
    path: '/fi/',
    name: 'Home.fi',
    component: Home,
    meta: {
      lang: 'fi',
      title: 'Fine.Fit | Find professionals to help you feel fine and fit.',
      metaTags: [
        {
          name: 'description',
          content: 'Löydä sinulle sopivin valmentaja.'
        },
        {
          property: 'og:description',
          content: 'Löydä sinulle sopivin valmentaja.'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/fi/:slug',
    name: 'ProfessionalsSingle.fi',
    component: ProfessionalsSingle,
    meta: {
      lang: 'fi'
    }
  },
  {
    path: '/fi/:slug/online',
    name: 'OnlineProfessionalsSingle.fi',
    component: ProfessionalsSingle,
    meta: {
      lang: 'fi'
    }
  },
  {
    path: '/fi/sivu/contacts',
    name: 'Contacts.fi',
    component: Contacts,
    meta: {
      lang: 'fi',
      title: 'Contacts | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Contact Fine.fit by email or phone. Email: info@fine.fit. Phone: +358 50 341 8002'
        },
        {
          property: 'og:description',
          content: 'Contact Fine.fit by email or phone. Email: info@fine.fit. Phone: +358 50 341 8002'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/fi/sivu/palveluehdot',
    name: 'Terms.fi',
    component: Terms,
    meta: {
      lang: 'fi',
      title: 'Palveluehdot | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Terms of services on Fine.fit'
        },
        {
          property: 'og:description',
          content: 'Terms of services on Fine.fit'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/fi/sivu/yksityisyys',
    name: 'Privacy.fi',
    component: Privacy,
    meta: {
      lang: 'fi',
      title: 'Yksityisyys | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Privacy policy on Fine.fit'
        },
        {
          property: 'og:description',
          content: 'Privacy policy on Fine.fit'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/fi/sivu/asiakas-rekisteröityminen',
    name: 'CustomersLanding.fi',
    component: CustomersLanding,
    meta: {
      lang: 'fi',
      title: 'Valmentajat, liikuntaohjaajat ja hyvinvointialan ammattilaiset | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Voimme auttaa sinua löytämään ammatilaisen. Meillä on homma hallussa!'
        },
        {
          property: 'og:description',
          content: 'Voimme auttaa sinua löytämään ammatilaisen. Meillä on homma hallussa!'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/fi/sivu/ammatilainen-kirjautuminen',
    name: 'ProfessionalsLanding.fi',
    component: ProfessionalsLanding,
    meta: {
      lang: 'fi',
      title: 'Valmentajat, liikuntaohjaajat ja hyvinvointialan ammattilaiset | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'We can help you with customers. We got this!'
        },
        {
          property: 'og:description',
          content: 'We can help you with customers. We got this!'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/fi/sivu/testikäyttäjä',
    name: 'TestersLanding.fi',
    component: TestersLanding,
    meta: {
      title: 'Testikäyttäjä? | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/fi/sivu/pricing',
    name: 'Pricing.fi',
    component: PricingLanding,
    meta: {
      title: 'Pricing | Fine.Fit',
      metaTags: [
        {
          name: 'description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:description',
          content: 'Voimme auttaa sinua asiakkaiden kanssa. Meillä on homma hallussa!'
        },
        {
          property: 'og:image',
          content: 'https://fine.fit/static/finefit_preview.jpg'
        }
      ]
    }
  },
  {
    path: '/fi/sivu/ammattilaiset/:search?/:location?/:service?',
    name: 'ProfessionalsSearch.fi',
    component: Professionals,
    props: true,
    meta: { guest: true, needsAuth: false, lang: 'fi' }
  },
  {
    path: '/fi/sivu/ammattilaiset',
    name: 'Professionals.fi',
    component: Professionals,
    props: true,
    meta: { guest: true, needsAuth: false, lang: 'fi' }
  },
  {
    path: '/fi/sivu/ammattilaiset-online/:search?/:language?/:service?/:method?',
    name: 'OnlineProfessionalsSearch.fi',
    component: OnlineProfessionals,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/fi/sivu/ammattilaiset-online',
    name: 'OnlineProfessionals.fi',
    component: OnlineProfessionals,
    props: true,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: '/fi/sivu/management',
    name: 'Management.fi',
    component: Management,
    meta: {
      lang: 'fi'
    }
  },
  {
    path: '/fi/sivu/tilini',
    name: 'Account.fi',
    component: Account,
    meta: {
      lang: 'fi',
      noScroll: true
    },
    children: [
      {
        path: '/fi/sivu/tilini/locations',
        name: 'AccountLocations.fi',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/fi/sivu/tilini/services',
        name: 'AccountServices.fi',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/fi/sivu/tilini/services-online',
        name: 'AccountServicesOnline.fi',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/fi/sivu/tilini/online-materials',
        name: 'AccountOnlineMaterials.fi',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/fi/sivu/tilini/certificates',
        name: 'AccountCertificates.fi',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/fi/sivu/tilini/social',
        name: 'AccountSocial.fi',
        component: Account,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/fi/sivu/tilini/settings',
        name: 'AccountSettings.fi',
        component: Account,
        meta: {
          noScroll: true
        }
      }
    ]
  },
  {
    path: '/fi/sivu/tilini',
    name: 'SignInSuccess.fi',
    component: Account,
    meta: {
      lang: 'fi'
    }
  },
  {
    path: '/fi/sivu/feed',
    name: 'AccountFeed.fi',
    component: AccountFeed,
    meta: {
      lang: 'fi'
    }
  },
  {
    path: '/fi/sivu/bookings',
    name: 'AccountBookings.fi',
    component: AccountBookings,
    meta: {
      lang: 'fi'
    }
  },
  {
    path: '/fi/sivu/marketing',
    name: 'AccountMarketing.fi',
    component: AccountMarketing,
    meta: {
      lang: 'fi'
    },
    children: [
      {
        path: '/fi/sivu/marketing/create',
        name: 'MarketingCreate.fi',
        component: AccountMarketing,
        meta: {
          noScroll: true
        }
      },
      {
        path: '/fi/sivu/marketing/campaigns',
        name: 'MarketingCampaigns.fi',
        component: AccountMarketing,
        meta: {
          noScroll: true
        }
      }
    ]
  },
  {
    path: '/fi/sivu/sisäänkirjautuminen',
    name: 'SignIn.fi',
    component: SignIn,
    meta: { guest: true, needsAuth: false, lang: 'fi' }
  },
  {
    path: '/fi/sivu/sisäänkirjautuminen/onnistunut',
    name: 'SignIn.Success.fi',
    component: SignIn,
    meta: { refreshToken: true, lang: 'fi' }
  },
  {
    path: '/fi/sivu/rekisteröityminen/:code',
    name: 'SignUp.Redirect.fi',
    component: SignUpRedirect,
    props: true,
    meta: { guest: true, needsAuth: false, lang: 'fi' }
  },
  {
    path: '/fi/sivu/rekisteröityminen',
    name: 'SignUp.fi',
    component: SignUpPage,
    props: true,
    children: SignUpStepsFi,
    meta: { guest: true, needsAuth: false, lang: 'fi' }
  },
  {
    path: '/fi/sivu/muuta-salasana',
    name: 'Forgot.fi',
    component: ForgotPage,
    props: true,
    children: ForgotStepsFi,
    meta: { guest: true, needsAuth: false, lang: 'fi' }
  },
  {
    path: '/fi/sivu/muuta-salasana/:code',
    name: 'Forgot.Redirect.fi',
    component: ForgotRedirect,
    props: true,
    meta: { guest: true, needsAuth: false, lang: 'fi' }
  },
  {
    path: '/fi/sivu/tilini/:code',
    name: 'Deleted.fi',
    component: Deleted,
    props: true,
    meta: {
      lang: 'fi'
    }
  },
  {
    path: '/fi/sivu/social',
    name: 'Social.fi',
    component: SocialRedirect,
    props: true,
    meta: { guest: true, needsAuth: false, lang: 'fi' }
  }
]

let routes = enRoutes.concat(fiRoutes)

let router = new Router({
  base: __dirname,
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.path === from.path && to.query && to.query.page === from.query.page) {
      return false
    } else {
      const position = {}

      // scroll to anchor by returning the selector
      if (to.hash) {
        position.selector = to.hash

        // specify offset of the element
        if (to.hash === '#anchor2') {
          position.offset = { y: 100 }
        }

        // bypass #1number check
        if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
          return position
        }

        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        return false
      }

      if (to.matched.some(m => m.meta.noScroll)) {
        return false
      } else {
        return { x: 0, y: 0 }
      }
    }
  }
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router

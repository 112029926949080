<template>
  <div class="avatar" :style="containerStyle">
    <div v-if="loadedUserImage" class="avatar-image" :style="[avatarStyle, customStyle]">
      <img :src="loadedUserImage" alt="" />
    </div>
    <div v-else class="avatar-generated" :style="[avatarStyle, initialsStyle]">
      <div class="initials">{{ initials }}</div>
      <div class="identicon">
        <!--<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet"><path fill="#4c4c4c" d="M25 15L15 15L15 5ZM25 15L25 5L35 5ZM25 35L35 35L35 45ZM25 35L25 45L15 45ZM15 25L5 25L5 15ZM35 25L35 15L45 15ZM35 25L45 25L45 35ZM15 25L15 35L5 35Z"></path><path fill="#e5e5e5" d="M5 15L5 5L10 5ZM35 5L45 5L45 10ZM45 35L45 45L40 45ZM15 45L5 45L5 40Z"></path><path fill="#cca666" d="M15 15L25 15L25 22L19 19L22 25L15 25ZM35 15L35 25L28 25L31 19L25 22L25 15ZM35 35L25 35L25 28L31 31L28 25L35 25ZM15 35L15 25L22 25L19 31L25 28L25 35Z"></path></svg>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'avatar',
  props: {
    userImage: {
      type: String,
      required: false
    },
    userName: {
      type: String,
      required: false
    },
    size: {
      type: String,
      default: '50px'
    },
    round: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadedUserName: this.userName,
      loadedUserImage: this.userImage,
      customStyle: {},
      backgroundColors: [
        '#F44336', '#FF4081', '#9C27B0', '#673AB7',
        '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688',
        '#4CAF50', '#8BC34A', '#CDDC39', /* '#FFEB3B' , */ '#FFC107',
        '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B']
    }
  },
  computed: {
    identicon() {
//      if (!_.isEmpty(jdenticon)) {
//        if (!_.isEmpty(this.loadedUserName)) {
//          return jdenticon.toSvg(this.loadedUserName, 50)
//        }
//      }
    },
    initials() {
      if (!_.isUndefined(this.loadedUserName) && this.loadedUserName !== '') {
        let name = this.loadedUserName
        let initials = name.match(/\b\w/g)
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()

        return initials
      }
    },
    background() {
      if (!_.isEmpty(this.loadedUserName)) {
        return this.backgroundColor || this.randomBackgroundColor(this.loadedUserName.length, this.backgroundColors)
      }
    },

    fontColor() {
      return this.color || this.lightenColor(this.background, 80)
    },
    Image() {
      return Boolean(this.src)
    },
    containerStyle () {
      let height = this.size
      if (this.size === '100%') {
        height = '100px'
      }
      let style = {
        width: `${this.size}`,
        height: `${height}`
      }
      if (this.round) {
        style.borderRadius = '50%'
      }
      return style
    },
    avatarStyle () {
      let height = this.size
      if (this.size === '100%') {
        height = 'auto'
      }
      const style = {
        width: `${this.size}`,
        height: `${height}`
      }

      const imgBackgroundAndFontStyle = {
        background: `transparent url('${this.src}') no-repeat scroll 0% 0% / ${this.size} ${this.size} content-box border-box`
      }

      const initialBackgroundAndFontStyle = {
        backgroundColor: this.background,
        fontWeight: 'bold',
        display: 'block',
        color: this.fontColor,
        overflow: 'hidden'
      }

      const backgroundAndFontStyle = (this.isImage)
        ? imgBackgroundAndFontStyle
        : initialBackgroundAndFontStyle

      Object.assign(style, backgroundAndFontStyle)

      return style
    },
    initialsStyle () {
      let height = this.size
      let minHeight = this.size
      if (this.size === '100%') {
        height = '100%'
        minHeight = '150px'
      }
      let style = {
        width: `${this.size}`,
        height: `${height}`,
        // minHeight: `${minHeight}`
      }

      if (this.round) {
        style.borderRadius = '50%'
      }

      const imgBackgroundAndFontStyle = {
        background: `transparent url('${this.src}') no-repeat scroll 0% 0% / ${this.size} ${this.size} content-box border-box`
      }

      const initialBackgroundAndFontStyle = {
        backgroundColor: this.background,
        fontWeight: 'bold',
        display: 'block',
        color: this.fontColor,
        overflow: 'hidden'
      }

      const backgroundAndFontStyle = (this.isImage)
        ? imgBackgroundAndFontStyle
        : initialBackgroundAndFontStyle

      Object.assign(style, backgroundAndFontStyle)

      return style
    }
  },
  methods: {
    randomBackgroundColor (seed, colors) {
      return colors[seed % (colors.length)]
    },

    lightenColor (hex, amt) {
      // From https://css-tricks.com/snippets/javascript/lighten-darken-color/
      var usePound = false

      if (hex[0] === '#') {
        hex = hex.slice(1)
        usePound = true
      }

      var num = parseInt(hex, 16)
      var r = (num >> 16) + amt

      if (r > 255) r = 255
      else if (r < 0) r = 0

      var b = ((num >> 8) & 0x00FF) + amt

      if (b > 255) b = 255
      else if (b < 0) b = 0

      var g = (num & 0x0000FF) + amt

      if (g > 255) g = 255
      else if (g < 0) g = 0

      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
    }
  },
  created() {
//    let jdenticon = document.createElement('script')
//    jdenticon.setAttribute('src', 'https://cdn.rawgit.com/dmester/jdenticon/5bbdf8cb/dist/jdenticon.min.js')
//    jdenticon.async = true
//    jdenticon.defer = true
//
//    document.head.appendChild(jdenticon)
  },
  watch: {
    userName: function () {
      this.loadedUserName = this.userName
    },
    userImage: function () {
      this.loadedUserImage = this.userImage
    }
  }
}
</script>

<style lang="scss">
  .avatar {
    position: relative;
    vertical-align: middle;
    overflow: hidden;
    margin: 0 auto;
  }

  .avatar-image {
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .avatar-generated {
    position: relative;
    font-weight: bold;
    vertical-align: middle;
    overflow: hidden;

    .initials {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
    }

    .identicon {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: .2;
      background: url(http://identicon-1132.appspot.com/random) center center no-repeat;
      background-size: 100% auto;
    }
  }
</style>

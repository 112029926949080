export const fi = {
  siteTitle: 'FINE.FIT - Gotta feel fine!',
  homeTitle: 'Löydä sinulle sopivin valmentaja.',
  searchDesc: 'Find {{search}} professionals to help you feel fine and fit.',
  professionalsTitle: 'Palveluntarjoajat',
  specialitiesTitle: 'Erikoistumiset',
  searchTitle: 'Search',
  findOfflineServices: 'Etsi face-to-face - valmennuksia',
  findOnlineServices: 'Hae online-valmennuksia',
  notFound: 'Haullasi ei löydy tuloksia :(',
  welcomeNotice: {
    title: 'Tervetuloa käyttämään Fine.fit:tiä!',
    intro: 'Meidän avullamme löydät helposti itsellesi sopivan valmentajan tai uuden mukavan harrastuksen. <strong>Klikkaa hakuruutua ja lähde tutkimusmatkalle!</strong>',
    cta: 'Ymmärretty!'
  },
  account: {
    title: 'Oma tilini',
    accessDenied: 'Sinun on kirjauduttava sisään käyttääksesi sivua',
    emailUnconfirmed: 'Sähköpostiosoitettasi ei ole vielä vahvistettu, vahvista osoitteesi!',
    emailConfirmed: 'Sähköpostiosoitteesi on vahvistettu!',
    followingsTitle: 'Seuraamani valmentajat',
    followingsDescription: 'Valmentajat joita seuraat',
    followingsEmpty: '<strong>Täällä ei ole vielä mitään.</strong> Seuraa ammattilaisten some-toimintaa täällä!',
    generalTitle: 'General',
    basicInfoTitle: 'Perustiedot',
    offlineTitle: 'Face-to-face',
    onlineTitle: 'Online-palvelut',
    servicesTitle: 'Palvelut',
    servicesOnlineTitle: 'Online-palvelut',
    locationsTitle: 'Valmennuspaikka',
    settingsTitle: 'Asetukset',
    settingsDescription: 'Käyttäjätilin asetukset',
    socialTitle: 'SoMe-tapahtumat',
    socialDescription: 'Boostaa näkyvyyttäsi sosiaalisen median aktiviteeteilla',
    servicesDescription: 'Palveluni',
    professionalCalendarTitle: 'Tapaamiset',
    professionalCalendarDescription: 'Varaamasi tapaukset',
    customerCalendarTitle: 'Varaushistoria',
    customerCalendarDescription: 'Varaushistoriasi',
    onlineMaterialsTitle: 'Online-materiaalit',
    onlineMaterialsDescription: 'Onko sinulla online-palveuidesi esittelyvideoita tai muuta materiaalia?',
    comingSoon: 'Tulossa pian!',
    certificatesTitle: 'Koulutukset, tutkinnot, todistukset',
    certificatesDescription: 'Todistuksia työstä tai koulutuksista? Lataa ne tänne!',
    professionalMarketingTitle: 'Markkinointi',
    professionalMarketingDescription: 'Hanki lisää asiakkaita mainostamalla!',
    searchTitle: 'Etsi',
    signedInTitle: 'Olet sisäänkirjautuneena<br />{{name}}',
    changeRoleTitle: 'Oletko ammattilainen?',
    yourProfileTitle: 'Profiilisi',
    accountTitle: 'Käyttäjätili',
    alerts: {
      uploadPhoto: 'Kuvan lataaminen on suositeltavaa, sillä se tekee profiilistasi vakuuttavamman.',
      maximumServices: 'Olet lisännyt maksimimäärän palveluita',
      unsavedChanges: 'Sinulla on tallentamattomia muutoksia profiilissasi. Muista tallentaa ennen poistumista!'
    },
    deleteAccount: 'Poista käyttäjätilini',
    unpublishAccount: 'Piilota profiilini',
    locations: {
      title: 'Valmennuspaikkasi',
      subTitle: 'Lisää pääasialliset valmennuspaikat',
      limitTitle: 'Maksimi, 5 sijaintia',
      limitReached: 'Olet lisännyt maksimimäärän valmennuspaikkoja.',
      flexibleLabel: 'Onko valmennussijaintisi joustava/sovittavissa?',
      agreeClient: 'Kyllä, valmennuspaikka on sovittavissa',
      minTitle: 'Lisää vähintään yksi sijainti palveluillesi!'
    },
    facebook: {
      posts: {
        loadFrom: 'Mistä haluat meidän lataavan julkaisusi?',
        fbPagesBtn: 'Sivuni Facebookissa',
        fbPrivateBtn: 'Sivuni Facebookissa',
        connectBtn: 'Yhdistä Facebook-tapahtumani käyttäjätiliini',
        disconnectBtn: 'Katkaise FB yhteys tiliini',
        disconnectInfo: 'Ei poista muita profiilisi tietoja, mikäli olet luonut sen Facebookin kautta',
        analyzer: {
          scoreLabel: 'FB-tulos',
          scoreTooltip: 'Kuinka samankaltaisia Facebook-postauksesi ovat, verrattuina ammattilaisprofiiliisi FineFitissä. Mitä parempi tulos, sitä parempi näkyvyytesi on hakupalvelussa. Parantaaksesi tulosta, tee lisää palveuihisi ja ammattiisi liittyviä postauksia.',
          analyzerTitle: 'Analysoin Facebook-postauksiasi',
          completeTitle: 'Analyysi valmis!',
          completeDesc: 'Olemme analysoineet Facebook-postauksesi samankaltaisuudet FineFit-profiiliisi. Tässä on <strong>Facebook-tuloksesi:</strong>',
          completeResult: 'Palvelusi ja palvelukategoriasi mainittiin <strong>{{count}} postauksessa</strong> lähiaikoina.',
          low: {
            intro: '<strong>Jos haluat parantaa hakutulostasi, kokeile kirjoittaa jotain palveluistasi Facebook-postauksissa.</strong>',
            info: 'Facebook-toimintasi näkyy profiilissasi, vaikka analyysituloksesi olisi vielä matala..'
          },
          medium: {
            intro: '<strong>Hyvä, kerrot aktiivisesti palveluistasi Facebookissa!</strong> Aktiivisuus Facebookissa voi nostaa palveuidesi hakutuloksia palvelussamme..',
            info: 'Facebook-toimintosi ovat asiakkaiden nähtävissä myös FineFit-palvelussa.'
          },
          high: {
            intro: '<strong>Mahtavaa!</strong> Näyttää siltä, että kerrot paveluistasi erittäin aktiivisesti Facebookissa! Aktiivisuutesi parantaa hakutuloksiasi.',
            info: 'Facebook-toimintosi ovat asiakkaiden nähtävissä myös FineFit-palvelussa.'
          }
        }
      }
    },
    instagram: {
      photos: {
        connectBtn: 'Lisää kuvia',
        disconnectBtn: 'Disconnect Instagram photos',
        disconnectInfo: 'Your connected Instagram photos will only be removed from Fine.fit, not from your actual Instagram account.',
        refreshBtn: 'Päivitä kuvat',
        source: 'Lähde',
        analyzer: {
          scoreLabel: 'Instagram<br />-tulos',
          scoreTooltip: 'Kuinka samankaltaisia Instagram-julkaisusi ovat, verrattuina ammattilaisprofiiliisi FineFitissä. Mitä parempi tulos, sitä parempi näkyvyytesi on hakupalvelussa. Parantaaksesi tulosta, tee lisää palveluihisi ja ammattiisi liittyviä julkaisuja.',
          analyzerTitle: 'Analysoin Instagram-julkaisujasi',
          completeTitle: 'Analyysi valmis!',
          completeDesc: 'Olemme analysoineet Instagram-julkaisusi samankaltaisuudet FineFit-profiiliisi. Tässä on <strong>Instagram-tuloksesi:</strong>',
          completeResult: 'Palvelusi ja palvelukategoriasi mainittiin <strong>{{count}} julkaisussa</strong> lähiaikoina.',
          low: {
            intro: '<strong>Jos haluat parantaa hakutulostasi, kokeile kirjoittaa jotain palveluistasi Instagram-julkaisuissa.</strong>',
            info: 'Instagram-toimintasi näkyy profiilissasi, vaikka analyysituloksesi olisi vielä matala..'
          },
          medium: {
            intro: '<strong>Hyvä, kerrot aktiivisesti palveluistasi Instagramissa!</strong> Aktiivisuus Instagramissa voi nostaa palveuidesi hakutuloksia palvelussamme..',
            info: 'Instagram-toimintosi ovat asiakkaiden nähtävissä myös FineFit-palvelussa.'
          },
          high: {
            intro: '<strong>Mahtavaa!</strong> Näyttää siltä, että kerrot paveluistasi erittäin aktiivisesti Instagramissa! Aktiivisuutesi parantaa hakutuloksiasi.',
            info: 'Instagram-toimintosi ovat asiakkaiden nähtävissä myös FineFit-palvelussa.'
          }
        }
      }
    },
    specialities: {
      minTitle: 'Lisää vähintään yksi erikoistumisalue!'
    },
    services: {
      fastRegDesc: 'Valitse pääasiallinen palvelusi<br />ja vähintään yksi valmennusmuoto',
      addPricing: 'Lisää hinnoittelu',
      addDescription: 'Lisää palvelun kuvaus',
      addOnline: 'Online training',
      editPricing: 'Muokkaa hinnoittelua',
      editDescription: 'Muokkaa palvelukuvausta',
      editOnline: 'Edit online',
      limitReached: 'Olet lisännyt maksimimäärän palveluita - {{limit}}.<br />poista joitakin palveluita voidaksesi lisätä muita palveluita.',
      minTitle: 'Lisää vähintään yksi palvelu!',
      addedAll: 'Olet jo lisännyt kaikki käytettävissä olevat palvelut.',
      saveSuccess: 'Palvelusi on tallennettu!',
      saveBtn: 'Tallenna palvelut',
      pricing: {
        title: 'Hinnoittelu',
        price: 'Hinta',
        duration: 'Valmennustapaamisen kesto',
        discount: 'Alennus',
        sessions: 'Valmennustapaamiset',
        firstSession: 'Ensimmäisen valmennuskerran alennus?',
        priceDuration: 'Hinta valmennuskerran keston mukaan',
        priceSessions: 'Hinta per valmennuskerta',
        customNotice: 'Mikäli tarjoat muita palvelupaketteja, voit kertoa niistä tässä.',
        packages: 'Valmennuspaketit',
        packageDesc: 'Valmennuspaketin kuvaus'
      },
      description: {
        title: 'Kuvaus',
        placeholder: 'Palvelun kuvaus'
      }
    },
    onlineServices: {
      skipMsg: 'Etkö tarjoa online-palveluita? Ei huolta!',
      skipScrollMsg: 'Etkö tarjoa online-palveluita? <strong>Siirry sivun alareunaan ja ohita.</strong>',
      trainingMethodTitle: 'Tarjoamasi valmennusmetodi?',
      languagesTitle: 'Millä kielillä online-palvelusi ovat saatavilla?',
      copyServicesInfo: 'Kopioidaanko sisältö face-to-face palveluistasi?',
      copyServicesBtn: 'Kyllä, kopoi',
      addWebsite: 'Lisää ulkoinen nettisivu',
      editWebsite: 'Muokkaa ulkoisen nettisivun tietoja',
      langLimitReached: 'Olet lisännyt maksimimäärän sallittuja kieliä - {{limit}}.<br />Mikäli haluat lisätä muita kieliä, poista jokin vanhoista.',
      websitesLimitReached: 'Olet lisännyt maksimimäärän ulkoisia nettisivuja - {{limit}}.<br />Mikäli haluat lisätä muita nettisivuja, poista jokin vanhoista.',
      website: {
        title: 'Online-valmennustesi ulkoiset nettisivut',
        url: 'Nettisivun/palvelun kuvaus',
        urlPlaceholder: 'https://...',
        description: 'Nettisivun kuvaus',
        descPlaceholder: 'Tämän nettisivun/palvelun lyhyt kuvaus.'
      },
      error: 'Tapahtui virhe'
    },
    certificates: {
      limitReached: 'Olet lisännyt maksimimäärän todistuksia - {{limit}}.',
      saveBtn: 'Tallenna todistukset',
      unsaved: 'Sinulla on tallentamattomia muutoksia kohteessa <i>Certificates</i>. Muista tallentaa muutokset!',
      uploadImg: 'Lataa skannattu kopio',
      saveEdit: 'Ok, olen valmis',
      saveSuccess: 'Todistukset tallennettu onnistuneesti!'
    },
    bookings: {
      bookingTitle: 'Varaus',
      bookingFor: 'Booking for <strong>{{name}}</strong>',
      bookingBy: 'Booking by <strong>{{name}}</strong>',
      serviceLabel: 'Service',
      locationLabel: 'Location',
      noteLabel: 'Note',
      acceptBtn: 'Accept',
      cancelBtn: 'Cancel',
      reAcceptBtn: 're-accept',
      declineBtn: 'Decline',
      canceled: 'Canceled',
      confirmed: 'Confirmed',
      pending: 'Pending',
      requestDate: 'Request date',
      suggestDate: 'You suggest booking date to customer.',
      status: {
        confirmed: 'Booking confirmed',
        pending: 'Booking pending',
        canceled: 'Booking canceled',
        declined: 'Booking declined'
      },
      addBooking: '+ Tee ajanvaraus',
      addBookingTitle: 'Tee ajanvaraus',
      moreBookings: 'x{{määrä}} varausta',
      viewAll: 'Katso kaikki',
      view: 'Katso',
      passed: 'Menneet',
      expired: 'Vanhentuneet',
      single: {
        title: 'Varaukset klo {{time}}',
        bookingTitle: 'Otsikko',
        yourNotes: 'Muistiinpanot',
        who: 'Kuka',
        chosenService: 'Valittu palvelu',
        customerExpectations: 'Asiakkaan erityistoiveet',
        professional: 'Ammattilainen',
        yourChosenService: 'Haluamasi palvelu',
        yourExpectations: 'Erityistoiveesi',
        duration: 'Tapaamisen kesto',
        location: 'Sijainti',
        price: 'Hinta?',
        priceNote: 'Näkyy vain sinulle',
        customerLocation: 'Asiakkaan',
        yourLocation: 'Sinun'
      },
      customModal: {
        success: '<strong>Varaus tehty!</strong><br />Voit muokata varausta milloin tahansa.',
          title: 'Otsikko / Asiakkaan nimi',
          date: 'PVM/aika ja kesto',
          otherLocation: 'Muu sijainti',
          notes: 'Muuta',
          notesPlaceholder: 'Omat muistiinpanot'
      },
      revenue: {
        label: 'Arvio kuukauden liikevaihdosta',
          lastMonth: 'viime kuun lopusta alkaen'
      }
    }
  },
  onlineServices: {
    methods: {
      remote: {
        title: 'Etävalmennus',
        icon: 'contactless'
      },
      live: {
        title: 'LIVE-treenit',
        icon: 'live_tv'
      },
      programs: {
        title: 'Valmennusohjelmat',
        icon: 'description'
      }
    },
    languagePlaceholder: 'Valitse kieli...',
    languageTooltip: 'Kirjoita tähän saadaksesi ehdotukset näkyviin',
    scrollBtn: 'Siirry online-palveluihin',
    offerServices: 'Tarjoan online-palveuluita.',
    servicesOnlineTitle: 'Online-palvelut',
    onlineLanguagesTitle: 'Online-palvelukielet',
    onlineLanguagesDesc: 'Online-palvelut ovat saatavissa seuraavilla kielillä:',
    onlineMethodsTitle: 'Online-valmennusmetodit',
    websitesTitle: 'Ulkoiset nettisivut, joista palveluni löytyvät',
    openWebsiteBtn: 'Avaa sivu',
    websiteLinkTitle: 'Avaa {{url}} uudessa ikkunassa',
    searchBtn: 'Etsi valmentajia'
  },
  professional: {
    metaDesc: '{{name}} - valmentaja joka auttaa sinua voimaan paremmin. Tutustu valmentajan palveluihin Fine.fit-sivuilla.',
    verified: 'Verified professional',
    premium: 'Premium professional',
    freeMember: 'Free member',
    gender: {
      male: 'Mies',
      female: 'Nainen'
    },
    currency: {
      eur: 'EUR',
      usd: 'USD',
      gbp: 'GBP',
      aud: 'AUD'
    },
    followBtn: 'Seuraa',
    unfollowBtn: 'Lopeta seuraaminen',
    following: 'Seurattavat',
    sendMsgBtn: 'Lähetä viesti',
    ads: {
      title: '{{name}} suosittelee'
    },
    tabs: {
      main: 'Perustiedot',
      social: 'Sosiaalinen media'
    },
    pricing: {
      pricingOptions: 'Hinnoittelu',
      firstSessionFree: 'Ensimmäinen tapaaminen ilmaiseksi',
      firstSessionDiscount: '{{percent}}% alennus ensimmäisestä valmennustapaamisesta',
      packages: 'Pakettihinnat'
    },
    aboutTitle: 'Valmennuksistani',
    photosTitle: 'Kuvat',
    reviews: {
      title: 'Arvostelut ja referennsit',
      userReviewTitle: 'Luokituksesi ja arvostelusi',
      placeholder: 'Kerro muille kokemuksistasi tämän valmentajan kanssa',
      ownReviews: 'Katselet omia arvostelujasi.',
      accessDenied: 'Sinun tulee kirjautua voidaksesi jättää arvostelun.',
      addBtn: 'Lisää arvostelu',
      cancelBtn: 'Peruuta',
      publishBtn: 'Julkaise arvostelusi',
      publishSuccess: 'Kiitos, arvostelusi on julkaistu!'
    },
    social: {
      instagram: {
        title: 'Instagram-toiminnot',
        modalTitle: 'Instagram-kuvat',
        viewBtn: 'Katsele Instagramissa',
        source: 'Lähde',
        selectPhoto: 'Ole hyvä ja valitse kuva näytettäväksi.'
      },
      facebook: {
        title: 'Facebook-toiminnot',
        originalPost: 'Alkuperäinen julkaisu',
        viewBtn: 'Katsele Facebookissa',
        viewBtnShort: 'Katsele'
      }
    },
    locations: {
      title: 'Sijainti',
      regularPlaces: 'Yleisimmät sijainnit',
      flexible: 'Valmentajan valmennuspaikat ovat neuvoteltavissa!'
    }
  },
  searchPage: {
    viewBtn: 'Katso valmentajaa',
    showMapBtn: 'Näytä valmentajat kartalla',
    hideMapBtn: 'Piilota kartta',
    servicesBtn: 'Katso palvelut',
    emptySearch: 'Etsi valmentajia'
  },
  booking: {
    successMsg: '<strong>Varauspyyntö lähetetty!</strong><br />Ole hyvä ja odota vastausta valmentajalta.',
    placeholders: {
      when: 'Milloin?',
      where: 'Missä?',
      sessionDuration: 'Valmennuskerran kesto?',
      expectations: 'Mitä odotat valmennuspalvelulta?'
    },
    labels: {
      date: 'Kuka valitsee valmennusajan?',
      location: 'Sijainti / valmennuspaikka?',
      expectations: 'Odotuksesi valmennuskerralta'
    },
    dateProfessional: 'Valmentaja voi ehdottaa valmennusaikaa',
    dateUser: 'Minulle sopivin päivä',
    userLocation: 'Minulle sopivin valmennuspaikka',
    durations: {
      '15min': '15 minuuttia',
      '30min': '30 minuuttia',
      '45min': '45 minuuttia',
      '1h': '1 tunti',
      '1h15min': '1 tunti 15 minuuttia',
      '1h30min': '1 tunti 30 minuuttia',
      '1h45min': '1 tunti 45 minuuttia',
      '2h': '2 tuntia',
      '2h15min': '2 tuntia 15 minuuttia',
      '2h30min': '2 tuntia 30 minuuttia',
      '2h45min': '2 tuntia 45 minuuttia',
      '3h': '3 tuntia',
      '4h': '4 tuntia',
      '5h': '5 tuntia',
      '6h': '6 tuntia',
      '7h': '7 tuntia',
      '8h': '8 tuntia'
    }
  },
  footer: {
    phone: 'Puhelin',
    email: 'Sähköposti',
    follow: {
      facebook: 'Seuraa meitä Facebookissa',
      twitter: 'Seuraa meitä Twitterissä',
      linkedin: 'Seuraa meitä Linkedin:ssä',
      instagram: 'Seuraa meitä Instagramissa'
    }
  },
  labels: {
    suggestedCategories: 'Kategoriat',
    suggestedServices: 'Treenimuodot',
    suggestedProfessionals: 'Suositellut valmentajat',
    chooseService: 'Mihin haluat keskittyä?',
    chooseSubService: 'Miten haluat sen tehdä?',
    selectedMainService: 'Valitsemasi treenimuodot'
  },
  placeholders: {
    search: 'Palvelu, erikoistuminen, palveluntarjoajan nimi',
    url: 'URL',
    slug: 'Käyttäjänimi (oman profiilisivusi nimi, your-name)',
    defaultLocation: '',
    language: 'Language',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    name: 'Nimi',
    phone: 'Puhelin',
    password: 'Salasana',
    newPassword: 'Uusi salasana',
    repeatPassword: 'Toista uusi salasana',
    email: 'Sähköposti',
    specialities: 'Erikoistumiset',
    professionalDescription: 'Kerro asiakkaille itsestäsi ja työskentelystäsi',
    professionalLongDescription: 'Pidempi palvelukuvauksesi, sana on vapaa.',
    birth: 'Syntymäaika',
    gender: 'Sukupuoli',
    currency: 'Valuuttasi',
    languages: 'Kielitaitosi',
    chooseService: 'Tarjoamasi palvelut',
    serviceDescription: 'Kerro asiakkaillesi, mitä he voivat odottaa palvelultasi...',
    headerVideo: 'Youtube esittelyvideosi',
    searchInput: 'Vapaa haku',
    location: 'Sijainti'
  },
  preloader: {
    default: 'Lataa...',
    sendingEmail: 'Lähettää varmistusta...',
    sendingForgotEmail: 'Lähettää salasanan uusimisen vahvistusta...',
    searchingResults: 'Lataa tuloksia...',
    searchingPros: 'Etsii sopivia valmentajia...',
    loadingPro: 'Lataa sopivia valmentajia...',
    loadingPros: 'Lataa sopivia valmentajia...',
    instagram: 'Yhdistää Instagramiin...',
    facebookPosts: 'Yhdistää Facebookiin...',
    analyzeFbPosts: 'Analyzing your Facebook posts...',
    analyzeInstaPosts: 'Analysoin Instagram-kuviasi...',
    facebookSignUp: 'Yhdistää Facebookiin...',
    googleSignUp: 'Yhdistää Googleen...'
  },
  signIn: {
    title: 'Kirjautumiseen',
    noAccount: 'Ei vielä käyttäjätiliä?',
    signedIn: 'Olet sisäänkirjautuneena {{name}}',
    forgotLabel: 'Unohditko salasanasi?',
    signUpLabel: 'Ei vielä käyttäjätiliä? Tee se',
    goBack: 'Tai palaa'
  },
  signUp: {
    title: 'Rekisteröityminen',
    checkMail: 'Ole hyvä ja tarkasta sähköpostisi',
    checkMailDescription: 'Olemme lähettäneet sinulle sähköpostin rekisteröinnin loppuunsaattamiseksi.',
    disclaimer: 'Rekisteröityäksesi, lue ja hyväksy <a href="#terms">{{terms}}</a> and <a href="#privacy">{{privacy}}</a>',
    form: {
      buttons: {
        loginNow: 'Log in now',
        send: 'Send',
        go: 'Let\'s go!'
      },
      label: {
        aboutYourself: 'Tell about yourself'
      }
    },
    customerDescription: 'Haluatko löytää palveluita?',
    professionalDescription: 'Tarjoatko palveluita?',
    haveAccount: 'Onko sinulla jo käyttäjätili?',
    userAccount: 'Rekisteröidyn yksityishenkilönä?',
    fitnessUnionPro: 'Olen Suomen Fitnessurheilu ry:n lisenssivalmentaja',
    termsAcceptText: 'Olen lukenut ja hyväksyn <a href="/page/terms" target="_blank" v-b-tooltip.hover title="Sivuston Käyttöehdot">{{terms}}</a> and <a href="/page/privacy" target="_blank" v-b-tooltip.hover title="Yksityisyyden Ja Tietosuojan Ehdot">{{privacy}}</a>',
    notificationsAcceptText: 'Suostun siihen että minulle lähetetään <strong>{{notifications}}</strong>',
    thirdTitle: 'Vahvista käyttäjätilisi rekisteröinti',
    thirdDescription: 'Ole hyvä ja täytä alla olevat tyhjät kentät.',
    customerAccount: 'Palveluita etsivä <strong>asiakas</strong>',
    professionalAccount: 'Palveluita tarjoava <strong>ammattilainen</strong>',
    customerAccountShort: 'Palveluita etsivä <strong>asiakas</strong>',
    professionalAccountShort: 'Palveluita tarjoava <strong>ammattilainen</strong>',
    or: 'tai',
    accountType: 'Olen:',
    socialConfirmation: {
      title: 'En löytänyt järjestelmästämme <strong style="text-transform: capitalize;">{{provider}}-tiliisi</strong> liitettyä käyttäjätiliä',
      description: '<strong>Luodaanko sinulle käyttäjätili?</strong> Klikkaa vain allaolevat kentät:',
      otherSignupWays: 'Haluan rekisteröityä muulla tavalla',
      signUpBtn: 'Kyllä, haluan rekisteröityä käyttäjäksi!'
    }
  },
  reset: {
    checkMail: 'Ole hyvä ja tarkasta sähköpostisi.',
    checkMailDescription: 'Olemme juuri lähettäneet sinulle varmistussähköpostin salasanan uusimiseksi..',
    addNewPassword: 'Lisää uusi salasana.',
    haveAccount: 'Salasanasi?',
    form: {
      buttons: {
        signIn: 'Sign in',
        send: 'Send'
      },
      errors: {
        noMatch: 'Passwords don\'t match'
      },
      placeholder: {
        email: 'Email',
        newPassword: 'New password',
        repeatPassword: 'Repeat password'
      }
    },
    title: 'Salasanan uusiminen'
  },
  deleted: {
    title: 'Käyttäjätilisi on poistettu pysyvästi.'
  },
  legal: {
    privacy: {
      body: 'Yksityisyyden Ja Tietosuojan Ehdot',
      intro: 'Spēkā no 2018. gada 1. marta.',
      title: 'Yksityisyyden Ja Tietosuojan Ehdot'
    },
    terms: {
      body: 'Sivuston Käyttöehdot',
      intro: 'Spēkā no 2018. gada 1. marta.',
      title: 'Sivuston Käyttöehdot'
    },
    notifications: {
      title: 'sähköposti-ilmoitukset'
    }
  },
  titles: {
    headerVideo: 'Liitä tähän Youtube-videosi URL tai ID'
  },
  buttons: {
    back: 'Paluu',
    book: 'Varaa',
    browseProfessionals: 'Selaa ammattilaisia',
    browseServices: 'Selaa palveluita',
    showSuggestions: 'Näytä ehdotuksia',
    clearSearch: 'Tyhjennä',
    clearLocation: 'Tyhjennä',
    openServices: 'Hae palvelun mukaan',
    changePassword: 'Muuta salasanaa?',
    setPassword: 'Aseta salasana',
    signIn: 'Kirjaudu sisään',
    signUp: 'Rekisteröidy, tule mukaan!',
    signOut: 'Kirjaudu ulos',
    go: 'Valmis',
    add: 'Lisää',
    edit: 'Muuta tietoja',
    delete: 'Poista',
    here: 'täällä',
    saveProfilePhoto: 'Tallenna profiilikuva',
    savePhotos: 'Tallenna kuvia',
    saveServices: 'Tallenna palveluita',
    saveBasicInfo: 'Tallenna perustiedot',
    close: 'Sulje',
    forgotPassword: 'Unohditko salasanasi?',
    signInFacebook: 'Kirjaudu käyttäen Facebooki-tiliäsi',
    signInGoogle: 'Kirjaudu käyttäen Google-tiliäsi',
    signInEmail: 'Kirjaudu käyttäen Sähköposti-tiliäsi',
    signUpFacebook: 'Rekisteröidy käyttäen Facebook-tiliäsi',
    signUpGoogle: 'Rekisteröidy käyttäen Google-tiliäsi',
    signUpEmail: 'Rekisteröidy käyttäen sähköpostiosoitettasi',
    send: 'Lähetä',
    submit: 'Lähetä',
    addMore: 'Lisää tietoja',
    addVideo: 'Lisää video',
    removeLast: 'Poista edellinen',
    deleteChange: 'Poista / Muuta',
    no: 'Ei',
    ok: 'Ok!',
    readMore: 'Lue lisää',
    resetPassword: 'Vaihda salasana',
    resendConfirmation: 'Lähetä vahvistus uudelleen',
    previous: 'Edellinen sivu',
    showLess: 'Näytä vähemmän',
    save: 'Tallenna',
    saveLocations: 'Tallenna sijainnit',
    yes: 'Kyllä',
    confirm: 'Vahvista',
    cancel: 'Peruuta',
    upgrade: 'Upgrade',
    manage: 'Manage',
    upgradeToProfessional: 'Muuta valmentaja profiiliksi',
    viewProfile: 'Katso profiilia',
    settings: 'Asetukset',
    editAccount: 'Muokkaa profiilia',
    certificates: 'Todistukset',
    feed: 'Tapahtumat',
    bookings: 'Varaukset',
    marketing: 'Markkinointi',
    messages: 'Viestit',
    viewDetails: 'View details'
  },
  servicesSection: {
    title: 'Tärkeimmät palveluni'
  },
  professionalSection: {
    title: 'Ammattilaisille?',
    subTitle: 'Autamme sinua.',
    description: 'Löytämään asiakkaita ja hoitamaan liiketoimintaasi, ilman sähläystä.',
    cta: 'Lue lisää'
  },
  pages: {
    home: 'Koti',
    contacts: 'Contacts',
    account: 'Tilini',
    professionals: 'Ammattilaisia',
    pricing: 'Pricing',
    termsOfServices: 'Palveluehdot',
    privacyPolicy: 'Yksityisyys',
    management: 'Hallinto'
  },
  copyright: '© 2021 by FineFit.',
  specialities: {
    acrobatics: 'Akrobatia',
    bikiniFitness: 'Bikini fitness',
    bodybuilding: 'Kehonrakennus',
    callisthenics: 'Callisthenics',
    competitionCoaching: 'Kilpailuvalmennus',
    crossfit: 'Crossfit',
    enduranceSports: 'Kestävyyslajit',
    fitness: 'Fitness',
    fodmap: 'Fodmap',
    functionalTraining: 'Toiminnallinen harjoittelu',
    fustra: 'Fustra',
    glutenFree: 'Gluteeniton',
    groupTraining: 'Ryhmäharjoittelu',
    gymnastics: 'Voimistelu',
    gymTraining: 'Kuntosaliharjoittelu',
    handstanding: 'Käsilläseisonta',
    highIntensityIntervalTraining: 'HIIT - High Intensity Interval Training',
    hydrobics: 'Hydrobicci - Vesijumppa',
    hydroRunning: 'Vesijuoksu',
    kettlebell: 'Kahvakuula',
    largeGroup: 'Ryhmäliikunta',
    martialArts: 'Kamppailulajit',
    meditation: 'Meditaatio',
    mentalCoaching: 'Mentaalivalmennus',
    methodMakia: 'Method Makia',
    methodPutkisto: 'Method Putkisto',
    milkFree: 'Maidoton',
    mindfulness: 'Mindfulness',
    movement: 'Movement',
    movementImpaired: 'Liikuntarajoitteisten harjoittelu',
    nutritionPrograms: 'Ravinto-ohjelmat',
    others: 'Muut',
    otherSports: 'Muut lajit',
    paleo: 'Paleo',
    performanceImprovement: 'Suorityskyvyn kehittäminen',
    personalTraining: 'Personal training - henkilökohtainen valmennus',
    physiotherapy: 'Fysioterapia',
    pilates: 'Pilates',
    postPregnancy: 'Raskauden jälkeinen harjoittelu',
    powerLifting: 'Voimanosto',
    pregnancyTimeTraining: 'Raskausajan harjoittelu',
    remoteTraining: 'Etävalmennus',
    running: 'Juoksu',
    seniorCitizens: 'Seniorivalmennus',
    smallGroup: 'Pienryhmä',
    strengthAndMuscle: 'Voiman ja lihasmassan hankinta',
    stressControl: 'Stressinhallinta',
    swimming: 'Uinti',
    therapeutic: 'Terapeuttinen',
    vegetarian: 'Vegetaarinen',
    weightLifting: 'Painonnosto',
    weightLoss: 'Painon pudotus',
    wellness: 'Wellness - terveys ja hyvinvointi',
    yoga: 'Jooga',
    exercises: 'Harjoittelu',
    recuperation: 'Palautuminen',
    generalMentalWellbeing: 'Yleinen henkinen',
    generalPerformance: 'Yleinen fyysinen',
    sexualWellbeing: 'Seksuaalinen',
    sportSpecific: 'Urheilulajikohtainen',
    circuitTraining: 'Kiertoharjoittelu',
    core: 'Core',
    stretching: 'Venyttely',
    recuperative: 'Kehonhuolto',
    danceAerobics: 'Dance aerobics (choreographic)',
    dance: 'Tanssi',
    absLegsBooty: 'RVP - Reisi - Vatsa – Pakara',
    bodyInterval: 'Body interval',
    posteriorConditioning: 'Selkäjumppa',
    waterAerobics: 'Vesijumppa',
    stepAerobics: 'Porrastreenit',
    seniorExercise: 'Seniorit',
    spinning: 'Spinning',
    bodyPump: 'Body pump'
  },
  messenger: {
    messenger: 'Viestit',
    messagePlaceholder: 'Kirjoita viestisi tähän...',
    messageSending: 'Sending...',
    startConversation: 'Kirjoita viestisi ja paina ENTER aloittaaksesi keskustelu <strong>{{name}}</strong> kanssa',
    conversationTitle: 'Keskustelu <strong>{{name}}</strong>',
    sendMessageInfo: '- Paina ENTER lähettääksesi viestin -',
    previousConversations: 'Edelliset keskustelut',
    empty: '- Tyhjä -',
    booking: {
      confirmed: '{{name}} on vahvistanut varauksen!',
      canceled: '{{name}} peruutti varauksen.',
      declined: '{{name}} peruutti varauksen.'
    }
  },
  image: {
    dropLabel: 'Etsi / pudota kuva tähän',
    confirmLabel: 'Vahvista',
    confirmTitle: 'Vahvista',
    cancelLabel: 'Peruuta',
    cancelTitle: 'Peruuta',
    editTitle: 'Muokkaa',
    removeTitle: 'Poista',
    downloadTitle: 'Lataa',
    rotateTitle: 'Käännä',
    uploadTitle: 'Lataa tiedosto',
    tooSmall: 'Kuva on liian pieni'
  },
  fastReg: {
    title: 'Julkaise profiilisi',
    description: 'Profiilisi on melkein valmis julkaistavaksi',
    step0: {
      title: 'Membership plan'
    },
    step1: {
      title: 'Perustiedot',
      finishBtn: 'Valmis! Seuraava, kiitos.'
    },
    step2: {
      title: 'Sijainti',
      finishBtn: 'Valmis! Seuraava, kiitos.'
    },
    step3: {
      title: 'Palveluni',
      finishBtn: 'Valmis! Vielä yksi.'
    },
    step4: {
      title: 'Online-palveluita',
      skipBtn: 'Ohita ja päätä rekisteröinti',
      finishBtn: 'Tallenna ja julkaise!'
    }
  },
  landings: {
    customers: {
      title: 'Voimme auttaa sinua löytämään ammatilaisen.<br />Meillä on homma hallussa!',
      why: {
        title: 'Mikä on Finefit?',
        description: '<p>Fine.fit on valmentajien ja hyvinvointialan ammattilaisten välityspalvelu. Täältä löydät juuri sinun tarpeisiisi sopivat ihmiset haluamaltasi paikkakunnalta ja alueelta. Löydettyäsi oman valmentajasi, voit varata ja maksaa valmennussession, tilata valmennuspaketin tai keskustella valmennuksen mahdollisista yksityiskohdista valmentajan kanssa ennen tilaamista.</p>'
      },
      how: {
        title: 'Kuinka se toimii?',
        options: {
          title: 'Etsiessäsi itsellesi sopivaa valmentajaa, sinulla on kolme vaihtoehtoa',
          option1: 'Voit hakea itsellesi sopivia valmentajia lajien tai treenitavan perusteella',
          option2: 'Voit hakea palveluita vapaan sanahaun tai sijainnin kautta',
          option3: 'Voit selata suoraan valmentajalistaamme',
          option4: 'Lähitulevaisuudessa voit myös jättää meille tarjouspyynnön, ja me ehdotamme sinulle sopivimpia valmentajia.'
        }
      }
    },
    professionals: {
      title: 'Voimme auttaa sinua asiakkaiden kanssa.<br />Meillä on homma hallussa!',
      why: {
        title: 'Miksi Finefit?',
        description: '<p>Fine.fit on valmentajien ja hyvinvointialan ammattilaisten välityspalvelu.</p><p>Missiomme on tuottaa ja välittää työtä liikunta- ja hyvinvointialojen ammattilaisille sekä yksilö-, ryhmäliikunta- että joukkuevalmentajille. Löydämme sinulle asiakkaita, teemme valmennusten ja tapaamisen sopimisesta sinulle mahdollisimman helppoa ja annamme sinulle keskustelukanavan suoraan asiakkaan kanssa.. Hinnoittelet itse omat palvelusi ja päätät itse omista työajoistasi ja -määristäsi.</p>'
      },
      how: {
        title: 'Kuinka se toimii ja mitä saat?',
        options: {
          title: 'Tee itsellesi profiili palveluumme',
          option1: 'Täytä perustietosi ja lisää haluamasi kuva itsestäsi profiiliisi',
          option2: 'Lisää palvelut joita tarjoat ja niiden hinnat / session pituus',
          option3: 'Mikäli kaikki tarjoamasi palvelut eivät löydy listaltamme, ehdota meille palvelusi lisäämistä valikkoomme',
          option4: 'Tutustu palvelumme ehtoihin',
          option5: 'Päätä, haluatko osallistua Fine.fit – tyytyväisyystakuuohjelmaan(Pa3)'
        }
      },
      communication: {
        title: 'Viestintä',
        description: 'Vastaanota yhteydenottoja asiakkailta ja vastaa niihin. Asiakkaan lähettäessä sinulle viestin tai suoran ajanvarauskyselyn, järjestelmämme lähettää sinulle ilmoituksen tästä valitsemaasi sähköpostiin.'
      }
    }
  },
  modals: {
    deleteConfirmation: {
      confirmation: {
        intro: 'Oletko varma, että haluat suorittaa tämän toimenpitään?',
        title: 'Oletko varma?'
      },
      deleted: {
        intro: 'Valitsemasi tieto on poistettu onnistuneesti.',
        title: 'Tiedot poistettu onnistuneesti.'
      }
    },
    deleteProfile: {
      confirmation: {
        intro: 'Oletko varma, että haluat poistaa käyttäjätilisi ja profiilisi?',
        title: 'Oletko varma?'
      },
      deleted: {
        intro: 'Olemme lähettäneet sinulle sähköpostin käyttäjätilisi poiston loppuunsaattamiseksi.',
        title: 'Vahvistussähköposti lähetetty.'
      }
    },
    unpublishProfile: {
      confirmation: {
        intro: 'Oletko varma että haluat piilottaa profiilisi? Piilottamisen jälkeen profiilisi ei näy etsintätuloksissa eikä profiilisi URL-tunniste ole olemassa.',
        title: 'Oletko varma?'
      },
      unpublished: {
        intro: 'Profiilisi on piilotettu!',
        title: 'Valmis!'
      }
    },
    deleteInstagram: {
      confirmation: {
        intro: 'Oletko varma, että haluat <strong>disconnect</strong> Instagram-tilisi?',
        title: 'Oletko varma?'
      },
      deleted: {
        intro: 'Instagram-tilisi on <strong>disconnected</strong> FineFit-käyttäjätilistäsi.',
        title: 'Valmis!'
      }
    },
    deletePosts: {
      confirmation: {
        intro: 'Oletko varma, että haluat <strong>disconnect</strong> Facebook-toiminnoistasi?',
        title: 'Oletko varma?'
      },
      deleted: {
        intro: 'Facebook-toimintosi on nyt <strong>disconnected</strong> FineFit-käyttäjätilistäsi.',
        title: 'Valmis!'
      }
    },
    changeRole: {
      confirmation: {
        intro: 'Oletko varma, että haluat muuttaa käyttäjätilisi ammattilais tiliksi?',
        title: 'Oletko varma?'
      },
      changed: {
        intro: 'Your account was upgraded to professional!',
        title: 'Account upgraded'
      }
    },
    cancelBooking: {
      confirmation: {
        intro: 'Oletko varma että haluat <strong>peruuttaa</strong> tämän varauksen?',
        title: 'Oletko varma?',
        messageTitle: 'Kerro syy peruutukseen.',
        messagePlaceholder: 'Peruutuksen syy'
      },
      canceled: {
        intro: 'Varauksesi on <strong>peruutettu</strong> onnistuneesti!',
        title: 'Tehty'
      }
    },
    deleteBooking: {
      confirmation: {
        intro: 'Oletko varma että haluat <strong>poistaa</strong> tämän varauksen?',
        title: 'Oletko varma?'
      },
      canceled: {
        intro: 'Varauksesi on <strong>peruutettu</strong> onnistuneesti!',
        title: 'Tehty'
      }
    },
    justSignedUp: {
      welcomeTitle: 'Tervetuloa FineFitiin!',
      title: 'Onneksi olkoon, olet juuri julkaissut profiilisi!',
      intro: 'Kiitoksena liittymisestäsi Finefit-valmentajien joukkoon, olemme juuri lähettäneet sähköpostiisi koodin, jolla saat ilmaisen 3 kuukauden kokeilujakson Step2fit-valmennusportaaliin.',
      intro2: 'Olet lisännyt minimimäärän tietoja profiilin julkaisemiseksi.',
      improveTitle: 'Muutama muu hieno juttu, jotka voisit lisätä  <strong>profiiliisi</strong> jotta profiilisi näkyy  <strong>tehokkaammin hakutuloksissa</strong>:',
      connectInstagram: 'Yhdistä <strong>Instagram</strong> tilisi lisätäksesi kuvia, jotka päivittyvät automaattisesti',
      connectFacebook: 'Yhdistä <strong>Facebook</strong> -julkaisusi Fine.fit-tiliisi jotta profiilisi näkyy tehokkaammin hakutuloksissa.',
      addDesc: 'Lisää lyhyt <strong>kuvauksesi, pidempi palvelukuvaus, sukupuoli jne.</strong> tavoittaaksesi useampia asiakkaita.'
    },
    justSignedUpCustomer: {
      welcomeTitle: 'Tervetuloa FineFitiin!',
      title: 'Onnittelut! Valitse mitä haluat tehdä seuraavaksi:',
      findOfflineServices: 'Etsi face-to-face - valmennuksia',
      findOnlineServices: 'Hae<br />online-valmennuksia',
    },
    addCertificate: {
      title: 'Lisää todistuksia',
      certificateId: 'Todistuksen numero / koodi (mikäli olemassa)',
      certificateTitle: 'Koulutuksen / tutkinnon nimi'
    },
    editCertificate: {
      title: 'Muokkaa todistuksia'
    },
    accountBlocked: {
      title: 'Käyttäjätilisi on suljettu.',
      reason: 'Syy: <strong>"{{reason}}"</strong>',
      body: 'Et voi enää tehdä julkaisuja, etkä lähettää viestejä palvelussa.',
      footer: '<strong>Kysymyksiä?</strong> Ota yhteyttä: <a href="mailto:info@fine.fit">info@fine.fit</a>'
    },
    deleteCard: {
      confirmation: {
        intro: '<p>Oletko varma että haluat poistaa maksukorttisi?</p><p><strong>Ammattilaisjäsenyyttäsi ei enää uusita automaattisesti, ennen kuin lisäät uuden maksukortin.</strong></p>',
        title: 'Oletko varma?'
      },
      success: {
        intro: 'Maksukorttisi on poistettu onnistuneesti. <strong>Lisää uusi maksukortti, varmistaaksesi jäsenyytesi jatkumisen.</strong>',
        title: 'Maksukortti poistettu'
      }
    },
    cancelSubscription: {
      confirmation: {
        intro: '<p>Oletko varma että haluat peruuttaa ammattilaisjäsenyytesi?</p><p><strong>Jäsenyyttäsi ei uusita automaattisesti ennenkuin luot uuden ennen kuin luot uuden jäsenyyden.</strong></p><p>Ammattialisjäsenyyden edut eivät ole käytettävissä jäsenyytesi vanhentuessa.</p>',
        title: 'Oletko varma?'
      },
      success: {
        intro: 'Ammattialaisjäsenyytesi on nyt peruutettu onnistuneesti. <strong>Luo uusi jäsenyyt jatkaaksesi ammattilaisjäsenyyden palveluiden käyttöä.</strong>',
        title: 'Ammattilaisjäsenyytesi on peruutettu'
      }
    },
    freeMembership: {
      title: '<strong>Tule perusjäseneksi</strong><br />3 kuukauden ajaksi, <strong>ILMAISEKSI</strong>',
      modalTitle: 'Perusjäseneksi!',
      offer: 'Erikoistarjous - kokeile perusjäsenyyttä ilmaiseksi välittömästi.',
      labels: {
        afterTrial: 'MITEN OLISI ILMAINEN KOKEILUJAKSO? (valitse yksi)',
        chargeNotice: 'Maksukorttiasi EI veloiteta kolmen (3) kuukauden kokeilujakson aikana, voit peruuttaa kokeilusi milloin tahansa!',
        addCard: 'LISÄÄ MAKSUKORTTISI TIEDOT (huijausjäsenyyksien estämiseksi)'
      },
      autoCharge: 'Anna lupa <strong>automaattinen jäsenyyden uudistaminen</strong> kokeilujakson jälkeen',
      autoChargeDesc: 'Kolmen (3) kuukauden kokeilujakson jälkeen, maksukorttiasi <strong>veloitetaan kuukausittain</strong> (19.89 €/kk). Ellet ole tyytyväinen Fine.fit-palveluihin, voit<strong>peruuttaa</strong> jäsenyytesi milloin tahansa profiilisivusi kautta.',
      noCharge: '<strong>Palauta</strong> jäsenyystasosi ilmaisjäsenyyteen kokeilujakson jälkeen',
      noChargeDesc: 'Kolmen (3) kuukauden jälkeen, jäsenyytesi palaa ilmaisjäsenyydeksi,  <strong>eikä</strong> maksukorttiasi veloiteta.',
      notice: 'Kolmen (3) kuukauden jälkeen maksukorttiasi veloitetaan kuukausittain. Mikäli et ole tyytyväinen Fine.fit-palveluihin voit peruuttaa jäsenyytesi milloin tahansa profiilisivusi kautta.',
      success: {
        cta: 'Seuraava vaihe',
        title: '<strong>Onneksi olkoon!</strong> Olet nyt <strong>perusjäsen</strong> !'
      }
    },
    basicMembership: {
      title: 'Lisää maksukorttisi,<br />ja <strong>liity jäseneksi</strong>',
      modalTitle: 'Tule ammattilaisjäseneksi!',
      labels: {
        important: 'TÄRKEÄÄ!',
        chargeNotice: 'Maksukorttiasi <strong>EI</strong> veloiteta <strong>kolmeen (3) kuukauteen</strong>, kokeilujakson aikana, ja voit peruuttaa <strong>milloin tahansa cancel</strong> !',
        addCard: 'LISÄÄ MAKSUKORTTI'
      },
      notice: 'Mikäli kolmen kuukauden kokeilujakson jälkeen olet tyytyväinen Fine.fit-palveluun, maksukorttiasi tullaan veloittamaan kuukausittain. Voit peruuttaa jäsenyytesi milloin tahansa profiilisivusi kautta.',
      success: {
        cta: 'Siirry seuraavaan vaiheeseen',
        title: '<strong>Onneksi olkoon!</strong> Olet nyt <strong>Fine.fit-ammattilaisjäsen</strong> !'
      }
    },
    applyPremium: {
      title: 'Hae Premium-jäsenyyttä',
      explLabel: 'Kuinka sinä voit auttaa?',
      explPlaceholder: 'Kerro vapaasti, kuinka haluaisit työskennellä Fine.fit:n kanssa.',
      success: 'Premium-hakemuksesi on <strong>vastaanotettu</strong> ja käsitellään ensi tilassa!'
    }
  },
  testersPage: {
    ctaBtn: 'Testikäyttäjä?',
    intro: 'HALUAMME KUTSUA SINUT<br />testikäyttäjäksi, tervetuloa!',
    invitationTitle: 'Auta meitä palvelun testaamisessa',
    invitation: '<p>Kutsumme sinut testikäyttäjäksi pian avautuvaan hyvinvointialan ammattilaisten hakupalveluun.</p>',
    rewardTitle: 'Palkitsemme sinut avustasi',
    reward: '<p>Palkkioksi <strong>palautteestasi</strong> tarjoamme sinulle elinikäisen täysjäsenyyden palveluun.</p>',
    signUpTitle: 'Rekisteröidy ja kokeile',
    signUp: '<p>Mene osoitteeseen <a href="https://fine.fit">www.fine.fit</a> ja tee itsellesi ammattilaisprofiili.</p>',
    feedbackTitle: 'Haluamme kuulla mielipiteesi ja ideasi',
    feedback: '<p>Palautetta voit antaa meille palautelomakkeella ja tulemme myös olemaan sinuun suoraan yhteydessä.</p>',
    welcome: '<p><span style="color: #f1a444;">Tervetuloa</span> mukaan!</p>',
    contactsTitle: 'Kysymyksiä / Palautetta?'
  },
  feedback: {
    submit: 'Submit your feedback',
    title: 'Kokemuksesi Fine.fit:sitä',
    description: 'Haluamme kuulla mielipiteesi ja ideasi.',
    question1: 'Miltä käyttö tuntuu?',
    question2: 'Kuinka helppoa profiilin tekeminen oli?',
    question3: 'Minkä yleisarvosanan antaisit Fine.fit:lle?',
    question4: 'Onko ominaisuuksia joita kaipaat?',
    question5: 'Onkin jotain turhia ominaisuuksia?',
    question6: 'Omia ehdotuksia?',
    yourAnswer: 'Kirjoita vastauksesi tähän',
    submitted: '<strong>Kiitos palautteestasi! Kiitoksestasi avustasi sinulla on nyt palvelussamme elinikäinen Premium-jäsenyys.</strong><br />Premium-jäsenen tunnus on nyt näkyvissä myös profiilissasi, ja jäsenyyteesi edut ja lisätoiminnot valmiita käyttöösi pian.'
  },
  currencies: {
    eur: '€',
    usd: '$',
    gbp: '£',
    sek: 'kr',
    yen: '¥'
  },
  marketing: {
    locked: {
      title: 'Lukittu',
      premium: 'Vain <strong>premium-jäsenillä</strong> on mahdollisuus luoda affiliate partner-mainoksia.',
      basic: 'Sinun on oltava <strong>vahvistettu palveluntarjoaja</strong> voidaksesi tehdä mainoksia <strong>profiilisivullasi</strong>.'
    },
    offlineTitle: 'Face-to-face training<br />marketing campaigns',
    onlineTitle: 'Online training<br />marketing campaigns',
    menuNotice: 'Valitse ylläolevasta valikosta.',
    boost: 'Boostaa profiilisi näkyvyyttä',
    create: 'Markkinoinnin vaihtoehdot',
    campaigns: {
      offline: {
        title: 'Face-to-face valmennuskampanjani'
      },
      online: {
        title: 'Online valmennuskampanjani',
        locked: {
          title: 'Lukittu',
          description: 'Lisää ensin <strong>online-palveluita</strong>.',
          cta: 'Lisää online-palveluita'
        }
      }
    },
    campaignsDesc: 'Aktiiviset ja aiemmat kampanjasi',
    placement: {
      search: 'Mainos hakutuloksissa',
      profile: 'Mainos profiilisivullasi'
    },
    paidSearch: {
      offline: {
        title: 'Ole ensimmäinen face-to-face valmennusten hakutuloksissa'
      },
      online: {
        title: 'Ole ensimmäinen online-valmennusten hakutuloksissa'
      },
      title: 'Ensimmäisenä hakutuloksissa',
      notPublished: 'Profiiliasi <strong>ei ole vielä julkaistu</strong>.<br />Voimme boostata vain jo julkaistuja profiileja hakutuloksissa.<br /><strong>Viimeistele profiilisi</strong> ja kokeile uudelleen!',
      adLabel: 'Mainos',
      impressions: 'Näyttökertoja',
      save: 'Tallenna',
      steps: {
        step1: 'Budjetti',
        step2: 'Avainsanat',
        step3: 'Maksu'
      },
      plans: {
        label: 'Valitse haluamasi näkyvyyspaketti',
        empty: 'Valitsemasi paketti ei ole saatavilla',
        pricesNotice: 'Hinnoittelu saattaa vaihdella kysynnän mukaan.',
        chosen: 'Valitse näkyvyyspaketti'
      },
      keywords: {
        notice: 'Maksimissaan 4 avainsanaa per kampanja. Maksimissaan 3 sanaa per aihe. Maksimissaan 30 kirjainta per aihe. Ei avainsanan toistoa.',
        currentlyActive: 'Tällä hetkellä aktiiviset avainsanat ja aiheet.',
        paymentBtn: 'Siirry maksamaan'
      },
      addedSuccess: 'Kampanjasi on nyt tallennettu onnistuneesti!',
      updatedSuccess: 'Kampanjasi on nyt uusittu onnistuneesti!',
      payment: {
        payBtn: 'Maksa ja aloita kampanja',
        payStartBtn: 'Maksa & käynnistä kampanja',
        success: 'Kampanjasi on nyt <strong>käynnissä!</strong><br />Voit seurata kampanjasi tilastoja <strong>"Kampanjat"-sivulla</strong>.'
      },
      details: {
        campaign: 'Kampanja',
        created: 'Luotu',
        keywords: 'Avainsanat',
        price: 'Maksettu',
        impressions: 'näyttökerrat',
        clicks: 'Mainoksen klikkaukset',
        status: 'Status',
        active: 'Aktiivinen',
        ended: 'Päättynyt',
        paused: 'Keskeytetty',
        unpaid: 'Ei maksettu',
        resumeBtn: 'Jatka',
        pauseBtn: 'Keskeytä',
        empty: 'Et ole vielä käynnistänyt yhtään kampanjaa',
        createBtn: 'Aloita kampanja'
      }
    },
    ads: {
      offline: {
        title: 'Facpalveluiden mainokset',
        customTitle: 'Kustomoidut face-to-face palveluiden mainokset',
        affiliatesTitle: 'Affiliate partner-mainokset face-to-face palveluissa'
      },
      online: {
        title: 'Online-palveluiden mainokset',
        customTitle: 'Kustomoidut online-palveluiden mainokset',
        affiliatesTitle: 'Affiliate partner-mainokset face-to-face palveluissa'
      },
      title: 'Mainokset',
      customTitle: 'Kustomoitu mainonta',
      affiliatesTitle: 'Yhteistyö- ja provisiomainonta',
      adLabel: 'Mainos',
      impressions: 'Näyttökerrat',
      save: 'Tallenna muutokset',
      savedLabel: 'Muutokset tallennettu',
      unsavedLabel: 'Muutoksia ei tallennettu',
      unpaidLabel: 'Not paid',
      publishProfileBtn: 'Tallenna ja julkaise mainos profiilissasi',
      steps: {
        step1: 'Budjetti',
        step2: 'Mainoksen sisältö',
        step3: 'Avainsanat',
        step4: 'Maksu',
        step5: 'Valmis'
      },
      affiliates: {
        label: 'Valitse yhteistyökumppani',
        discountCodeLabel: 'Alennuskoodisi',
        chosen: 'Valittu yhteistyökumppani',
        bodyclub: {
          adTitle: 'Jopa 20% alennus!',
          adDescription: 'Käytä koodiani [code], ja saat jopa 20% alennusta Bodyclub.fi-nettikaupassa!',
          adUrl: 'http://www.bodyclub.fi',
          adImage: '',
          ctaLabel: 'Katso tuotteita'
        }
      },
      placement: {
        label: 'Mainoksen sijoittelu',
        profile: 'Näytä <strong>profiilisivulla</strong>',
        search: 'Näytä <strong>hakutuloksissa</strong>',
        chosen: 'Valittu sijoittelu',
        multiple: 'Voit valita useita'
      },
      plans: {
        label: 'Aseta budjetti',
        empty: 'Mainoskampanja ei ole tällä hetkellä valittavissa.',
        pricesNotice: 'Avainsanojen hinnoittelu voi vaihdella kysynnän mukaan.',
        chosen: 'Valittu kampanja.',
        profilePrice: 'ILMAINEN',
        searchPrice: 'ASETA BUDJETTI',
        profileExistingNotice: '<strong>Profiilisivullasi on jo aktiivinen mainoskampanja.</strong><br />Profiilisivullasi voi olla vain yksi aktiivinen mainoskampanja kerrallaan. Haluatko keskeyttää meneillään olevan kampanjan ja aloittaa uuden?',
        stopActiveBtn: 'Kyllä, keskeytä meneillään oleva mainoskampanja',
        contentBtn: 'Jatka mainoksesi sisältöön'
      },
      content: {
        label: 'Lisää mainoksen sisältö',
        adTitle: 'Lisää otsikko',
        adDescription: 'Lisää teksti',
        adCta: 'Call to action-painikkeen teksti',
        adUrl: 'External URL',
        adPreview: 'Mainoksen esikatselu',
        notice: 'Maksimissaan neljä (4) avainsanaa per mainoskampanja. Maksimissaan kolme (3 sanaa per avainsana. Maksimissaan 30 kirjainta per avainsana. Älä toista avainsanaa.',
        currentlyActive: 'Tällä hetkellä aktiiviset avainsanat',
        keywordsBtn: 'Jatka haluamiisi avainsanoihin',
        editBtn: 'Muokkaa mainoksen sisältöä',
        profileFinishBtn: 'Tallenna ja julkaise mainos profiilissasi',
        finishBtn: 'Tallenna ja julkaise'
      },
      keywords: {
        notice: 'Max 4 keywords per each campaign. Max 3 words per each keyword. Max 30 characters per keyword. No duplicates.',
        currentlyActive: 'Currently active keywords',
        paymentBtn: 'Continue to payment'
      },
      addedSuccess: 'Your campaign was saved successfully!',
      updatedSuccess: 'Your campaign was updated successfully!',
      payment: {
        payBtn: 'Pay',
        payStartBtn: 'Maksa ja aloita mainoskampanja',
        success: '<strong>Onnistuit!</strong> Mainoskampanjasi on nyt käynnissä!<br />Voit katsella kampanjasi tilastoja alavisulla <strong>"Omat kampanjani"</strong>.'
      },
      details: {
        campaign: 'Mainoskampanja',
        created: 'Luotu',
        keywords: 'Avainsanat',
        price: 'Maksettu',
        impressions: 'näyttökerrat',
        clicks: 'Mainosklikkaukset',
        status: 'Status',
        active: 'Aktiivinen',
        ended: 'Päättynyt',
        paused: 'Keskeytetty',
        resumeBtn: 'Jatka kampanjaa',
        pauseBtn: 'Keskeytä',
        empty: 'Et ole vielä luonut yhtään kampanjaa',
        createBtn: 'Luo mainoskampanja',
        profileExistingNotice: '<strong>Profiilisivullasi on jo aktiivinen mainoskampanja.</strong><br />Profiilisivullasi voi olla vain yksi aktiivinen mainoskampanja kerrallaan. Haluatko keskeyttää meneillään olevan kampanjan ja käynnistää tämän?',
      }
    }
  },
  checkout: {
    payByExisting: 'Maksa nykyisellä kortilla',
    addCard: 'Lisää maksukortti',
    notice: 'Fine.fit ei tallenna maksukorttisi tietoja. Maksukorttisi tiedot tallennetaan Stripe-maksujärjestelmään.'
  },
  membership: {
    title: 'Jäsenyys',
    label: 'Jäsenyystaso',
    upgradeServicesPricing: '<strong>Ilmaisjäsenenä</strong> palveluidesi hinnoittelu ei näy asiakkaille. <strong>Korota jäsenyystasoasi</strong> jotta saat tämän ja monia muita palveluita käyttöösi!',
    upgradeServicesDescription: '<strong>Ilmaisjäsenenä</strong> palveluidesi kuvaukset eivät näy asiakkaille, mutta ovat heidän haettavissaan. <strong>Korota jäsenyystasoasi</strong> jotta saat tämän ja monia muita palveluita käyttöösi!',
    upgradeAds: '<strong>Ilmaisjäsenenä</strong> mainoksesi eivät näy profiilissasi asiakkaille. <strong>Korota jäsenyystasoasi</strong> jotta saat tämän ja monia muita palveluita käyttöösi!',
    upgradePricing: '<strong>Ilmaisjäsenenä</strong> palveluidesi hinnoittelu ei näy asiakkaillesi. <strong>Korota jäsenyystasoasi</strong> jotta saat tämän ja monia muita palveluita käyttöösi!',
    upgradeSocialActivities: '<strong>Ilmaisjäsenenä</strong>, toimintasi sosiaalisessa mediassa ei näy asiakkaillesi, mutta tulevat esiin hakutuloksissa. <strong>Korota jäsenyystasoasi</strong> jotta saat tämän ja monia muita palveuita käyttöösi!',
    free: 'Ilmaisjäsenyys',
    basic: 'Perusjäsenyys',
    premium: 'Premium-jäsenyys',
    1: 'Ilmaisjäsenyys',
    2: 'Perusjäsenyys',
    3: 'Premium-jäsenyys',
    paymentType: {
      free: 'Ilmainen kokeilujakso',
      charge_automatically: 'Jäsenyyden automaattinen uusiminen'
    },
    currentMembership: 'Tämänhetkinen jäsenyys',
    pricePaid: 'Maksettu',
    membershipExpires: 'Jäsenyys päättyy',
    paymentInfo: 'Maksujen tiedot',
    daysLeft: '{{days}} päiviä jäljellä',
    renewalCanceled: 'Jäsenyytesi automaattinen uusiminen on peruutettu.',
    renewalNotice: '<strong>Lisää uusi kortti tai valitse olemassa oleva maksukortti pääasialliseksi maksutavaksi</strong>, kuukausittaisen jäsenmaksun maksamiseksi, ja jatka vahvistettujen ammattilaisten palveluiden käyttöä.',
    paidPeriod: 'Maksettu',
    paymentTypeLabel: 'Maksutyyppi',
    cardPrimaryNotice: 'Et ole asettanut mitään maksukorttia pääasialliseksi maksutavaksi jäsenyyden automaattiseksi uudistamiseksi. Valitse jokin kortti listasta tai lisää uusi kortti.',
    cardPrimaryMissing: 'Et ole lisännyt yhtään maksukorttia maksutavaksi jäsenyyden automaattiseksi uusimiseksi.',
    addedCards: 'Lisäämäsi maksukortit',
    cardExp: 'Voimassa',
    primaryCard: 'PÄÄASIALLINEN MAKSUKORTTI',
    setPrimary: 'Aseta pääasialliseksi ',
    upgrade: {
      title: 'Jäsentason korotus',
      intro: 'lisää näkyvyyttäsi hakutuloksissa, ja ota käyttöön perustason jäsenten palvelut!'
    }
  },
  pricingPage: {
    title: 'Valitse jäsenyystaso',
    month: 'kuukausi',
    upgraded: 'Olet jo korottanut jäsenyystasoasi',
    existing: 'Sinulla on jo tämä jäsenyystaso!',
    free: {
      title: 'Ilmainen',
      cta: 'Ota käyttöön ilmainen jäsenyys'
    },
    basic: {
      title: 'Perustaso',
      cta: 'Kokeile 3 kuukautta ilmaiseksi'
    },
    premium: {
      title: 'Premium',
      cta: 'Hae premium-tasoa',
      description: '<p><strong>Premium-jäsenet ovat rajallinen ryhmä jäseniä jotka auttavat Fine.fit-palvelua kasvamaan (testaamalla kehitteillä olevia palveluita ja markkinoimalla palveluamme).</strong></p><p>Voit hakea premium-jäsenyyttä lähettämällä hakemuksen ja kertomalla, kuina voit auttaa Fine.fit:a. Jokainen hakemus käsitellään manuaalisesti.</p>',
      locked: {
        title: 'Lukittu',
        description: 'Sinun on oltava vähintään <strong>ilmaisjäsen</strong> voidaksesi hakea premium-jäsenyyttä.'
      }
    },
    features: {
      feature1: '<span>Julkinen</span> profiili',
      feature2: '<span>Lisää</span> sijainteja',
      feature3: '<span>Lisää</span> face-to-face palveluita',
      feature4: '<span>Lisää</span> online-palveluita',
      feature5: '<span>Lisää</span> perustietosi',
      feature6: '<span>Käytä</span> viestipalvelua',
      feature7: '<span>Näytä</span> perustason jäsen-badge',
      feature8: '<span>Näytä</span> hinnoittelu',
      feature9: '<span>Näytä</span> yksityiskohtaiset kuvaukset',
      feature10: '<span>Näytä</span> sosiaalisen median toimintasi',
      feature11: '<span>Käytä</span> varaus- ja liikevaihtokalenteria',
      feature12: '<span>Lisää</span> kustomoituja mainoksia profiiliisi',
      feature13: '<span>Boostaa</span> profiilisi näkyvyyttä hakutuloksissa*',
      feature14: '<span>Boostaa</span> mainoksiasi hakutuloksissa*',
      feature15: '<span>Ota käyttöön</span> tähtipisteytys ja arvostelut',
      feature16: '<span>Näytä</span> premiumjäsen-badge',
      feature17: '<span>Ammattilaisesittely</span>',
      feature18: '<span>Korkeammalla</span> hakutuloksissa',
      feature19: '<span>Lisää</span> affiliate-mainoksia profiiliisi ads on profile',
      feature20: '<span>Boostaa</span> affiliate-mainoksia hakutuloksissa*'
    },
    footnote: '* Erillinen maksu mainosten boostaamisesta profiilissasi ja/tai hakutuloksissa.'
  },
  services: {
    acrobatics: 'Akrobatia',
    bikiniFitness: 'Bikini fitness',
    bodybuilding: 'Kehonrakennus',
    callisthenics: 'Callisthenics',
    competitionCoaching: 'Kilpailuvalmennus',
    crossfit: 'Crossfit',
    enduranceSports: 'Kestävyyslajit',
    fitness: 'Fitness',
    fodmap: 'Fodmap',
    functionalTraining: 'Toiminnallinen harjoittelu',
    fustra: 'Fustra',
    glutenFree: 'Gluteeniton',
    groupTraining: 'Ryhmäharjoittelu',
    gymnastics: 'Voimistelu',
    gymTraining: 'Kuntosaliharjoittelu',
    handstanding: 'Käsilläseisonta',
    highIntensityIntervalTraining: 'HIIT - High Intensity Interval Training',
    hydrobics: 'Hydrobicci - Vesijumppa',
    hydroRunning: 'Vesijuoksu',
    kettlebell: 'Kahvakuula',
    largeGroup: 'Ryhmäliikunta',
    martialArts: 'Kamppailulajit',
    meditation: 'Meditaatio',
    mentalCoaching: 'Mentaalivalmennus',
    methodMakia: 'Method Makia',
    methodPutkisto: 'Method Putkisto',
    milkFree: 'Maidoton',
    mindfulness: 'Mindfulness',
    movement: 'Movement',
    movementImpaired: 'Liikuntarajoitteisten harjoittelu',
    nutritionPrograms: 'Ravinto-ohjelmat',
    others: 'Muut',
    otherSports: 'Muut lajit',
    paleo: 'Paleo',
    performanceImprovement: 'Suorityskyvyn kehittäminen',
    personalTraining: 'Personal training - henkilökohtainen valmennus',
    physiotherapy: 'Fysioterapia',
    pilates: 'Pilates',
    postPregnancy: 'Raskauden jälkeinen harjoittelu',
    powerLifting: 'Voimanosto',
    pregnancyTimeTraining: 'Raskausajan harjoittelu',
    remoteTraining: 'Etävalmennus',
    running: 'Juoksu',
    seniorCitizens: 'Seniorivalmennus',
    smallGroup: 'Pienryhmä',
    strengthAndMuscle: 'Voiman ja lihasmassan hankinta',
    stressControl: 'Stressinhallinta',
    swimming: 'Uinti',
    therapeutic: 'Terapeuttinen',
    vegetarian: 'Vegetaarinen',
    weightLifting: 'Painonnosto',
    weightLoss: 'Painon pudotus',
    wellness: 'Wellness - terveys ja hyvinvointi',
    yoga: 'Jooga',
    exercises: 'Harjoittelu',
    recuperation: 'Palautuminen',
    generalMentalWellbeing: 'Yleinen henkinen',
    generalPerformance: 'Yleinen fyysinen',
    sexualWellbeing: 'Seksuaalinen',
    sportSpecific: 'Urheilulajikohtainen'
  },
  notifications: {
    new_review: {
      title: '{name} has reviewed you!',
      name: 'Received a new review',
      body: '{name} has left a review on your profile: \"{review}\"'
    },
    new_message: {
      title: '{name} sent you a message',
      name: 'Received a new message',
      body: '{name}: "{message}"'
    },
    new_booking: {
      title: '{name} wants to book a session!',
      name: 'Received a new booking request',
      body: '{name} wants to book your session on "{date}", special requests: "{message}"'
    },
    new_follower: {
      title: '{name} started following you!',
      name: 'New follower',
      body: '{name} started following you'
    }
  },
  errors: {
    400: 'Bad Request',
    404: 'Not Found',
    500: 'Internal Server Error',
    1001: 'Employee already registered.',
    1002: 'Unauthorized',
    1003: 'Current offer option is sold out!',
    1004: 'Deleted must be boolean!',
    1005: 'Enable must be boolean!',
    1006: 'Cannot self add to employee.',
    1007: 'Daily limit, maximum 10 emails per day!',
    1008: 'Data can be set once per day',
    1009: 'The Date from is not a valid date format.',
    1010: 'The Date to is not a valid date format.',
    1011: 'Category Not Exists!',
    1012: 'Not found into database.',
    1013: 'Offer option does not exists!',
    1014: 'Password reset link is broken!',
    1015: 'Is not current Company offer!',
    1016: 'Db error.',
    1017: 'Type Not Exists!',
    1018: 'The Email format is invalid.',
    1019: 'The Offer id must be an integer.',
    1020: 'The Offer option id must be an integer.',
    1021: 'Failed to refresh token',
    1022: 'Failed to revoke token',
    1023: 'Invalid image extension, allowed (jpg, jpeg, gif, png)',
    1024: 'Maximum image size 1 Mb.',
    1025: 'Invalid email or password!',
    1026: 'Failed to get id from iWOWNFit',
    1027: 'Offer option limit reached by user.',
    1028: 'Failed to send email',
    1029: 'The Comment may not be greater than :max characters.',
    1030: 'The Name may not be greater than :max characters.',
    1031: 'No access level.',
    1032: 'The Target must be a number.',
    1033: 'Current Password must match.',
    1034: 'Balance Points not enough.',
    1035: 'Invalid Target type id.',
    1036: 'Available must be positive integer!',
    1037: 'Invalid Company id.',
    1038: 'Invalid Count id.',
    1039: 'Invalid id.',
    1040: 'Password reset link is broken!',
    1041: 'Invalid Period id.',
    1042: 'Invalid Phone nr. ex:(+37136363634, 0037121237979)',
    1043: 'Points must be positive integer!',
    1044: 'Invalid Price id.',
    1045: 'Invalid id',
    1046: 'Invalid Target type id.',
    1047: 'Failed to request outside api',
    1048: 'The Active field is required.',
    1049: 'The Available field is required.',
    1050: 'Available Points is required.',
    1051: 'The Comment field is required.',
    1052: 'The Company id field is required.',
    1053: 'The Count field is required.',
    1054: 'The Date from field is required.',
    1055: 'The Date to field is required.',
    1056: 'The Deleted field is required.',
    1057: 'The Description field is required.',
    1058: 'The Devices field is required.',
    1059: 'The Email field is required.',
    1060: 'The First name field is required.',
    1061: 'Type id is required.',
    1062: 'Name is required.',
    1063: 'The Offer id field is required.',
    1064: 'The Offer option id field is required.',
    1065: 'The Password field is required.',
    1066: 'The New Password field is required.',
    1067: 'The Password repeat field is required.',
    1068: 'Link required.',
    1069: 'The Period type field is required.',
    1070: 'The Phone field is required.',
    1071: 'The Phone Code field is required.',
    1072: 'The Phone Number field is required.',
    1073: 'Points is required.',
    1074: 'The Position field is required.',
    1075: 'The Price field is required.',
    1076: 'The Role id field is required.',
    1077: 'The address field is required.',
    1078: 'The Target field is required.',
    1079: 'The Target type field is required.',
    1080: 'Title is required.',
    1081: 'Url is required.',
    1082: 'The Values field is required.',
    1083: 'Password must match.',
    1084: 'The Description field is required.',
    1085: 'Token expired.',
    1086: 'Unknown error.',
    1087: 'Email already in use.',
    1088: 'Broken registration link.',
    1089: 'Cannot Create Token.',
    1090: 'Failed to set token.',
    1091: 'Token invalid.',
    1092: 'The Phone nr must be an integer.',
    1093: 'The Phone code must be an string.',
    1094: 'Company Not Exists!',
    1095: 'Company reg. Nr. is required.',
    1096: 'Street is required.',
    1097: 'City is required.',
    1098: 'Street Nr. is required.',
    1099: 'Postal code is required.',
    1100: 'Country is required.',
    1101: 'Region is required.',
    1102: 'Bank name is required.',
    1103: 'Account Nr. is required.',
    1104: 'Swift is required.',
    1105: 'This address is already taken.',
    1106: 'User already has Company.',
    1107: 'Provider id is required.',
    1108: 'Provider not exists.',
    1109: 'Not Company Employee.',
    1110: 'Employee already registered.',
    1111: 'Integration not found.',
    1112: 'Options title must be an string.',
    1113: 'The option points must be an integer.',
    1114: 'The option available count must be an integer.',
    1115: 'The option limit by user must be an integer.',
    1116: 'API client is not authorized by the resource owner to access the resource requested.',
    1117: 'Missing params.',
    1118: 'API client is not authorized by the resource owner to access the resource requested.',
    1119: 'Challenge is not active.',
    1120: 'User has already applied to this challenge.',
    1121: 'User has already completed this day.',
    1122: 'User has not applied to this challenge.',
    1123: 'Image is required to complete this challenge.',
    1124: 'Challenge has not started yet.',
    1125: 'Day index cannot be zero.',
    1126: 'Challenge apply deadline has passed.',
    1127: 'Challenge day has not started yet.',
    1128: 'Day index cannot be bigger than overall days count.',
    1129: 'The achievement ID is required.',
    1150: 'The achievement ID must be an integer.',
    1151: 'Please, do not spam.',
    1152: 'Login failed.',
    1153: 'Unable to add image.',
    1154: 'Short description field is required.',
    1161: 'Email notifications field is required.',
    1162: 'The email notifications must be an boolean.',
    1163: 'The challenge ID is required.',
    1164: 'The challenge ID must be an integer.',
    1165: 'The terms agree is required.',
    1166: 'The terms agree must be an boolean.',
    1167: 'Registered via Facebook or Google, and password not set.',
    1170: 'Email not provided. Please register by using your email.',
    1171: 'Image does not exist.',
    1172: 'Day is required.',
    1173: 'The day must be an integer.',
    1174: 'Your image must be shared on Facebook.',
    1175: 'Social shared must be an integer.',
    1176: 'Image can be changed only within 1 hour.',
    1177: 'Challenge does not exists!',
    1178: 'Some of partner(s) not exists.',
    1179: 'Partner id is required',
    1180: 'Partner id must be an integer',
    1181: 'This is not your Company challenge!',
    1182: 'Partner does not exist',
    1183: 'This partner does not belong to current company',
    1184: 'Prize id is required',
    1185: 'Prize id must be an integer',
    1186: 'Prize does not exist',
    1187: 'This prize does not belong to current company',
    1188: 'Partners field is required',
    1189: 'Partners must be an array[]',
    1190: 'Quantity is required',
    1191: 'Quantity must be an integer',
    1192: 'Order nr is required',
    1193: 'Order nr must be an integer',
    1194: 'Prizes order nr is required',
    1195: 'Prizes order must be an array',
    1196: 'Invalid password',
    1197: 'Winners count is required',
    1198: 'Winners count must be an integer',
    1199: 'Winners count must be greater than 0',
    1200: 'Min points is required',
    1201: 'Min points must be an integer',
    1202: 'Win quantity is required',
    1203: 'Win quantity must be an integer',
    1204: 'Win quantity must be greater than 0',
    1205: '"Can win again?" is required',
    1206: '"Can win again?" must be boolean',
    1207: 'Winners quantity must be the same as winners count',
    1208: 'Prizes available quantity is less than winners',
    1209: 'You have unfinished lottery',
    1210: 'Lottery id is required',
    1211: 'Lottery id must be an integer',
    1212: 'Accepted winners is required',
    1213: 'Accepted winners must be an array',
    1214: 'Declined winners is required',
    1215: 'Declined winners must be an array',
    1216: 'Invalid lottery',
    1217: 'Invalid qr code(s)',
    1218: 'Declined winners must be less than actual winners',
    1219: 'Employees csv is required',
    1220: 'Maximum {{}} New Employees Allowed.',
    1221: 'Employee email: \'{{}}\' already registered',
    1222: 'Employee first name is empty, line: {{}}',
    1223: 'Employee last name is empty, line: {{}}',
    1224: 'Employee email is empty, line: {{}}',
    1225: 'You have added maximum count of employees.',
    1226: 'Plan not found for current company.',
    1227: 'Current plan does not allow to add more challenges.',
    1228: 'Plan allow maximum {{}} challenge day(s)',
    1229: 'Parent is required',
    1230: 'Parent must be an integer',
    1231: 'Parent must be an positive integer',
    1232: 'Team parent not found',
    1233: 'Company team not found',
    1234: 'Teams order required',
    1235: 'Teams order must be an array',
    1236: 'Teams id is required',
    1237: 'Teams id must be an integer',
    1238: 'Employees is required',
    1239: 'Employees must be an array',
    1240: 'Employees can be add only on last level',
    1241: 'Duplicate email in CSV file',
    1242: 'Team cannot be changed after 24 hours.',
    1243: 'Only company employee can choose a team.',
    1244: 'Not allowed to delete team(s) with added emplooyees.',
    1245: 'Enabled field is required.',
    1246: 'Invalid company password.',
    1248: 'Your slug contains invalid characters. Only latin letters (a-z), numbers (0-9), and hyphen (-) are allowed.',
    1249: 'This slug is taken.',
    1250: 'Your slug is too long. It exceeds the maximum length of 20 characters.',
    1251: 'The message must be at least 10 characters.',
    1252: 'The message field is required.',
    1253: 'Invalid rating.',
    1254: 'Rating is required',
    1255: 'Location is required',
    1256: 'Service is required',
    1257: 'Date and time is required',
    1258: 'Duplicate keyword detected. Keyword <strong><i>{{var}}</i></strong> exists in your other currently active campaign.',
    1259: 'Keyword must contain not more than <strong>{{var}} words</strong>.',
    1260: 'Keyword must not contain <strong>duplicate words</strong>.',
    1261: 'Keyword must contain not more than <strong>{{var}} words</strong>.',
    1262: 'Keyword must not contain <strong>duplicate words</strong>.',
    1263: '<strong>Your email is not confirmed yet.</strong><br />Go to your inbox and find email from Fine.fit to confirm your email address.',
    2001: 'Data not available yet.',
    2002: 'You have no active targets at the moment, but you can create new.',
    2003: 'You have not made any transaction yet.',
    2004: 'You have to read and agree to Terms & Conditions and Privacy Policy.',
    2005: 'You have to read and agree to Privacy Policy.',
    2006: 'Authorization failed. Try again later.',
    2007: 'Could not authorize - token was empty.'
  }
}

import SignupStep1 from '../components/step1-signup'
import SignupStep2 from '../components/step2-email'
import SignupStep3 from '../components/step3-signup'

export const SignUpStepsEn = [
  {
    path: 'step/1',
    name: 'SignUp.Step1.em',
    component: SignupStep1,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'step/2',
    name: 'SignUp.Step2.en',
    component: SignupStep2,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'step/3',
    name: 'SignUp.Step3.en',
    component: SignupStep3,
    meta: { guest: true, needsAuth: false }
  }
]

export const SignUpStepsFi = [
  {
    path: 'askel/1',
    name: 'SignUp.Step1.fi',
    component: SignupStep1,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'askel/2',
    name: 'SignUp.Step2.fi',
    component: SignupStep2,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'askel/3',
    name: 'SignUp.Step3.fi',
    component: SignupStep3,
    meta: { guest: true, needsAuth: false }
  }
]

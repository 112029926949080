export default {
  professional: {},
  professionalOfflineAds: [],
  professionalOnlineAds: [],
  searchResults: [],
  searchNamesResults: [],
  searchResultsBoosted: [],
  searchResultsAds: {},
  isSearchLoading: false,
  onlineSearchResults: [],
  onlineSearchNamesResults: [],
  onlineSearchResultsBoosted: [],
  onlineSearchResultsAds: {}
}

export const authenticated = (state) => {
  return state.user.authenticated
}

export const user = (state) => {
  const data = state.user.data
  return data
}

export const services = (state) => {
  return state.services
}

export const servicesArr = (state) => {
  return state.services
}

export const specialities = (state) => {
  return state.specialities
}

export const userServices = (state) => {
  return state.userServices
}

export const userOnlineServices = (state) => {
  return state.userOnlineServices
}

export const userSpecialities = (state) => {
  return state.userSpecialities
}

export const userLocations = (state) => {
  return state.userLocations
}

export const userCertificates = (state) => {
  return state.userCertificates
}

export const userBookings = (state) => {
  return state.userBookings
}

export const customerBookings = (state) => {
  return state.customerBookings
}

export const userPhotos = (state) => {
  return state.userPhotos
}

export const userAnalyzePhotos = (state) => {
  return state.userAnalyzePhotos
}

export const userPosts = (state) => {
  return state.userPosts
}

export const userAnalyzePosts = (state) => {
  return state.userAnalyzePosts
}

export const userFollowings = (state) => {
  return state.userFollowings
}

export const userCart = (state) => {
  return state.userCart
}

export const userMembership = (state) => {
  return state.userMembership
}

export const boostCampaignPlans = (state) => {
  return state.boostCampaignPlans
}

export const adsCampaignPlans = (state) => {
  return state.adsCampaignPlans
}

export const userBoostCampaigns = (state) => {
  return state.userBoostCampaigns
}

export const userAdsCampaigns = (state) => {
  return state.userAdsCampaigns
}

export const userAffiliates = (state) => {
  return state.userAffiliates
}

export const affiliates = (state) => {
  return state.affiliates
}

export const payment = (state) => {
  return state.payment
}

<template>
  <div>
    <div class="head text-center">
      <h3 class="card-title">{{ $t('reset.addNewPassword') }}</h3>
    </div>
    <form action="#" @submit.prevent="submit" class="mt-3">
      <div class="form-group">
        <div class="input-group">
          <input type="email" class="form-control" id="inputEmail" :placeholder="$t('placeholders.email')" v-model="form.email" disabled>
        </div>
      </div>

      <div class="form-group">
        <div class="input-group">
          <input type="password" class="form-control" id="new_password" :placeholder="$t('placeholders.newPassword')" v-model="form.password" required>
        </div>
      </div>

      <div class="form-group mb-3">
        <div class="input-group">
          <input type="password" class="form-control" id="repeat_password" :placeholder="$t('placeholders.repeatPassword')" v-model="form.password_repeat" required>
        </div>
      </div>

      <response :items="errors" class="mb-3"></response>

      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block">{{ $t('buttons.go') }}</button>
      </div>
    </form>

    <p class="text-center">
      {{ $t('reset.haveAccount') }} <router-link :to="{ name: `SignIn.${$i18n.i18next.language}`}">{{ $t('buttons.signIn') }}</router-link>
    </p>

  </div>
</template>

<script>
import Debug from '@/util/debug'
import { mapActions, mapGetters } from 'vuex'

const debug = Debug('Forgot:Step3')

export default {
  data () {
    return {
      errors: {},
      code: null,
      form: {
        email: null,
        password: null,
        password_repeat: null
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/signUpUser'
    })
  },
  methods: {
    ...mapActions({
      changePassword: 'auth/changePassword'
    }),
    submit () {
      let payload = this.form
      this.changePassword({ payload: payload, context: this })
    }
  },
  mounted () {
    debug.info('mounted')
    this.form.email = this.user.email
    if (!this.form.email) {
      this.$router.push({name: `Forgot.Step2.${this.$i18n.i18next.language}`})
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
    a {
      font-weight: bold;
    }
  }
</style>

import * as api from '../api'
import store from '@/store'
import router from '@/router'
import EventBus from '@/util/eventBus'

export const saveUserBasicInfo = ({ dispatch, commit }, { context, payload }) => {
  api.saveUserBasicInfo(payload).then((response) => {
    context.success = true
    context.unsavedChanges = false
    store.commit('SET_UNSAVED_CHANGES', false)
    // context.setSaved()
    // context.setUnsaved(false)
    // context.clearPassword()
    context.errors = []
    // context.step = 1
    // context.step1Completed = false
    setTimeout(function () {
      // commit('SET_BASIC_USER_DATA', response.data.data)
      // console.log('SET_BASIC_USER_DATA resp', response.data.data)
      store.dispatch('auth/fetchUser').then((resp) => {
        context.init()
      })
      // context.user.password = ''
      // context.user.password_new = ''
      // context.user.password_repeat = ''
    }, 2000)
    // location.reload()
  }).catch((error) => {
    context.success = false
    context.errors = error.response.data.errors
    context.$emit('basicInfoErrors', error.response.data.errors)
  })
}

export const saveFastRegStep1 = ({ dispatch, commit }, { context, payload }) => {
  api.saveUserBasicInfo(payload).then((response) => {
    context.success = true
    context.unsavedChanges = false
    store.commit('SET_UNSAVED_CHANGES', false)
    context.errors = []
    context.step = 2
    context.step1Completed()
    // context.isStep1Loading = false
    setTimeout(function () {
      // commit('SET_BASIC_USER_DATA', response.data.data)
      store.dispatch('auth/fetchUser').then((resp) => {
        context.init()
      })
      // context.step1Completed()
    }, 2000)
    // location.reload()
  }).catch((error) => {
    context.isStep1Loading = false
    context.success = false
    context.errors = error.response.data.errors
    context.step1Errors()
  })
}

export const tryPublish = ({ commit }, { context }) => {
  api.tryPublish().then((response) => {
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const unPublish = ({ commit }, { context }) => {
  api.unPublish().then((response) => {
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const deleteSelf = ({ commit }, { context }) => {
  api.deleteSelf().then((response) => {
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const completeDeleteSelf = ({ dispatch }, { context, payload }) => {
  api.completeDeleteSelf(payload).then((response) => {
    context.success = true
    dispatch('clearAuth')
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getServices = ({ commit }, { context }) => {
  api.getServices().then((response) => {
    commit('SET_SERVICES_DATA', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getSpecialities = ({ commit }, { context }) => {
  api.getSpecialities().then((response) => {
    commit('SET_SPECIALITIES_DATA', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserServices = ({ commit }, { context }) => {
  context.isServicesLoading = true
  api.getUserServices().then((response) => {
    context.formOriginal = {
      services: response.data.data,
      currency: null,
      description: null
    }
    context.init()
    commit('SET_USER_SERVICES_DATA', response.data.data)
    context.isServicesLoading = false
  }).catch((error) => {
    context.isServicesLoading = false
    context.errors = error.response.data.errors
  })
}

export const saveUserServices = ({ commit, dispatch }, { context, payload }) => {
  api.saveUserServices(payload).then((response) => {
    context.success = true
    context.unsavedChanges = false
    store.commit('SET_UNSAVED_CHANGES', false)
    context.errors = []
    if (payload.lastStep) {
      api.tryPublish().then((response) => {
        context.justPublished = true

        setTimeout(function () {
          store.dispatch('auth/fetchUser')
          dispatch('getUserServices', { context: context })
        }, 3000)
      }).catch((error) => {
        context.errors = error.response.data.errors
      })
    } else {
      context.servicesSaved()
    }
  }).catch((error) => {
    // context.isServicesLoading = false
    context.errors = error.response.data.errors
  })
}

export const getUserOnlineServices = ({ commit }, { context }) => {
  context.isServicesLoading = true
  api.getUserOnlineServices().then((response) => {
    context.formOriginal = {
      languages: response.data.data.languages,
      methods: response.data.data.methods,
      services: response.data.data.services,
      currency: null,
      description: null
    }
    context.init()
    commit('SET_USER_ONLINE_SERVICES_DATA', response.data.data)
    context.isServicesLoading = false

    // Get face-to-face services for copy if online services are empty
    api.getUserServices().then((response) => {
      commit('SET_USER_SERVICES_DATA', response.data.data)
    })
  }).catch((error) => {
    context.isServicesLoading = false
    context.errors = error.response.data.errors
  })
}

export const getUserOnlineServicesOnly = ({ commit }, { context }) => {
  api.getUserOnlineServices().then((response) => {
    context.formOriginal = {
      languages: response.data.data.languages,
      methods: response.data.data.methods,
      services: response.data.data.services,
      currency: null,
      description: null
    }
    commit('SET_USER_ONLINE_SERVICES_DATA', response.data.data)
  }).catch((error) => {
    context.isServicesLoading = false
    context.errors = error.response.data.errors
  })
}

export const saveUserOnlineServices = ({ commit, dispatch }, { context, payload }) => {
  api.saveUserOnlineServices(payload).then((response) => {
    context.success = true
    context.unsavedChanges = false
    store.commit('SET_UNSAVED_CHANGES', false)
    context.errors = []

    if (payload.lastStep) {
      setTimeout(function () {
        api.tryPublish().then((response) => {
          context.justPublished = true

          setTimeout(function () {
            store.dispatch('auth/fetchUser')
            dispatch('getUserOnlineServices', {context: context})
          }, 2000)
        }).catch((error) => {
          context.errors = error.response.data.errors
        })
      }, 2000)
    } else {
      context.servicesSaved()
    }
  }).catch((error) => {
    // context.isServicesLoading = false
    context.errors = error.response.data.errors
  })
}

export const skipOnlineServices = ({ commit, dispatch }, { context }) => {
  api.tryPublish().then((response) => {
    context.success = true
    context.unsavedChanges = false
    store.commit('SET_UNSAVED_CHANGES', false)
    context.errors = []
    context.justPublished = true

    setTimeout(function () {
      store.dispatch('auth/fetchUser')
      dispatch('getUserOnlineServices', { context: context })
    }, 3000)
  }).catch((error) => {
    // context.isServicesLoading = false
    context.errors = error.response.data.errors
  })
}

export const getUserSpecialities = ({ commit }, { context }) => {
  api.getUserSpecialities().then((response) => {
    commit('SET_USER_SPECIALITIES_DATA', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const saveUserSpecialities = ({ commit }, { context, payload }) => {
  api.saveUserSpecialities(payload).then((response) => {
    context.errors = []
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserLocations = ({ commit }, { context }) => {
  context.isLocationsLoading = true
  api.getUserLocations().then((response) => {
    commit('SET_USER_LOCATIONS_DATA', response.data.data)
    context.init()
    context.initAutocomplete()
    context.isLocationsLoading = false
  }).catch((error) => {
    context.isLocationsLoading = false
    context.errors = error.response.data.errors
  })
}

export const saveUserLocations = ({ dispatch, commit }, { context, payload }) => {
  api.saveUserLocations(payload).then((response) => {
    // context.$emit('locationsSaved', true)
    context.isLocationsLoading = false
    context.unsavedChanges = false
    store.commit('SET_UNSAVED_CHANGES', false)
    context.success = true
    context.errors = []
    context.step2Completed()
    // context.initAutocomplete()

    setTimeout(function () {
      dispatch('getUserLocations', { context: context }).then((resp) => {
        // context.init()
      })
    }, 2000)
  }).catch((error) => {
    context.isLocationsLoading = false
    context.errors = error.response.data.errors
  })
}

export const getUserCertificates = ({ commit }, { context }) => {
  api.getUserCertificates().then((response) => {
    commit('SET_USER_CERTIFICATES_DATA', response.data.data)
    context.init()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const saveUserCertificates = ({ commit }, { context, payload }) => {
  api.saveUserCertificates(payload).then((response) => {
    context.success = true
    context.unsavedChanges = false
    store.commit('SET_UNSAVED_CHANGES', false)
    context.errors = []
    setTimeout(function () {
      dispatch('getUserCertificates', { context: context }).then((resp) => {
        context.init()
      })
    }, 2000)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserBookings = ({ commit }, { context, payload }) => {
  api.getUserBookings(payload).then((response) => {
    commit('SET_USER_BOOKINGS_DATA', response.data.data)
    if (!_.isUndefined(payload.refreshBookings) && payload.refreshBookings) {
      context.refreshMonthView()
      context.isLoading = false
    }
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const getUserBooking = ({ commit }, { context, payload }) => {
  api.getUserBooking(payload).then((response) => {
    context.bookingData = response.data.data
    context.isLoading = false
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const changeBookingStatus = ({ dispatch }, { context, payload }) => {
  context.isLoading = true
  api.changeBookingStatus(payload).then((response) => {
    setTimeout(function () {
      context.refreshBookings()
    }, 1000)
    // If confirmed, inform user
    if (payload.status === 'confirmed') {
      // let payload2 = {
      //   user_id: payload.user_id,
      //   message: 'Your booking was confirmed!'
      // }
      // store.dispatch('messenger/sendMessage', { payload: payload2 })

      // let user = store.getters['account/user']
      let professional = {
        id: payload.user_id,
        name: '',
        image: ''
      }
      store.commit('messenger/SET_SELECTED_PROFESSIONAL', professional)
      // Send a message that booking was confirmed as a system text
      EventBus.$emit('sendBookingMessage', payload.id, 'system', `messenger.booking.confirmed`)
    }
    context.cancelMessage = ''
    context.success = true
    // context.bookingOpen = false
    context.errors = []
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const cancelBooking = ({ dispatch }, { context, payload }) => {
  context.isLoading = true
  api.changeBookingStatus(payload).then((response) => {
    // context.cancelMessage = ''

    // context.bookingOpen = false
    setTimeout(function () {
      context.success = true
      context.isLoading = false
      context.errors = []
      context.setSuccess()
    }, 1000)
    // If canceled, inform user
    // let payload2 = {
    //   user_id: payload.user_id,
    //   message: `Your booking was canceled. Reason: "${payload.message}"`
    // }
    // store.dispatch('messenger/sendMessage', { payload: payload2 })

    // let user = store.getters['account/user']
    let professional = {
      id: payload.user_id,
      name: '',
      image: ''
    }
    store.commit('messenger/SET_SELECTED_PROFESSIONAL', professional)
    // Send a message that booking was canceled as a system text
    EventBus.$emit('sendBookingMessage', payload.id, 'system', `messenger.booking.canceled`)
    // Send the reason on behalf of user
    setTimeout(function () {
      EventBus.$emit('prepareAndSendMessage', `${payload.message}`)
    }, 2000)
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
    context.setError()
  })
}

export const deleteBooking = ({ dispatch }, { context, payload }) => {
  context.isLoading = true
  api.deleteBooking(payload).then((response) => {
    setTimeout(function () {
      context.success = true
      context.isLoading = false
      context.errors = []
      context.setSuccess()
    }, 1000)
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
    context.setError()
  })
}

export const changeBookingPrice = ({ dispatch }, { context, payload }) => {
  context.isLoadingPrice = true
  api.changeBookingPrice(payload).then((response) => {
    context.success = true
    context.errors = []
    context.isLoadingPrice = false
    setTimeout(function () {
      context.refreshBookings()
    }, 1000)
  }).catch((error) => {
    context.isLoadingPrice = false
    context.errors = error.response.data.errors
  })
}

export const addCustomBooking = ({ commit, dispatch }, { context, payload }) => {
  context.errors = []
  context.success = false
  context.isLoading = true
  api.addCustomBooking(payload).then((response) => {
    setTimeout(function () {
      context.setSuccess()
    }, 1000)
    context.success = true
    context.isLoading = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const getCustomerBookings = ({ commit }, { context, payload }) => {
  api.getCustomerBookings(payload).then((response) => {
    commit('SET_CUSTOMER_BOOKINGS_DATA', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const connectUserInstagramPhotos = ({ commit }, { context, payload }) => {
  api.connectUserInstagramPhotos(payload).then((response) => {
    if (response.data.data.redirect) {
      window.location = response.data.data.redirect
    }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const refreshUserInstagramPhotos = ({ commit, dispatch }, { context, payload }) => {
  api.refreshUserInstagramPhotos(payload).then((response) => {
    setTimeout(function () {
      context.isInstagramLoading = false
      dispatch('getUserPhotos', { context: this })
      context.analyzePhotos()
    }, 3000)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserPhotos = ({ commit }, { context }) => {
  api.getUserPhotos().then((response) => {
    commit('SET_USER_PHOTOS_DATA', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const deletePhotos = ({ commit, dispatch }, { context, payload }) => {
  api.deletePhotos().then((response) => {
    context.success = true
    setTimeout(function () { dispatch('getUserPhotos', { context: this }) }, 1000)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const analyzeUserPhotos = ({ commit, dispatch }, { context, payload }) => {
  api.analyzeUserPhotos(payload).then((response) => {
    commit('SET_USER_ANALYZE_PHOTOS_DATA', response.data.data)
    setTimeout(function () {
      // context.analyzePhotosModalShow = false
      context.isAnalyzerLoading = false
      dispatch('getUserPhotos', { context: this })
    }, 3000)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const connectUserFbPosts = ({ commit }, { context, payload }) => {
  api.connectUserFbPosts(payload).then((response) => {
    if (response.data.data.redirect) {
      window.location = response.data.data.redirect
    }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const connectPageFbPosts = ({ commit }, { context, payload }) => {
  api.connectPageFbPosts(payload).then((response) => {
    if (response.data.data.redirect) {
      window.location = response.data.data.redirect
    }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserPosts = ({ commit }, { context }) => {
  api.getUserPosts().then((response) => {
    commit('SET_USER_POSTS_DATA', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const deletePosts = ({ commit, dispatch }, { context, payload }) => {
  api.deletePosts().then((response) => {
    context.success = true
    setTimeout(function () { dispatch('getUserPosts', { context: this }) }, 1000)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const analyzeUserPosts = ({ commit, dispatch }, { context, payload }) => {
  api.analyzeUserPosts(payload).then((response) => {
    commit('SET_USER_ANALYZE_POSTS_DATA', response.data.data)
    setTimeout(function () {
      // context.analyzePostsModalShow = false
      context.isAnalyzerLoading = false
      dispatch('getUserPosts', { context: this })
    }, 3000)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const saveUserLang = ({ commit }, { payload }) => {
  api.saveUserLang(payload).then((response) => {
    // Nothing
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getFollowings = ({ commit }, { context }) => {
  api.getFollowings().then((response) => {
    commit('SET_USER_FOLLOWINGS_DATA', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const applyPremium = ({ commit }, { payload, context }) => {
  context.isLoading = true
  context.successPremium = false
  api.applyPremium(payload).then((response) => {
    context.isLoading = false
    context.successPremium = true
    context.name = ''
    context.email = ''
    context.phone = ''
    context.explanation = ''
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const changeRole = ({ commit }, { payload, context }) => {
  api.changeRole(payload).then((response) => {
    context.success = true
    store.dispatch('auth/fetchUser').then((resp) => {
      context.setSuccess()
    })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserMembership = ({ commit }, { context }) => {
  context.isLoading = true
  api.getUserMembership().then((response) => {
    commit('SET_USER_MEMBERSHIP', response.data.data)
    context.isLoading = false
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const deleteUserCard = ({ commit, dispatch }, { payload, context }) => {
  context.isLoading = true
  context.success = false
  api.deleteUserCard(payload).then((response) => {
    dispatch('getUserMembership', { context: context })
    // context.isLoading = false
    context.success = true
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const detachUserDefaultCard = ({ commit, dispatch }, { payload, context }) => {
  context.isLoading = true
  context.success = false
  api.detachUserDefaultCard(payload).then((response) => {
    dispatch('getUserMembership', { context: context })
    // context.isLoading = false
    context.success = true
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const setUserDefaultCard = ({ commit, dispatch }, { payload, context }) => {
  context.isLoading = true
  context.success = false
  api.setUserDefaultCard(payload).then((response) => {
    dispatch('getUserMembership', { context: context })
    // context.isLoading = false
    context.success = true
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const cancelUserSubscription = ({ commit, dispatch }, { payload, context }) => {
  context.isLoading = true
  context.success = false
  api.cancelUserSubscription(payload).then((response) => {
    dispatch('getUserMembership', { context: context })
    // context.isLoading = false
    context.success = true
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const getBoostCampaignPlans = ({ commit }, { context, payload }) => {
  api.getBoostCampaignPlans(payload).then((response) => {
    commit('SET_BOOST_CAMPAIGN_PLANS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getAdsCampaignPlans = ({ commit }, { context, payload }) => {
  api.getAdsCampaignPlans(payload).then((response) => {
    commit('SET_ADS_CAMPAIGN_PLANS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserBoostCampaigns = ({ commit }, { context, payload }) => {
  api.getUserBoostCampaigns(payload).then((response) => {
    commit('SET_USER_BOOST_CAMPAIGNS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserAdsCampaigns = ({ commit }, { context, payload }) => {
  api.getUserAdsCampaigns(payload).then((response) => {
    commit('SET_USER_ADS_CAMPAIGNS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getUserAffiliates = ({ commit }, { context }) => {
  api.getUserAffiliates().then((response) => {
    commit('SET_USER_AFFILIATES', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const stopCampaign = ({ commit, dispatch }, { context, payload }) => {
  api.stopCampaign(payload).then((response) => {
    context.setCampaignStatus(false, payload.type)
    // commit('SET_USER_SEARCH_CAMPAIGNS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const resumeCampaign = ({ commit, dispatch }, { context, payload }) => {
  api.resumeCampaign(payload).then((response) => {
    context.setCampaignStatus(true, payload.type)
    // commit('SET_USER_SEARCH_CAMPAIGNS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const addCampaign = ({ dispatch }, { context, payload }) => {
  context.isLoading = true
  api.addCampaign(payload).then((response) => {
    // Placement, new campaign true/false
    context.setSuccess(payload.placement, true)
    context.isLoading = false
    context.campaignId = response.data.data.id
    context.campaign = response.data.data
    context.checkoutData.client_secret = response.data.data.client_secret
    // dispatch('getSingleChallengeData', { payload: challengeId, context: this })
    // if (payload.campaign === 'search') {
    //   setTimeout(function () {
    //     dispatch('getUserSearchCampaigns', { context: context })
    //   }, 2000)
    // } else if (payload.campaign === 'ads') {
    //   setTimeout(function () {
    //     dispatch('getUserAdsCampaigns', { context: context })
    //   }, 2000)
    // }
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const updateCampaign = ({ dispatch }, { context, payload }) => {
  context.isLoading = true
  api.updateCampaign(payload).then((response) => {
    // Placement, new campaign true/false
    context.setSuccess(payload.placement, false)
    context.campaign = response.data.data
    context.isLoading = false
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const getAffiliates = ({ commit }, { context }) => {
  api.getAffiliates().then((response) => {
    commit('SET_AFFILIATES', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const paymentStart = ({ commit }, { context, payload }) => {
  api.paymentStart(payload).then((response) => {
    commit('SET_PAYMENT', response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const paymentFinish = ({ commit }, { context, payload }) => {
  api.paymentFinish(payload).then((response) => {
    // commit(types.SET_PAYMENT, response.data)
    context.setSuccess()
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const startMembership = ({ commit }, { context, payload }) => {
  api.startMembership(payload).then((response) => {
    console.log('action resp', response)
    context.handleMembershipResponse(response.data)
  }).catch((error) => {
    context.isLoading = false
    console.log('action error', error)
    context.errors = error.response.data.errors
  })
}

export const checkMembership = ({ commit }, { context, payload }) => {
  api.checkMembership(payload).then((response) => {
    // commit(types.SET_PAYMENT, response.data)

    // Reload user data for new membership
    setTimeout(function () {
      store.dispatch('auth/fetchUser').then((resp) => {
        context.setSuccess()
      })
    }, 2000)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

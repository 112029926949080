import uuidv4 from 'uuid/v4'
import state from './state'
import * as api from '../api'

export const conversationSeed = ({ rootState }) => {
  // let convoRef = DB.collection('conversations')
  //
  // convoRef.add({
  //   created: Date.now(),
  //   users: ['mr_a', 'mr_b'],
  //   messages: [
  //     { id: uuidv4(), text: 'Hi there', sender: 'mr_a', created: Date.now() },
  //     { id: uuidv4(), text: 'Hi to you too!', sender: 'mr_b', created: Date.now() }
  //   ]
  // })
  //
  // convoRef.add({
  //   created: Date.now(),
  //   users: ['mr_a', 'mr_c'],
  //   messages: []
  // })
}

export const conversationGet = ({ commit, rootState }, { professionalId }) => {
  // let convoRef = DB.collection('conversations')
  // convoRef.get().then(function(result) {
  //   result.forEach(function(doc) {
  //     let conversation = doc
  //     commit('SET_CONVERSATION', { conversation })
  //   })
  // })
}

export const createConversation = ({ commit, dispatch }, { context, payload }) => {
  api.createConversation(payload).then((response) => {
    console.log('response.data.data', response.data.data)
    // Conversation created, set selected conversation
    commit('SET_SELECTED_CONVERSATION', response.data.data)
    // commit('SET_NEW_CONVERSATION', response.data.data)
    setTimeout(function () {
      dispatch('getConversations', { context: context })
    }, 2000)
    // Send message
    context.onMessageWasSent({})
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const sendMessage = ({ commit, dispatch, getters }, { context, payload }) => {
  let inputDisabled = getters.inputDisabled
  if (!inputDisabled) {
    commit('SET_INPUT_DISABLED', true)
    api.sendMessage(payload).then((response) => {
      // context.newMessageText = ''
      // context.inputDisabled = false
      // context.success = true
      commit('SET_SPECIFIC_MESSAGES', response.data.data)
      setTimeout(function () {
        if (!_.isUndefined(payload.conversation_id)) {
          dispatch('getMessages', {payload: payload, context: context})
        }
        dispatch('getConversations', { context: context })
      }, 2000)
      commit('SET_INPUT_DISABLED', false)
    }).catch((error) => {
      commit('SET_INPUT_DISABLED', false)
      context.errors = error.response.data.errors
    })
  } else {
    console.log('Input disabled')
    // Message already sent
  }
}

export const getConversations = ({ commit, dispatch }, { context }) => {
  api.getConversations().then((response) => {
    let conversations = response.data.data
    commit('SET_CONVERSATIONS', conversations)

    // if (conversations.length > 0) {
    //   if (_.isNil(state.selectedProfessional.id)) {
    //     let recipientId = conversations[0].recipient_id
    //     dispatch('getMessages', { payload: recipientId, context: this })
    //     let payload = {
    //       id: recipientId,
    //       name: `${conversations[0].user_first_name} ${conversations[0].user_last_name}`,
    //       image: conversations[0].user_image
    //     }
    //     commit('SET_SELECTED_PROFESSIONAL', payload)
    //   }
    // }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getMessages = ({ commit, dispatch }, { context, payload }) => {
  if (!_.isUndefined(payload.no_loading) && payload.no_loading) {
    // No Preloader
  } else {
    commit('SET_LOADING', true)
  }
  api.getMessages(payload.conversation_id).then((response) => {
    console.log('Conversation messages', response)
    commit('SET_MESSAGES', response.data.data)
    commit('SET_LOADING', false)
    commit('SET_MESSAGE_LOADING', false)
    commit('SET_MESSAGE_PENDING', '')
    setTimeout(function () {
      dispatch('getConversations', { context: context })
    }, 2000)
    // context.isConversationLoading = false
    // context.success = true
  }).catch((error) => {
    // context.isConversationLoading = false
    commit('SET_LOADING', false)
    context.errors = error.response.data.errors
  })
}

export const getSharedKeys = ({ commit, dispatch }, { context, payload }) => {
  api.getSharedKeys(payload.conversation_id).then((response) => {
    commit('SET_SHARED_KEYS', response.data.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const userSeed = ({ rootState }) => {
  // let userRef = DB.collection('users')
  //
  // userRef.doc('mr_a').set({
  //   firstName: 'Andy',
  //   lastName: 'Andyson'
  // })
  //
  // userRef.doc('mr_b').set({
  //   firstName: 'Ben',
  //   lastName: 'Benson'
  // })
  //
  // userRef.doc('mr_c').set({
  //   firstName: 'Cee',
  //   lastName: 'Ceeson'
  // })
}

export const userGet = ({ commit, rootState }) => {
  // let userRef = DB.collection('users')
  //
  // userRef.get().then(function(result) {
  //   result.forEach(function(doc) {
  //     let user = doc
  //     commit('SET_USER', { user })
  //   })
  // })
}

export const getUserConversationMessages = ({ commit, rootState }) => {}
export const saveMessagesAsRead = ({ commit, rootState }) => {}

export const signUpUser = (state) => {
  return state.signUpUser
}

export const authRedirect = (state) => {
  return state.authRedirect
}

export const userNotifications = (state) => {
  const data = state.userNotifications.data
  return data
}

export const notificationsSettings = (state) => {
  const data = state.notificationsSettings.data
  return data
}

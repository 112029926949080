export const LOCALE_DEFAULT = 'en'
export const SERVICES_LIMIT = 10
export const CERTIFICATES_LIMIT = 5
export const DEBOUNCE_TIMER = 500

export const IDB_NAME = 'Fine.fit'
export const IDB_DOMAIN = 'fine.fit'
export const DOCUMENT_TITLE = 'fine.fit'

export const AFTER_LOGIN_PAGE = 'Account'

export const IDB_LOCALFORAGE = `${IDB_NAME}:localforage`
export const IDB_LOCALFORAGE_TABLE_GENERAL = 'general'

export const ENV_IS_DEVELOPMENT = process.env.NODE_ENV === 'development'
export const ENV_IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const ENV_IS_TESTING = process.env.NODE_ENV === 'testing'

export const DEFAULT_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Helsinki'

export const STRIPE_KEY = process.env.NODE_ENV === 'production' ? 'pk_live_5TFMEPBUxa7WBRSkTla14qa500RJpiuOxL' : 'pk_test_Gw5CUIJyIH6OhzJQn3dUFM9f00SIAkGKDl'
export const CRYPTER_AES_KEY_MAC = 'af1cdab992db52fd0cb377fa23310dec'

<template>
  <div>
    <response :text="$t('account.emailConfirmed')" variant="success"></response>
    <p class="mt-3" align="center">
      <b-btn v-if="authenticated" variant="primary" :to="{ name: `Account.${$i18n.i18next.language}` }">{{ $t('account.accountTitle') }}</b-btn>
      <b-btn v-else variant="primary" v-b-modal.signInModal>{{ $t('buttons.signIn') }}</b-btn>
    </p>
  </div>
</template>

<script>
import Debug from '@/util/debug'
import { mapActions, mapGetters } from 'vuex'

const debug = Debug('Signup:Step3')

export default {
  data () {
    return {
      errors: [],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'account/authenticated',
      user: 'auth/signUpUser'
    }),
    firstError () {
      if (_.isEmpty(this.errors)) {
        return null
      }
      return _.values(this.errors)[0][0]
    }
  },
  methods: {
    ...mapActions({
      register: 'auth/signUpAccount'
    })
  },
  mounted () {
    debug.info('mounted')
  }
}
</script>

<style lang="scss" scoped>
  p {
    a {
      font-weight: bold;
    }
  }

  .head {
    margin: 2rem 0;
  }
</style>

import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { i18n } from './app/boot'
import { DEFAULT_TIME_ZONE } from '@/util/constants'

import moment from 'moment-timezone'
moment.tz.setDefault(DEFAULT_TIME_ZONE)

// Global plugins
require('./app/boot')

Vue.config.productionTip = false

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  template: '<App/>',
  components: { App }
})

export const userConversations = (state) => {
  return state.userConversations
}

export const userMessages = (state) => {
  return state.userMessages
}

export const sharedKeys = (state) => {
  return state.sharedKeys
}

export const selectedProfessional = (state) => {
  return state.selectedProfessional
}

export const selectedConversation = (state) => {
  return state.selectedConversation
}

export const typingMessage = (state) => {
  return state.typingMessage
}

export const isLoading = (state) => {
  return state.isLoading
}

export const isMsgLoading = (state) => {
  return state.isMsgLoading
}

export const msgPending = (state) => {
  return state.msgPending
}

export const inputDisabled = (state) => {
  return state.inputDisabled
}

import axios from 'axios'

/* Professional profile */
export const getProfessionalInfo = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/professional/${payload}/info`)
}

export const addProfessionalReview = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/professional/${payload.slug}/reviews`, payload)
}

export const addProfessionalBooking = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/professional/${payload.slug}/bookings`, payload)
}

export const getProfessionalOfflineAds = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/professional/${payload}/ads/offline`)
}

export const getProfessionalOnlineAds = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/professional/${payload}/ads/online`)
}

/* Beginning of search for face-to-face professionals */
export const searchProfessionals = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/professionals/search`, payload)
}

export const searchProfessionalsNames = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/professionals/search-names`, payload)
}

export const searchProfessionalsService = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/professionals/search-service`, payload)
}

export const searchBoostedProfessionals = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/professionals/search-boosted`, payload)
}

export const searchAds = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/professionals/search-ads`, payload)
}

export const incCampaignClicks = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/professionals/campaign-clicks`, payload)
}
/* End of search for face-to-face professionals */

export const followProfessional = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/professional/${payload}/follow`, payload)
}

export const unfollowProfessional = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/professional/${payload}/unfollow`, payload)
}

/* Beginning of search for online professionals */
export const searchOnlineProfessionals = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/online-professionals/search`, payload)
}

export const searchOnlineProfessionalsNames = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/online-professionals/search-names`, payload)
}

export const searchOnlineProfessionalsService = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/online-professionals/search-service`, payload)
}

export const searchOnlineBoostedProfessionals = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/online-professionals/search-boosted`, payload)
}

export const searchOnlineAds = (payload) => {
  if (payload.search === '') {
    payload.search = '#'
  }
  if (payload.location === '') {
    payload.location = '#'
  }
  return axios.post(`${process.env.API_URL}/v1/online-professionals/search-ads`, payload)
}

export const incOnlineCampaignClicks = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/online-professionals/campaign-clicks`, payload)
}
/* End of search for online professionals */

import Vue from 'vue'
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import lodash from 'lodash'
import VueLodash from 'vue-lodash'
Vue.use(VueLodash, lodash)

// import Vue2Dropzone from 'vue2-dropzone'
// import 'vue2-dropzone/dist/vue2Dropzone.css'
// Vue.use(Vue2Dropzone)

import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime)

import vueEventCalendar from 'vue-event-calendar'
import 'vue-event-calendar/dist/style.css'

Vue.use(vueEventCalendar, {locale: 'en'})

// import Messenger from '@/app/messenger/components/Home'
// Vue.component('Messenger', Messenger)

// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyBG9gy2nA4LYg0V_cBT7p0L3lKOH2YL5TM',
//     libraries: 'places'
//   }
// })

import Response from '@/app/global/components/Response'
Vue.component('response', Response)

import Preloader from '@/app/global/components/Preloader'
Vue.component('preloader', Preloader)

import Avatar from '@/app/global/components/Avatar'
Vue.component('avatar', Avatar)

import CharsCounter from '@/app/global/components/CharsCounter'
Vue.component('chars-counter', CharsCounter)

import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

// import VueSocketio from 'vue-socket.io-extended'
// import io from 'socket.io-client'
// import axios from 'axios'

// We use Socket io for messenger
// Vue.use(VueSocketio, io(process.env.API_URL, {
//   transportOptions: {
//     polling: {
//       extraHeaders: {
//         'Authorization': axios.defaults.headers.common['Authorization']
//       }
//     }
//   }
// }))

import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'

import { en } from '@/locales/en'
import { fi } from '@/locales/fi'
// import VueSocketio from "vue-socket.io-extended";
// import io from "socket.io-client";
// import axios from "axios";

Vue.use(VueI18Next)

i18next.init({
  lng: 'en',
  resources: {
    en: { translation: en },
    fi: { translation: fi }
  }
})

export const i18n = new VueI18Next(i18next)

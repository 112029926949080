import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

import state from './state'
import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

import auth from '@/app/auth/store'
import account from '@/app/account/store'
import messenger from '@/app/messenger/store'
import professionals from '@/app/professionals/store'
import feedback from '@/app/feedback/store'


Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    auth,
    account,
    messenger,
    professionals,
    feedback
  }
})

store.dispatch('auth/setToken').then(() => {
  let slug = {}
  store.dispatch('auth/fetchUser', { context: slug }).then(() => {
    // Nothing
  }).catch(() => {
    store.dispatch('auth/clearAuth')
    router.replace({ name: 'login' })
  })
}).catch(() => store.dispatch('auth/clearAuth'))

export default store

import state from './state'

export default {
  ADD_MESSAGE (state, { conversationId, message }) {
    if (!state.allMsgIds.includes(message.id)) {
      state.all[conversationId].messages.push(message)
      state.allMsgIds.push(message.id)
    }
  },
  SET_USER (state, { user }) {
    state.allUsers = {...state.allUsers, [user.id]: user.data() }
  },
  SET_CONVERSATIONS (state, payload) {
    state.userConversations = payload
  },
  SET_NEW_CONVERSATION (state, payload) {
    state.userConversations.push(payload)
  },
  SET_MESSAGES (state, payload) {
    if (payload) {
      state.userMessages = payload
    }
  },
  SET_SHARED_KEYS (state, payload) {
    state.sharedKeys = payload
  },
  SET_SPECIFIC_MESSAGES (state, payload) {
    console.log('PAYLOAD', payload)
    console.log('state', state)
    // let recipientId = payload.recipient_id
    // let messageId = Object.keys(payload.messages)[0]
    // console.log(recipientId)

    if (payload && !_.isEmpty(payload)) {
      state.userMessages.push(payload)
    }

    // state.userMessages[recipientId].messages = {
    //   ...state.userMessages[recipientId].messages,
    //   [messageId]: payload.messages[messageId]
    // }
    console.log(state.userMessages)
    // state.userMessages[recipientId]['messages'][messageId] = payload.messages[messageId]

  },
  SET_TYPING_MESSAGE (state, payload) {
    if (payload) {
      state.typingMessage = payload
    }
  },
  SET_SELECTED_PROFESSIONAL (state, payload) {
    state.selectedProfessional = payload
    state.userMessages = []
  },
  SET_SELECTED_CONVERSATION (state, payload) {
    state.selectedConversation = payload
  },
  SET_LOADING (state, payload) {
    state.isLoading = payload
  },
  SET_MESSAGE_LOADING (state, payload) {
    state.isMsgLoading = payload
  },
  SET_MESSAGE_PENDING (state, payload) {
    state.msgPending = payload
  },
  SET_INPUT_DISABLED (state, payload) {
    state.inputDisabled = payload
  }
}

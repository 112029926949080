<template>
  <div id="app">
    <div id="main-block">
      <site-header></site-header>

      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>

      <site-footer></site-footer>

      <b-modal v-if="!authenticated" id="signInModal" :title="$t('signIn.title')">
        <div class="modal-body">
          <sign-in></sign-in>
        </div>
        <div slot="modal-footer">
          <p>{{ $t('signIn.forgotLabel') }} <a href="javascript:void(0);" v-b-modal.resetPasswordModal>{{ $t('buttons.resetPassword') }}</a></p>
          <p>{{ $t('signIn.signUpLabel') }} <a href="javascript:void(0);" v-b-modal.signUpModal>{{ $t('buttons.here') }}</a></p>
        </div>
      </b-modal>

      <b-modal v-if="!authenticated" id="signUpModal" :title="$t('signUp.title')">
        <div class="modal-body">
          <sign-up></sign-up>
        </div>
        <div slot="modal-footer">
          <span>{{ $t('signIn.goBack') }} <a href="javascript:void(0);" v-b-modal.signInModal>{{ $t('signIn.title') }}</a>.</span>
        </div>
      </b-modal>

      <b-modal v-if="!authenticated" id="signUpConfirmationModal" :title="$t('signUp.title')" v-model="signUpConfirmationModalShow">
        <div class="modal-body">
          <sign-up-confirmation :provider="loadedProvider"></sign-up-confirmation>
        </div>
        <div slot="modal-footer">
          <div>{{ $t('signIn.goBack') }} <a href="javascript:void(0);" v-b-modal.signInModal>{{ $t('signIn.title') }}</a>.</div>
        </div>
      </b-modal>

      <b-modal v-if="!authenticated" id="resetPasswordModal" :title="$t('reset.title')">
        <div class="modal-body">
          <reset-password></reset-password>
        </div>
        <div slot="modal-footer">
          <span>{{ $t('signIn.goBack') }} <a href="javascript:void(0);" v-b-modal.signInModal>{{ $t('signIn.title') }}</a>.</span>
        </div>
      </b-modal>

      <b-modal id="signUpSuccessfulModal" title="Sign up almost done!" v-model="modalShow">
        <div class="modal-body">
          <b-alert variant="success" show>We just sent you a confirmation email!</b-alert>
          <p>After confirming your registration, you can sign in.</p>
          <sign-in></sign-in>
        </div>
        <div slot="modal-footer">
          <p>You don't have an account? You can create one <a href="javascript:void(0);" v-b-modal.signUpModal>create one</a></p>
        </div>
      </b-modal>

      <b-modal id="accountBlockedModal" title="You have been blocked." v-model="accountBlockedModalShow">
        <div class="modal-body">
          <i class="material-icons blocked-icon">
            security
          </i>
          <b-alert variant="danger" show>{{ $t('modals.accountBlocked.title') }}</b-alert>
          <p v-if="blockedReason" v-html="$t('modals.accountBlocked.reason', { reason: blockedReason })"></p>
          <p v-html="$t('modals.accountBlocked.body')"></p>
        </div>
        <div slot="modal-footer">
          <p v-html="$t('modals.accountBlocked.footer')"></p>
        </div>
      </b-modal>

      <div class="overlay" @click="closeMessenger()"></div>
    </div>

    <div v-if="1 === 2" id="messenger">
      <div class="relative h-100">
        <b-btn id="close-messenger" @click="closeMessenger()">X</b-btn>
        <Messenger></Messenger>
      </div>
    </div>

    <div v-if="showWelcomeNotice" class="welcome-notice">
      <b-row class="notice-content mx-auto">
        <b-col cols="12" md="7" lg="8">
          <a href="javascript:void(0);"
             @click.prevent="closeWelcomeNotice"
             class="btn-close">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 47.971 47.971" style="width: 15px; height: 15px; enable-background:new 0 0 47.971 47.971;" xml:space="preserve">
              <g>
                <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                  c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                  C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                  s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"/>
              </g>
            </svg>
          </a>
          <h4>{{ $t('welcomeNotice.title') }}</h4>
          <p v-html="$t('welcomeNotice.intro')"></p>
        </b-col>
        <b-col cols="12" md="5" lg="4" class="my-md-auto">
          <b-btn @click="closeWelcomeNotice"
                 class="btn-outline tester"
                 variant="primary">{{ $t('welcomeNotice.cta') }}</b-btn>
        </b-col>
      </b-row>
    </div>

    <div v-if="!_.isEmpty(user) && !user.social_only && !confirmedEmail" class="unconfirmed-email-notice d-md-flex align-items-center text-center">
      <div class="ml-auto">{{ $t('account.emailUnconfirmed') }}</div>
      <preloader v-if="isEmailLoading" title="none" class="ml-auto ml-md-3 mr-auto"></preloader>
      <div v-else-if="emailSuccess" class="ml-auto ml-md-3 mr-auto mt-3 mt-md-0">
        <img src="~/assets/images/icon_success.svg" width="30" class="align-middle" />
      </div>
      <b-btn v-else
             class="btn-outline tester ml-auto ml-md-3 mr-auto mt-3 mt-md-0"
             variant="primary"
             @click="resendConfirmation">{{ $t('buttons.resendConfirmation') }}</b-btn>
    </div>

    <div v-if="1 === 2">
      <div v-if="!authenticated" class="testers-notice d-sm-none">
        <b-btn class="btn-outline tester"
               variant="primary"
               :to="{ name: `TestersLanding.${$i18n.i18next.language}` }">{{ $t('testers.ctaBtn') }}</b-btn>
      </div>
      <div v-else-if="!_.isEmpty(user) && !feedbackSent && user.role_id === 3 && user.published" class="feedback-notice">
        <div>{{ $t('testers.feedbackTitle') }}</div>
        <b-btn class="btn-outline tester ml-3 mt-3"
               variant="primary"
               @click="openFeedbackModal">{{ $t('feedback.submit') }}</b-btn>
      </div>
    </div>

    <new-messenger v-if="authenticated"></new-messenger>

    <confirmation id="rootConfirmation"
                  :modalData="modalData"
                  @success="refreshBookings"></confirmation>

    <feedback v-if="1 === 2"></feedback>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import EventBus from '@/util/eventBus'
  import { updateFavicon } from '@/util/dom'
  // import './assets/styles/app.css'

  /* Start of lazy load components config */
  import lazyLoadComponent from '@/util/lazy-load-component'
  import Preloader from '@/app/global/components/Preloader'
  // import SkeletonBox from '@/app/global/components/SkeletonBox'
  const lazyLoadOptions = {
    loading: Preloader,
    loadingData: {
      props: {
        width: '100%',
        height: '2rem'
      }
    }
  }
  /* End of lazy load components config */

  export default {
    name: 'App',
    components: {
      SiteHeader: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/global/components/Header')}),
      SiteFooter: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/global/components/Footer')}),
      // SignIn: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/auth/components/SignIn')}),
      // SignUp: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/auth/components/SignUp')}),
      SignUpConfirmation: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/auth/components/SignUpConfirmation')}),
      // ResetPassword: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/auth/components/ResetPassword')}),
      NewMessenger: () => import('@/app/messenger/new/Launcher'),
      Confirmation: () => import('@/app/global/components/Confirmation'),
      Feedback: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/feedback/components/Feedback')}),
      SignIn: () => import('@/app/auth/components/SignIn'),
      SignUp: () => import('@/app/auth/components/SignUp'),
      ResetPassword: () => import('@/app/auth/components/ResetPassword')
    },
    data () {
      return {
        success: false,
        errors: {},
        modalShow: false,
        signUpConfirmationModalShow: false,
        currentHash: this.$route.hash,
        loadedProvider: '',
        modalData: {},
        showWelcomeNotice: true,
        isEmailLoading: false,
        emailSuccess: false
      }
    },
    computed: {
      ...mapGetters({
        user: 'account/user',
        authenticated: 'account/authenticated',
        userConversations: 'messenger/userConversations',
        selectedConversation: 'messenger/selectedConversation',
        selectedProfessional: 'messenger/selectedProfessional',
        feedbackSent: 'feedback/feedbackSent',
        showFeedbackModal: 'feedback/showModal'
      }),
      accountBlockedModalShow () {
        if (this.authenticated) {
          if (!_.isEmpty(this.user)) {
            if (this.user.blocked) {
              return true
            }
          }
        }
        return false
      },
      blockedReason () {
        if (!_.isEmpty(this.user)) {
          if (!_.isUndefined(this.user.blocked_reason)) {
            return this.user.blocked_reason
          }
        }
        return ''
      },
      confirmedEmail () {
        if (this.authenticated) {
          if (!_.isEmpty(this.user) && !_.isUndefined(this.user.email_confirmed)) {
            return this.user.email_confirmed
          }
        }
        return true
      }
    },
    methods: {
      ...mapActions({
        getUserConversations: 'messenger/getConversations',
        getUserMessages: 'messenger/getMessages',
        setAuthRedirect: 'auth/authRedirect',
        sendNotificationsToken: 'auth/sendNotificationsToken',
        getUserBookings: 'account/getUserBookings',
        changeBookingStatus: 'account/changeBookingStatus',
        sendMessage: 'messenger/sendMessage',
        resendEmailConfirmation: 'auth/resendEmailConfirmation'
      }),
      ...mapMutations({
        showFeedbackModalMut: 'feedback/SET_SHOW_MODAL',
        typingMessageMut: 'messenger/SET_TYPING_MESSAGE'
      }),
      updateFavicon: updateFavicon,
      openMessenger (hash) {
        hash = hash.replace('#', '')
        if (hash === 'messenger') {
          let messenger = document.getElementById(hash)

          if (messenger) {
            if (messenger.classList.contains('slide-out')) {
              document.getElementById('messenger').classList.remove('slide-out')
            }

            if (messenger.classList.contains('active')) {
              // Do nothing
            } else {
              document.getElementById(hash).classList.add('active')
              document.getElementById('main-block').classList.add('messenger-open')
            }
          }
        }
      },
      closeMessenger () {
        let messenger = document.getElementById('messenger')

        if (messenger) {
          if (messenger.classList.contains('active')) {
            document.getElementById('messenger').classList.remove('active')
            document.getElementById('main-block').classList.remove('messenger-open')
            document.getElementById('messenger').classList.add('slide-out')
          }
        }

        let currentPath = this.$route.path
        this.$router.push({ path: currentPath })
      },
      isMobileDevice () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        }
      },
      getRoutesList (routes, pre) {
        return routes.reduce((array, route) => {
          const path = `${pre}${route.path}`;

          if (route.path !== '*') {
            array.push(path);
          }

          if (route.children) {
            array.push(...this.getRoutesList(route.children, `${path}/`));
          }

          return array;
        }, []);
      },
      setConfirmBooking (bookingId, professionalId, customerId) {
        let userId = customerId
        if (this.user.id === customerId) {
          userId = professionalId
        }

        let payload = {
          id: bookingId,
          status: 'confirmed',
          user_id: userId
        }
        this.changeBookingStatus({ payload: payload, context: this })
      },
      setCancelBooking (bookingId, professionalId, customerId) {
        let userId = customerId
        if (this.user.id === customerId) {
          userId = professionalId
        }

        this.modalData = {
          title: 'modals.cancelBooking.confirmation.title',
          intro: 'modals.cancelBooking.confirmation.intro',
          successTitle: 'modals.cancelBooking.canceled.title',
          successIntro: 'modals.cancelBooking.canceled.intro',
          messageTitle: 'modals.cancelBooking.confirmation.messageTitle',
          messagePlaceholder: 'modals.cancelBooking.confirmation.messagePlaceholder',
          messageLimit: 100,
          parameters: {
            id: bookingId,
            user_id: userId,
            status: 'canceled',
            message: '',
            action: 'account/cancelBooking',
            endpoint: '/v1/professional/me/bookings/status'
          }
        }
        this.$root.$emit('bv::show::modal', 'rootConfirmation')
      },
      setDeleteBooking (bookingId) {
        this.modalData = {
          title: 'modals.deleteBooking.confirmation.title',
          intro: 'modals.deleteBooking.confirmation.intro',
          successTitle: 'modals.deleteBooking.canceled.title',
          successIntro: 'modals.deleteBooking.canceled.intro',
          messageTitle: 'modals.deleteBooking.confirmation.messageTitle',
          messagePlaceholder: 'modals.deleteBooking.confirmation.messagePlaceholder',
          parameters: {
            id: bookingId,
            action: 'account/deleteBooking',
            endpoint: '/v1/professional/me/bookings/status'
          }
        }
        this.$root.$emit('bv::show::modal', 'rootConfirmation')
      },
      refreshBookings () {
        let parent = this
        let payload = {
          date: parent.date,
          refreshBookings: true
        }
        this.getUserBookings({ context: this, payload: payload })
      },
      sendBookingMessage (bookingId, bookingStatus, message = '') {
        let conversations = this.userConversations
        let proId = this.selectedProfessional.id
        let conversation = {}

        if (_.isEmpty(this.selectedConversation)) {
          for (let i = 0; i < conversations.length; i++) {
            if (!_.isUndefined(conversations[i].user_ids) && !_.isEmpty(conversations[i].user_ids)) {
              // Check if there is an existing conversation with only this professional
              if (conversations[i].user_ids.length === 1 && conversations[i].user_ids[0] === proId) {
                conversation = conversations[i]
                break
              }
            }
          }
        } else {
          conversation = this.selectedConversation
        }

        let payload = {
          type: 'text',
          message: '',
          no_loading: false
        }

        if (bookingStatus === 'pending') {
          payload.type = 'booking'
          payload.message = bookingId
        } else if (bookingStatus === 'confirmed') {
          payload.message = message
        } else if (bookingStatus === 'canceled') {
          payload.message = message
        } else if (bookingStatus === 'system') {
          payload.type = 'system'
          payload.message = message
        }

        let parent = this
        if (!_.isEmpty(conversation)) {
          // Load existing conversation
          this.$store.commit('messenger/SET_SELECTED_CONVERSATION', conversation)
          payload.conversation_id = conversation.conversation_id
        } else {
          // Create new potential conversation
          payload.recipients = [this.selectedProfessional.id]
        }

        this.sendMessage({ context: this, payload: payload }).then(function () {
          // parent.success = true
        })
      },
      prepareAndSendMessage (message) {
        let conversations = this.userConversations
        let proId = this.selectedProfessional.id
        let conversation = {}

        if (_.isEmpty(this.selectedConversation)) {
          for (let i = 0; i < conversations.length; i++) {
            if (!_.isUndefined(conversations[i].user_ids) && !_.isEmpty(conversations[i].user_ids)) {
              // Check if there is an existing conversation with only this professional
              if (conversations[i].user_ids.length === 1 && conversations[i].user_ids[0] === proId) {
                conversation = conversations[i]
                break
              }
            }
          }
        } else {
          conversation = this.selectedConversation
        }

        let payload = {
          type: 'text',
          message: message,
          no_loading: true
        }

        if (!_.isEmpty(conversation)) {
          // Load existing conversation
          this.$store.commit('messenger/SET_SELECTED_CONVERSATION', conversation)
          payload.conversation_id = conversation.conversation_id
        } else {
          // Create new potential conversation
          payload.recipients = [this.selectedProfessional.id]
        }

        this.sendMessage({ context: this, payload: payload }).then(function () {
        })
      },
      openFeedbackModal () {
        this.$root.$emit('bv::show::modal', 'feedbackModal')
      },
      closeWelcomeNotice () {
        this.showWelcomeNotice = false
      },
      socketInit () {
        let parent = this
        this.$socket.on('GET_MESSAGE', (data) => {
          if (!_.isEmpty(parent.selectedConversation) && !_.isUndefined(parent.selectedConversation.conversation_id)) {
            let payload = parent.selectedConversation
            payload.no_loading = true
            parent.getUserMessages({context: parent, payload: payload})
          }
          parent.getUserConversations({context: parent})
        })

        this.$socket.on('GET_TYPING_MESSAGE', (data) => {
          if (!_.isEmpty(this.selectedConversation) && !_.isUndefined(this.selectedConversation.conversation_id)) {
            if (this.selectedConversation.conversation_id === data.data.conversation_id) {
              parent.typingMessageMut(data.data)
              // After 1 second of inactivity, remove typing message state
              setTimeout(function () {
                parent.typingMessageMut({})
              }, 2000)
            }
          }
        })
      },
      resendConfirmation () {
        let payload = {
          email: this.user.email
        }
        this.resendEmailConfirmation({ payload: payload, context: this })
      }
    },
    mounted () {
      // this.openMessenger(this.currentHash)
      // this.$router.afterEach((to, from) => {
      //   this.openMessenger(to.hash)
      // })

      let parent = this

      let urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('registered')) {
        if (urlParams.get('registered') === 'false') {
          this.signUpConfirmationModalShow = true
          // this.$root.$emit('bv::show::modal', 'signUpConfirmationModal')
        }
      }

      this.loadedProvider = 0
      if (urlParams.has('provider')) {
        let provider = parseInt(urlParams.get('provider'))
        if (provider === 1 || provider === 2) {
          this.loadedProvider = provider
        }
      }

      EventBus.$on('setConfirmBooking', function (bookingId, professionalId, customerId) {
        parent.setConfirmBooking(bookingId, professionalId, customerId)
      })

      EventBus.$on('setCancelBooking', function (bookingId, professionalId, customerId) {
        parent.setCancelBooking(bookingId, professionalId, customerId)
      })

      EventBus.$on('setDeleteBooking', function (bookingId) {
        parent.setDeleteBooking(bookingId)
      })

      EventBus.$on('sendBookingMessage', function (bookingId, bookingStatus, message = '') {
        parent.sendBookingMessage(bookingId, bookingStatus, message)
      })

      EventBus.$on('prepareAndSendMessage', function (message) {
        parent.prepareAndSendMessage(message)
      })

      // const API_KEY = 'AIzaSyBG9gy2nA4LYg0V_cBT7p0L3lKOH2YL5TM'
      // const script = document.createElement('script')
      // script.async = true
      // script.defer = true
      // script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`
      // document.querySelector('head').appendChild(script)
    },
    created () {
      // Add favicon dynamically, based if browser has/hasn't dark mode. Param: notifications icon (true/false)
      this.updateFavicon(false)

      let signUpSuccessful = this.$route.params.success
      if (signUpSuccessful === 'success') {
        this.modalShow = true
      }
      // Get page list for sitemap
      // console.log(this.getRoutesList(this.$router.options.routes, 'https://fine.fit'))
    },
    watch: {
      showFeedbackModal () {
        if (this.authenticated && this.showFeedbackModal) {
          // Show feedback modal to professionals
          // this.openFeedbackModal()
          // this.showFeedbackModalMut(false)
        }
      },
      authenticated () {
        if (this.authenticated) {
          // Socket IO receive new instant messages
          this.socketInit()
        }
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
  @import '~assets/styles/app.scss';
  /* @import '../node_modules/bootstrap/scss/bootstrap.scss';
  @import './assets/styles/app.scss';
  @import './assets/styles/style.scss';
  @import './assets/styles/forms.scss'; */
</style>

<style lang="scss" scoped>
  #main-block {
    animation: main-slide-in 0.5s forwards;
    -webkit-animation: main-slide-in 0.5s forwards;
    -moz-animation: unset;

    &.messenger-open {
      animation: main-slide-out 0.5s forwards;
      -webkit-animation: main-slide-out 0.5s forwards;
      -moz-animation: unset;

      .overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 98;
      }
    }
  }

  #messenger {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    padding: 2rem;
    padding-top: 4rem;
    background: white;

    .relative {
      position: relative;
    }

    #close-messenger {
      position: absolute;
      left: 100%;
      top: -2rem;
      z-index: 100;
    }

    &.slide-out {
      right: 0;
      animation: messenger-slide-out 0.5s forwards;
      -webkit-animation: messenger-slide-out 0.5s forwards;
      -moz-animation: unset;

      #close-messenger {
        left: calc(-2rem - 36px);
      }
    }

    &.active {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      opacity: 1;
      width: 70%;
      z-index: 99;
      animation: messenger-slide-in 0.5s forwards;
      -webkit-animation: messenger-slide-in 0.5s forwards;
      -moz-animation: unset;

      #close-messenger {
        left: calc(-2rem - 36px);
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        min-width: 320px;
        padding-top: 2rem;

        #close-messenger {
          left: -2rem;
          top: -2rem;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .testers-notice,
  .feedback-notice {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 8;
    padding: 1rem 0;
    background: black;

    .btn-outline {
      color: white !important;
      border-color: white !important;

      &:hover {
        color: black !important;
        background: white !important;
      }
    }
  }

  .unconfirmed-email-notice {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 7;
    padding: 1rem 0;
    color: white;
    background: black;

    .btn-outline {
      color: white !important;
      border-color: white !important;

      &:hover {
        color: black !important;
        background: white !important;
      }
    }
  }

  .welcome-notice {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 8;

    h4 {
      padding-left: 1rem;
    }

    .btn-outline {
      width: 100%;
      color: black !important;
      border-color: black !important;

      &:hover {
        color: white !important;
        background: black !important;
      }
    }

    .notice-content {
      width: 40%;
      padding: 0.5rem;
      color: black;
      background: white;
      box-shadow:
        inset 0 -3em 3em rgba(0,0,0,0.1),
        0 0  0 2px rgb(255,255,255),
        0.3em 0.3em 1em rgba(0,0,0,0.3);

      @include media-breakpoint-down(lg) {
        width: 50%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        font-size: 0.8rem;
        line-height: 1.2rem;

        h4 {
          font-size: 1.2rem;
        }
      }
    }

    .btn-close {
      position: absolute;
      left: 0.5rem;
      top: 0rem;
    }
  }

  .feedback-notice {
    color: white;
  }

  #accountBlockedModal {
    .blocked-icon {
      display: block;
      margin: 1rem auto;
      color: #721c24;
      font-size: 6rem;
      text-align: center;
    }
  }

  @keyframes messenger-slide-in {
    0% { transform: translateX(120%); }
    100% { transform: translateX(0%); }
  }

  @-webkit-keyframes messenger-slide-in {
    0% { -webkit-transform: translateX(120%); }
    100% { -webkit-transform: translateX(0%); }
  }

  @keyframes messenger-slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(120%); }
  }

  @-webkit-keyframes messenger-slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(120%); }
  }

  @keyframes main-slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-10%); }
  }

  @-webkit-keyframes main-slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-10%); }
  }

  @keyframes main-slide-in {
    0% { transform: translateX(-10%); }
    99% { transform: translateX(0%); }
    100% { transform: unset; }
  }

  @-webkit-keyframes main-slide-in {
    0% { -webkit-transform: translateX(-10%); }
    99% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: unset; }
  }
</style>

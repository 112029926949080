import * as storage from '@/util/storage'

export default {
  SET_FEEDBACK_SENT: (state, data) => {
    state.feedbackSent = data
  },
  SET_SHOW_MODAL: (state, data) => {
    state.showModal = data
  }
}

import Debug from '@/util/debug'
import { IDB_LOCALFORAGE, IDB_LOCALFORAGE_TABLE_GENERAL } from '@/util/constants'
import localForage from 'localforage'
import moment from 'moment'

const debug = Debug('util:local-storage')

export const storage = localForage.createInstance({
  name: `${IDB_LOCALFORAGE}`,
  driver: localForage.LOCALSTORAGE,
  storeName: IDB_LOCALFORAGE_TABLE_GENERAL
})

export function get (key, defaultValue = null) {
  return storage.getItem(key)
    .then(result => {
      debug.info('get: "%s", value: %o', key, result)

      if (result !== null && Object.prototype.hasOwnProperty.call(result, 'value')) {
        if (result.expire > 0 && moment().valueOf() > result.expire) {
          debug.warn('expired', moment().valueOf(), result.expire)
          return null
        }
        return result.value
      }
      return defaultValue
    }).catch(e => {
      debug.error('error while getting: "%s", error: %o, returning: %o', key, e, defaultValue)
      return defaultValue
    })
}

export function set (key, value, expireIn = null) {
  const expire = expireIn !== null ? moment().valueOf() + (expireIn * 1000) : null
  const data = { value, expire }
  return storage.setItem(key, data)
    .then(result => {
      debug.info('saved: "%s", data: %o', key, result)
      return result
    }).catch(e => {
      debug.error('error while saving: "%s", value: %o, error: %o', key, data, e)
    })
}

export function remove (key) {
  return storage.removeItem(key)
    .then(() => {
      debug.info('removing: "%s"', key)
    }).catch(e => {
      debug.error('error while removing: "%s", error: %o', key, e)
    })
}

<template>
  <div>
  <div class="head text-center">
      <h3 class="card-title">{{ $t('reset.title') }}</h3>
    </div>

    <form action="#" @submit.prevent="submit" class="mt-3">
      <b-row>
        <b-col>
          <b-form-input type="email" v-model="email" :placeholder="$t('placeholders.email')" required></b-form-input>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <response :items="errors" class="mt-2 mb-3"></response>

          <button type="submit" class="btn btn-primary btn-block">{{ $t('reset.form.buttons.send') }}</button>
        </b-col>
      </b-row>

      <p class="text-center mt-3">
        {{ $t('reset.haveAccount') }} <a href="javascript:void(0);" v-b-modal.signInModal>{{ $t('buttons.signIn') }}</a>
      </p>

    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      errors: {},
      form: {
        email: null,
        lang: this.$i18n.i18next.language
      }
    }
  },
  methods: {
    ...mapActions({
      sendForgotEmail: 'auth/sendForgotEmail'
    }),
    submit () {
      let payload = {
        email: this.form.email,
        lang: this.$i18n.i18next.language
      }
      this.sendForgotEmail({context: this, payload: payload})
    }
  }
}
</script>

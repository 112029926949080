<template>
  <div>

    <form action="#" @submit.prevent="submit">
      <div class="form-group mt-0 mb-3" :class="{ 'has-error': errors.email }">
        <div class="input-group">
          <span class="input-group-addon">
            <i class="material-icons" aria-hidden="true">email</i>
          </span>
          <input type="email"
                 class="form-control"
                 :placeholder="$t('placeholders.email')"
                 aria-describedby="email"
                 autofocus v-model="email"
                 required>
        </div>
      </div>

      <!--<p>-->
        <!--{{ $t('register.disclaimer') }} <router-link :to="{ path: '#terms'}">terms & conditions</router-link> and <router-link :to="{ path: '#privacy'}">privacy policy</router-link>-->
      <!--</p>-->

      <p v-if="1 === 2" class="text-center" v-html="$t('signUp.disclaimer', { terms: $t('legal.terms.title'), privacy: $t('legal.privacy.title') })"></p>

      <div class="text-left">
        <b-form-checkbox id="accept-terms"
                         v-model="terms_agree"
                         :value="true"
                         :unchecked-value="false">
          <p class="text-left" v-html="$t('signUp.termsAcceptText', { terms: $t('legal.terms.title'), privacy: $t('legal.privacy.title') })"></p>
        </b-form-checkbox>
      </div>

      <response :items="errors"></response>

      <button type="submit" class="btn btn-primary btn-block">{{ $t('buttons.signUp') }}</button>

      <hr />

      <div class="social-login">
        <b-row>
          <b-col>
            <b-btn variant="primary" @click="authenticate('facebook')"><img height="24" src="@/assets/images/logo-fb.svg" />{{ $t('buttons.signUpFacebook') }}</b-btn>
          </b-col>
          <b-col>
            <b-btn variant="primary" @click="authenticate('google')"><img height="24" src="@/assets/images/logo-google.svg" />{{ $t('buttons.signUpGoogle') }}</b-btn>
          </b-col>
        </b-row>
      </div>
      <p class="text-center">
        {{ $t('signUp.haveAccount') }} <router-link :to=" { name: `SignIn.${$i18n.i18next.language}` } ">{{ $t('buttons.signIn') }}</router-link>
      </p>

    </form>

    <b-modal id="termsModal" title="Terms & Conditions">
      <div class="modal-body">
        <terms></terms>
      </div>
      <div slot="modal-footer">
        <p>Terms & Conditions</p>
      </div>
    </b-modal>

    <b-modal id="privacyModal" title="Privacy Policy">
      <div class="modal-body">
        <privacy></privacy>
      </div>
      <div slot="modal-footer">
        <p>Pirvacy Policy</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Debug from '@/util/debug'
import { mapActions } from 'vuex'
import { ENV_IS_PRODUCTION } from '@/util/constants'

const debug = Debug('Signup:Step1')

/* Start of lazy load components config */
import lazyLoadComponent from '@/util/lazy-load-component'
import Preloader from '@/app/global/components/Preloader'
// import SkeletonBox from '@/app/global/components/SkeletonBox'
const lazyLoadOptions = {
  loading: Preloader,
  loadingData: {
    props: {
      width: '100%',
      height: '2rem'
    }
  }
}
/* End of lazy load components config */

export default {
  components: {
    Terms: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/global/components/Terms')}),
    Privacy: lazyLoadComponent({...lazyLoadOptions, componentFactory: () => import('@/app/global/components/Privacy')})
  },
  data () {
    return {
      email: '',
      slug: null,
      errors: [],
      terms_agree: false,
      socialProvider: null
    }
  },
  methods: {
    ...mapActions({
      register: 'auth/registerEmail',
      socialLogin: 'auth/socialSignIn',
      socialSignInToken: 'auth/socialSignInToken'
    }),
    authenticate: function (provider) {
      this.socialProvider = provider
      this.errors = []
      let payload = {
        token: 'empty',
        provider: provider
      }
      if (this.terms_agree) {
        this.socialLogin({ payload: payload, context: this })
      } else {
        this.errors.push({ code: 2004 })
      }
    },
    submit () {
      this.errors = []
      let payload = {
        email: this.email,
        slug: this.slug
      }
      if (this.terms_agree) {
        this.register({ payload: payload, context: this })
      } else {
        this.errors.push({ code: 2004 })
      }
    },
    submitWithSecret (secret) {
      let payload = {}
      let provider = this.socialProvider
      if (!_.isNil(provider)) {
        payload = {
          token: 'empty',
          provider: provider,
          slug: this.slug,
          company_secret: secret
        }
        this.socialLogin({ payload: payload, context: this })
      } else {
        payload = {
          email: this.email,
          slug: this.slug,
          company_secret: secret
        }
        this.register({ payload: payload, context: this })
      }
    },
    openModal: function (modalId) {
      this.closeModal()
      if (document.getElementById(modalId)) {
        if (document.getElementById(modalId).classList.contains('modal-active')) {
          // Do nothing
        } else {
          document.getElementById(modalId).classList.add('modal-active')
        }
      }
    },
    closeModal: function () {
      var elements = document.getElementsByClassName('modal-active')
      if (elements.length > 0) {
        var currentModal = elements[0]
        currentModal.classList.remove('modal-active')
      }
    }
  },
  mounted () {
    debug.info('mounted')

    // Social login
    if ('oauth2' in this.$route.query) {
      if (this.$route.query.oauth2 === 'true') {
        if ('token' in this.$route.query) {
          let token = this.$route.query.token
          this.socialSignInToken({ payload: token, context: this })
        } else {
          this.errors.push({ code: 2007 })
        }
      } else {
        this.errors.push({ code: 2006 })
      }
    }

    // Company subdomain
    if (ENV_IS_PRODUCTION) {
      this.slug = location.hostname.split('.').shift()
    } else {
      this.slug = this.$route.query.slug
    }
    if (this.slug === 'my') {
      this.slug = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .social-login {
    .btn {
      width: 100%;
      color: black;
      background-color: white !important;

      img {
        margin-right: 0.5rem;
      }
    }
  }

  .modals {
    p {
      text-align: left;
    }
  }
</style>

import * as api from '../api'
import store from '@/store'

export const getFeedback = ({ commit }, { context, payload }) => {
  api.getFeedback(payload).then((response) => {
    console.log('Feedback resp', response)
    if (response.data.data.length > 0) {
      commit('SET_FEEDBACK_SENT', true)
    } else {
      let user = store.getters['account/user']
      console.log(user)
      if (user.role_id === 3 && user.published) {
        // Show feedback modal after 2 minutes
        setTimeout(function () {
          commit('SET_SHOW_MODAL', true)
        }, 20000)
      }
    }
  }).catch((error) => {
    // context.errors = error.response.data.errors
  })
}


export const sendFeedback = ({ commit }, { context, payload }) => {
  context.isLoading = true
  context.errors = []
  api.sendFeedback(payload).then((response) => {
    context.success = true
    context.isLoading = false
    commit('SET_FEEDBACK_SENT', true)

    // Set professional's membership to premium
    store.commit('account/SET_USER_MEMBERSHIP', 3)
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

import Debug from '@/util/debug'
import { updateTitle } from '@/util/dom'
import Vue from 'vue'

const debug = Debug('router:afterEach')

const afterEach = (to) => {
  // updateTitle()

  Vue.nextTick(() => {
    if (!_.isEmpty(to.meta)) {
      if (!_.isUndefined(to.meta.title)) {
        document.title = to.meta.title
      }
    }
  })

  return Promise.resolve()
}

export default afterEach

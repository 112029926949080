import ForgotStep1 from '../components/step1-forgot'
import ForgotStep2 from '../components/step2-forgot-email'
import ForgotStep3 from '../components/step3-forgot'

export const ForgotStepsEn = [
  {
    path: 'step/1',
    name: 'Forgot.Step1.en',
    component: ForgotStep1,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'step/2',
    name: 'Forgot.Step2.en',
    component: ForgotStep2,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'step/3',
    name: 'Forgot.Step3.en',
    component: ForgotStep3,
    meta: { guest: true, needsAuth: false }
  }
]


export const ForgotStepsFi = [
  {
    path: 'askel/1',
    name: 'Forgot.Step1.fi',
    component: ForgotStep1,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'askel/2',
    name: 'Forgot.Step2.fi',
    component: ForgotStep2,
    meta: { guest: true, needsAuth: false }
  },
  {
    path: 'askel/3',
    name: 'Forgot.Step3.fi',
    component: ForgotStep3,
    meta: { guest: true, needsAuth: false }
  }
]

import axios from 'axios'

export const sendMessage = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/messenger`, payload)
}

export const createConversation = (payload) => {
  return axios.post(`${process.env.API_URL}/v1/messenger/conversation`, payload)
}

export const getConversations = () => {
  return axios.get(`${process.env.API_URL}/v1/messenger`)
}

export const getMessages = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/messenger/${payload}`)
}

export const getSharedKeys = (payload) => {
  return axios.get(`${process.env.API_URL}/v1/messenger/${payload}/keys`)
}

import * as storage from '@/util/storage'

export default {
  SET_PROFESSIONAL_INFO_DATA: (state, data) => {
    state.professional = data
  },
  SET_PROFESSIONAL_OFFLINE_ADS_DATA: (state, data) => {
    state.professionalOfflineAds = data
  },
  SET_PROFESSIONAL_ONLINE_ADS_DATA: (state, data) => {
    state.professionalOnlineAds = data
  },
  SET_SEARCH_RESULTS (state, payload) {
    state.searchResults = payload
  },
  SET_SEARCH_NAMES_RESULTS (state, payload) {
    state.searchNamesResults = payload
  },
  SET_SEARCH_RESULTS_BOOSTED (state, payload) {
    state.searchResultsBoosted = payload
  },
  SET_SEARCH_RESULTS_ADS (state, payload) {
    state.searchResultsAds = payload
  },
  SET_SEARCH_LOADING (state, payload) {
    state.isSearchLoading = payload
  },
  SET_ONLINE_SEARCH_RESULTS (state, payload) {
    state.onlineSearchResults = payload
  },
  SET_ONLINE_SEARCH_NAMES_RESULTS (state, payload) {
    state.onlineSearchNamesResults = payload
  },
  SET_ONLINE_SEARCH_RESULTS_BOOSTED (state, payload) {
    state.onlineSearchResultsBoosted = payload
  },
  SET_ONLINE_SEARCH_RESULTS_ADS (state, payload) {
    state.onlineSearchResultsAds = payload
  },
}

// import store from '@/store'
// import { i18n } from '@/app/boot'
// import { DateTime } from 'luxon'
//
// export default {
//   SET_SEARCH_RESULTS (state, payload) {
//     state.searchResults = payload
//   }
// }

export default {
  SET_UNSAVED_CHANGES (state, data) {
    state.unsavedChanges = data
  }
}

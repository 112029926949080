export default {
  authorized: true,
  user: {
    authenticated: false,
    data: {
      membership_id: 0,
      social_only: false
    }
  },
  services: [],
  servicesArr: [],
  specialities: [],
  userServices: [],
  userOnlineServices: {
    languages: [],
    methods: [],
    services: []
  },
  userSpecialities: [],
  userLocations: [],
  userCertificates: [],
  userBookings: {},
  customerBookings: [],
  userPhotos: {},
  userAnalyzePhotos: {},
  userPosts: {},
  userAnalyzePosts: {},
  userFollowings: [],
  userCart: {},
  userMembership: {},
  boostCampaignPlans: [],
  adsCampaignPlans: [],
  userBoostCampaigns: [],
  userAdsCampaigns: [],
  userAffiliates: [],
  affiliates: [],
  payment: {}
}

import debug from 'debug'

const prefix = 'finefit:'

const createLogger = (name, target) => {
  const logger = debug(`${prefix}${name}`)
  logger.log = target
  return logger
}

export default name => {
  const res = createLogger(name, console.debug.bind(console)) // eslint-disable-line no-console
  res.debug = createLogger(name, console.debug.bind(console)) // eslint-disable-line no-console
  res.info = createLogger(name, console.info.bind(console)) // eslint-disable-line no-console
  res.warn = createLogger(name, console.warn.bind(console)) // eslint-disable-line no-console
  res.error = createLogger(name, console.error.bind(console)) // eslint-disable-line no-console
  return res
}

export const professional = (state) => {
  return state.professional
}

export const professionalOfflineAds = (state) => {
  return state.professionalOfflineAds
}

export const professionalOnlineAds = (state) => {
  return state.professionalOnlineAds
}

export const searchResults = (state) => {
  return state.searchResults
}

export const searchNamesResults = (state) => {
  return state.searchNamesResults
}

export const searchResultsBoosted = (state) => {
  return state.searchResultsBoosted
}

export const searchResultsAds = (state) => {
  return state.searchResultsAds
}

export const isSearchLoading = (state) => {
  return state.isSearchLoading
}

export const onlineSearchResults = (state) => {
  return state.onlineSearchResults
}

export const onlineSearchNamesResults = (state) => {
  return state.onlineSearchNamesResults
}

export const onlineSearchResultsBoosted = (state) => {
  return state.onlineSearchResultsBoosted
}

export const onlineSearchResultsAds = (state) => {
  return state.onlineSearchResultsAds
}

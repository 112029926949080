import * as api from '../api'
import * as storage from '@/util/storage'
import { setHttpToken } from '@/util/api'
import router from '@/router'
import { IDB_DOMAIN, ENV_IS_PRODUCTION } from '@/util/constants'
import store from '@/store'
import account from '@/app/account/store'

import Vue from 'vue'
import VueSocketio from 'vue-socket.io-extended'
import io from 'socket.io-client'
import axios from 'axios'
import i18next from 'i18next'
import firebase from 'firebase'
import { browserName } from '@/util/browser'

export const signUpEmail = ({ dispatch }, { context, payload }) => {
  context.errors = []
  context.isLoading = true

  // Check if should redirect to Account page
  let redirect = store.getters['auth/authRedirect']
  if (!redirect) {
    // Send redirect pathname to backend
    payload.redirect = window.location.pathname

    // Set back to defeault value
    dispatch('authRedirect', true)
  }
  return api.signUpEmail(payload).then((response) => {
    context.email = ''
    // context.role_id = null
    context.emailSuccess = true
    context.isLoading = false
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const resendEmailConfirmation = ({ dispatch }, { context, payload }) => {
  context.errors = []
  context.isEmailLoading = true
  return api.resendEmailConfirmation(payload).then((response) => {
    context.emailSuccess = true
    context.isEmailLoading = false
  }).catch((error) => {
    context.isEmailLoading = false
    context.errors = error.response.data.errors
  })
}

export const signUpAccount = ({ dispatch }, { payload, context }) => {
  context.errors = []
  context.isLoading = true
  return api.signUpAccount(payload).then((response) => {
    const redirect = response.data.data.redirect
    dispatch('setToken', response.data.data.access_token).then(() => {
      dispatch('fetchUser').then(() => {
        if (redirect) {
          window.location = redirect
        } else {
          router.replace({ name: `SignInSuccess.${i18next.language}`, params: { justSignedUp: true }})
        }
      })
    })
    // Redirecting to account page, no need to hide preloader
    // context.isLoading = false
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const getUserByCode = ({ commit, dispatch }, code) => {
  return api.getUserByCode(code).then((response) => {
    store.commit('auth/SET_TEMP_USER', {
      code: code,
      email: response.data.data.email,
      role_id: response.data.data.role_id,
      redirect: response.data.data.redirect
    })
  })
}

export const confirmEmail = ({ commit, dispatch }, { code, context }) => {
  context.isLoading = true
  return api.confirmEmail(code).then((response) => {
    context.isLoading = false
    context.confirmSuccess()
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const signIn = ({ dispatch }, { payload, context }) => {
  context.errors = []

  // Check if should redirect to Account page
  let redirect = store.getters['auth/authRedirect']

  // if ('redirect' in router.currentRoute.query) {
  //   redirect = window.location.pathname
  // }
  // payload.redirect = redirect

  return api.oAuthLogin(payload).then((response) => {
    // let redirect = response.data.data.redirect
    dispatch('setToken', response.data.data.access_token).then(() => {
      dispatch('fetchUser').then(() => {

        // Internet Explorer and Safari doesn't support web push notifications
        if (firebase.messaging.isSupported()) {

          // Start of Firebase Cloud Messaging for notifications
          var firebaseConfig = {
            apiKey: 'AIzaSyDVMsZM9x-TQGbpT2HuOEijRFxSTlYflyA',
            authDomain: 'phitwork-4a978.firebaseapp.com',
            databaseURL: 'https://phitwork-4a978.firebaseio.com',
            projectId: 'phitwork-4a978',
            storageBucket: 'phitwork-4a978.appspot.com',
            messagingSenderId: '484841928305',
            appId: '1:484841928305:web:c842f8584ca57378324dd0'
          }

          if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig)
          }

          Vue.prototype.$messaging = firebase.messaging()

          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/static/firebase-messaging-sw.js', {
              scope: '/static/'
            })
              .then(function (reg) {
                console.log('Registration succeeded. Scope is ' + reg.scope)
                Vue.prototype.$messaging.useServiceWorker(reg)

                Vue.prototype.$messaging
                  .requestPermission()
                  .then(() => Vue.prototype.$messaging.getToken())
                  .then((token) => {
                    console.log(token) // Receiver Token to use in the notification
                    let payload = {
                      token: token,
                      device_registered: false,
                      type: browserName()
                    }
                    dispatch('sendNotificationsToken', {payload: payload, context: this})
                  })
                  .catch(function (err) {
                    console.log('Unable to get permission to notify.', err)
                  });

                Vue.prototype.$messaging.onMessage(function (payload) {
                  console.log('Message received. ', payload)
                  // ...
                })
              }).catch(function (error) {
              // registration failed
              console.log('Registration failed with ' + error)
            })
          }
        }
        // End of Firebase Cloud Messaging for notifications

        if (redirect) {
          router.replace({name: `SignInSuccess.${i18next.language}`})
        } else {
          // window.location = redirect

          // Do not redirect to account page

          // Set back to defeault value
          dispatch('authRedirect', true)
        }
      })
    }).catch((error) => {
      context.errors = error.response.data.errors
    })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const signOut = ({ dispatch, commit }) => {
  return api.signOut().then((response) => {
    dispatch('clearAuth').then(() => {
      store.commit('account/SET_USER', { authenticated: false, data: {} })
      store.commit('messenger/SET_SELECTED_PROFESSIONAL', {})
      store.commit('messenger/SET_CONVERSATIONS', [])
      store.commit('messenger/SET_MESSAGES', [])
      router.replace({name: `Home.${i18next.language}`})
    })
  }).catch((error) => {
    dispatch('clearAuth').then(() => {
      store.commit('account/SET_USER', { authenticated: false, data: {} })
      store.commit('messenger/SET_SELECTED_PROFESSIONAL', {})
      store.commit('messenger/SET_CONVERSATIONS', [])
      store.commit('messenger/SET_MESSAGES', [])
      router.replace({name: `Home.${i18next.language}`})
    })
  })
}

export const socialSignIn = ({ dispatch }, { context, payload }) => {
  // Check if should redirect to Account page
  let redirect = store.getters['auth/authRedirect']
  if (!redirect) {
    // Send redirect pathname to backend
    payload.redirect = window.location.pathname
    // Set back to defeault value
    dispatch('authRedirect', true)
  } else {
    payload.redirect = ''
  }
  return api.socialSignIn(payload).then((response) => {
    context.success = true
    if (response.data.data.redirect) {
      window.location = response.data.data.redirect
    }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const socialSignUp = ({ dispatch }, { context, payload }) => {
  // Check if should redirect to Account page
  let redirect = store.getters['auth/authRedirect']
  if (!redirect) {
    // Send redirect pathname to backend
    payload.redirect = window.location.pathname
    // Set back to defeault value
    dispatch('authRedirect', true)
  } else {
    payload.redirect = ''
  }
  return api.socialSignUp(payload).then((response) => {
    context.socialSuccess = true
    if (response.data.data.redirect) {
      window.location = response.data.data.redirect
    }
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const socialSignInToken = ({ dispatch }, { payload, context }) => {
  dispatch('clearAuth').then(() => {
    dispatch('setToken', payload.token).then(() => {
      dispatch('fetchUser').then(() => {
        context.errors = []
        if (!_.isUndefined(payload.redirect) && payload.redirect !== '' && payload.redirect !== '/') {
          window.location = `${payload.redirect}?lang=${payload.lang}`
        } else {
          router.replace({name: `SignInSuccess.${i18next.language}`})
        }
      })
    }).catch((error) => {
      context.errors = error.response.data.errors
    })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const sendForgotEmail = ({ dispatch }, { context, payload }) => {
  context.errors = []
  context.isLoading = true
  return api.sendForgotEmail(payload).then((response) => {
    context.isLoading = false
    context.success = true
  }).catch((error) => {
    context.isLoading = false
    context.errors = error.response.data.errors
  })
}

export const sendForgotEmailAccount = ({ dispatch }, { context, payload }) => {
  context.passwordResetErrors = []
  context.passwordResetSuccess = false
  return api.sendForgotEmail(payload).then((response) => {
    context.passwordResetSuccess = true
  }).catch((error) => {
    context.passwordResetSuccess = false
    context.passwordResetErrors = error.response.data.errors
  })
}

export const getForgotDataByCode = ({ commit, dispatch }, code) => {
  return api.getForgotDataByCode(code).then((response) => {
    store.commit('auth/SET_TEMP_USER', {
      code: code,
      email: response.data.data.email
    })
  })
}

export const changePassword = ({ dispatch }, { context, payload }) => {
  return api.changePassword(payload).then((response) => {
    // router.push({name: `Home.${i18next.language}`})
    dispatch('signIn', { context: context, payload: payload })
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const setToken = ({ commit, dispatch }, token) => {
  if (_.isEmpty(token)) {
    return dispatch('checkTokenExists').then((token) => {
      setHttpToken(token)
    })
  }
  store.commit('account/SET_TOKEN', token)
  setHttpToken(token)
}

export const checkTokenExists = ({ commit, dispatch }, token) => {
  return storage.get('authToken').then((token) => {
    if (_.isEmpty(token)) {
      return Promise.reject(new Error('NO_STORAGE_TOKEN'))
    }
    return Promise.resolve(token)
  })
}

export const checkTokenExistsApi = ({ commit, dispatch }) => {
  return api.fetchUser().then((response) => {
    // Nothing
  }).catch((error) => {
    dispatch('signOut')
  })
}

export const fetchUser = ({ commit, dispatch }) => {
  return api.fetchUser().then((response) => {
    let success = false
    if (_.get(response, 'data.access_token')) {
      success = true
      dispatch('setToken', response.data.access_token)
    } else if (!success && _.get(response, 'headers.bearer')) {
      success = true
      dispatch('setToken', response.headers.bearer)
    }
    if (!success) {
      return success
    }
    store.commit('account/SET_AUTHENTICATED', true)
    store.commit('account/SET_USER', {
      authenticated: true,
      data: response.data.data
    })
    // Get user messages
    store.dispatch('messenger/getConversations', { context: '' })


    if (response.data.data.role_id === 3) {
      // Get user's specialities
      store.dispatch('account/getUserSpecialities', { context: '' })

      if (response.data.data.published) {
        // Check if professional has sent a feedback
       //  store.dispatch('feedback/getFeedback', {context: '', payload: 'professional_test_1'})
      }
    }

    // We use Socket io for messenger
    Vue.use(VueSocketio, io(process.env.API_URL, {
      transportOptions: {
        polling: {
          extraHeaders: {
            'Authorization': axios.defaults.headers.common['Authorization']
          }
        }
      }
    }))
  }).catch((error) => {
    dispatch('signOut')
  })
}

export const clearAuth = ({ commit }, token) => {
  store.commit('account/SET_AUTHENTICATED', false)
  store.commit('account/SET_USER', {})
  store.commit('account/SET_TOKEN', null)
  setHttpToken(null)
}

export const deleteSelf = ({ commit }, { context }) => {
  context.preloaderTitle = 'preloader.sendingEmail'
  context.isLoading = true
  api.deleteSelf().then((response) => {
    context.success = true
    context.isLoading = false
  }).catch((error) => {
    context.errors = error.response.data.errors
    context.isLoading = false
  })
}

export const completeDeleteSelf = ({ dispatch }, { context, payload }) => {
  api.completeDeleteSelf(payload).then((response) => {
    context.success = true
    dispatch('clearAuth')
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const authRedirect = ({ commit }, redirect) => {
  commit('SET_AUTH_REDIRECT', redirect)
}

export const sendNotificationsToken = ({ commit }, { context, payload }) => {
  api.sendNotificationsToken(payload).then((response) => {
    // Done!
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getNotifications = ({ commit, dispatch }, { context }) => {
  api.getNotifications().then((response) => {
    commit(types.SET_USER_NOTIFICATIONS_DATA, response.data)
    commit(types.SET_USER_NOTIFICATIONS_AS_READ)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const getNotificationsSettings = ({ commit }, { context }) => {
  api.getNotificationsSettings().then((response) => {
    commit(types.SET_NOTIFICATIONS_SETTINGS_DATA, response.data)
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

export const changeNotificationSettings = ({ dispatch }, { context, payload }) => {
  api.changeNotificationSettings(payload).then((response) => {
    dispatch('getNotificationsSettings', { context: this })
    context.success = true
  }).catch((error) => {
    context.errors = error.response.data.errors
  })
}

import { DOCUMENT_TITLE } from './constants'

let currentTitle = DOCUMENT_TITLE

export function updateTitle (newTitle) {
  if (typeof newTitle !== 'undefined') {
    currentTitle = newTitle
  }
  document.title = currentTitle
  window.history.replaceState({}, document.title, document.location.href)
  console.log(document.title)
}

export function updateFavicon (notification = false) {
  // Default favicon is in black color, on light background
  let favicon = 'https://finefit.s3.eu-north-1.amazonaws.com/images/finefit_favicon_black.png'

  // Black favicon with notifications icon
  if (notification) {
    favicon = 'https://finefit.s3.eu-north-1.amazonaws.com/images/finefit_favicon_black_notification.png'
  }
  // Check if browser has dark mode
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // Dark mode
    favicon = 'https://finefit.s3.eu-north-1.amazonaws.com/images/finefit_favicon_white.png'

    // White favicon with notifications icon, for dark mode
    if (notification) {
      favicon = 'https://finefit.s3.eu-north-1.amazonaws.com/images/finefit_favicon_white_notification.png'
    }
  }
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = favicon;
  document.getElementsByTagName('head')[0].appendChild(link)
}

export function scrollTo (elem, style, unit, from, to, time, prop) {
  if (!elem) {
    return;
  }
  var start = new Date().getTime(),
    timer = setInterval(function () {
      var step = Math.min(1, (new Date().getTime() - start) / time);
      if (prop) {
        elem[style] = (from + step * (to - from))+unit;
      } else {
        elem.style[style] = (from + step * (to - from))+unit;
      }
      if (step === 1) {
        clearInterval(timer);
      }
    }, 25);
  if (prop) {
    elem[style] = from+unit;
  } else {
    elem.style[style] = from+unit;
  }
}
